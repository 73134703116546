const initialState = {
  token: localStorage.getItem('token'),
  permissions: JSON.parse(localStorage.getItem('permissions') || '{}' ),
  mainContact: JSON.parse(localStorage.getItem('mainContact') || '{}' ),
  hoId: localStorage.getItem('hoId'),
  patients: [],
  patient: {},
  vaccines: [],
  vaccination: {},
  error: null,
  success: null,
  errorFields: {},
  tenant: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
  case 'PATIENTS_LOADED': {
    return {
      ...state,
      patients: action.payload
    };
  }
  case 'PERMISSIONS': {
    return {
      ...state,
      permissions: action.payload
    };
  }
  case 'MAIN_CONTACT': {
    return {
      ...state,
      mainContact: action.payload
    };
  }
  case 'PATIENTS_ERROR': {
    return {
      ...state,
      error: action.payload
    };
  }
  case 'PATIENT_LOADED': {
    return {
      ...state,
      patient: action.payload
    };
  }
  case 'PATIENT_ERROR': {
    return {
      ...state,
      error: action.payload
    };
  }
  case 'VACCINES_LOADED': {
    return {
      ...state,
      vaccines: action.payload
    };
  }
  case 'VACCINES_ERROR': {
    return {
      ...state,
      error: action.payload
    };
  }
  case 'HW_CREATED': {
    return {
      ...state,
      healthworker: action.payload,
      success: {...action.payload, msg: 'Your registration has been successfully submitted!'},
      error: null,
      validationError: null
    };
  }
  case 'HW_CREATED_ERROR': {
    return {
      ...state,
      error: action.payload,
      success: null
    };
  }
    case 'HW_CREATED_VALIDATION_ERROR': {
      return {
        ...state,
        validationError: action.payload,
      };
    }
  case 'PATIENT_CREATED': {
    return {
      ...state,
      patient: action.payload,
      success: {...action.payload, msg: 'Patient successfully created!'},
      error: null,
      errorFields: null
    };
  }
  case 'PATIENT_CREATED_ERROR': {
    return {
      ...state,
        error: action.payload.message,
        errorFields: action.payload.errorFields
      };
    }
  case 'PATIENT_EDITED': {
    return {
      ...state,
      patient: action.payload,
      success: {...action.payload, msg: 'Patient successfully edited!'},
      error: null
    };
  }
  case 'PATIENT_EDITED_ERROR': {
    return {
      ...state,
      error: action.payload
    };
  }
  case 'VACC_BATCH_CREATED': {
    return {
      ...state,
      vaccinebatch: action.payload,
      success: {...action.payload, msg: 'Vaccine batch safely stored in the system!'},
      error: null
    };
  }
  case 'VACC_BATCH_ERROR': {
    return {
      ...state,
      error: action.payload
    };
  }
  case 'VACCINATION_CREATED': {
    return {
      ...state,
      vaccination: action.payload,
      success: {...action.payload, msg: 'Vaccination successfully registered!'},
      error: null
    };
  }
  case 'VACCINATION_CREATED_ERROR': {
    return {
      ...state,
      error: action.payload
    };
  }
  case 'VACC_VC_CREATED': {
    return {
      ...state,
      vc: action.payload
    };
  }
    case 'VACC_VC_RELOADED': {
      return {
        ...state,
        vc: null
      };
    }
  case 'VACC_VC_ERROR': {
    return {
      ...state,
      error: action.payload
    };
  }
  case 'LOGIN': {
    return {
      ...state,
    };
  }
  case 'STORE_TOKEN': {
    return {
      ...state,
      token: action.payload
    };
  }
  case 'TRACKING_LOADED': {
    return {
      ...state,
      vaccination: action.payload
    };
  }
  case 'TRACKING_LOADED_ERROR': {
    return {
      ...state,
      error: action.payload
    };
  }
  case 'HO_LOADED': {
    return {
      ...state,
      healthorg: action.payload
    };
  }
  case 'HO_LOADED_ERROR': {
    return {
      ...state,
      error: action.payload
    };
  }
    case 'TENANT_LOADED': {
      return {
        ...state,
        tenant: action.payload
      };
    }
    case 'TENANT_LOADED_ERROR': {
      return {
        ...state,
        error: action.payload
      };
    }
  case 'RESET_ERROR_AND_SUCCESS': {
    return {
      ...state,
      error: null,
      success: null
    };
  }
  case 'PERMISSIONS_ERROR': {
    return {
      ...state,
      error: action.payload,
      success: null
    };
  }
  case 'PDF_CREATED': {
    return {
      ...state,
      success: {...action.payload, msg: 'Pdf created'},
      error: null,
      pdf: action.payload
    };
  }
  case 'PDF_ERROR': {
    return {
      ...state,
      error: action.payload,
      success: null
    };
  }
  case 'CERTIFICATE_SENT': {
    return {
      ...state,
      success: {...action.payload, msg: 'Certificate successfully sent!'},
      error: null
    };
  }
  case 'CERTIFICATE_SENT_ERROR': {
    return {
      ...state,
      success: null,
      error: action.payload
    };
  }
    case 'CERTIFICATE_SENT_WITHOUT_EMAIL_ERROR': {
      return {
        ...state,
        success: null,
        error: action.payload
      };
    }
  default:
    return state;
  }
}
