import React, { Component }  from 'react';
import styled, {withTheme} from 'styled-components';
import { connect } from 'react-redux';

const StyledDiv = styled.div`
  background-color: ${(props) => props.color.bg};
  color: ${(props) => props.color.color};
  border-radius: ${(props) => props.theme.avatarIcon.radius};
  height: ${(props) => props.theme.avatarIcon.height};
  width: ${(props) => props.theme.avatarIcon.width};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-top: 5px;
  font-family: ${(props) => props.theme.fonts.montserrat};
  font-size: ${(props) => props.theme.fonts.size.subtitle1};
  font-weight: ${(props) => props.theme.fonts.weight.subtitle1};
  text-align: center;
`;

function Avatar(props) {
  const colors = [
    { color: '#28C76F', bg: 'rgba(40, 199, 111, 0.12)' },
    { color: '#F9682A', bg: 'rgba(249, 104, 42, 0.12)' },
    { color: '#285EC7', bg: 'rgba(40, 94, 199, 0.12)' },
    { color: '#C7B728', bg: 'rgba(199, 183, 40, 0.12)' },
  ];
  const color = colors[Math.floor(Math.random() * colors.length)];

  return (
    <StyledDiv color={color}>
      {props.firstName && props.firstName[0]}
      {props.lastName && props.lastName[0]}
    </StyledDiv>
  );
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    mainContact: state.updates.mainContact,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTheme(connect(mapStateToProps, mapDispatchToProps)(Avatar));