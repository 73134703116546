import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AppBar, Box, Grid } from '@material-ui/core';
import { withTheme } from 'styled-components';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StyledAppBar = styled(AppBar)`
  height: ${(props) => props.theme.layout.header.height};
  &.MuiAppBar-root {
    @media screen and (max-width: 800px) {
      background-size: 100%;
    }
  }
  &.MuiAppBar-positionFixed {
    position: unset;
  }
  &.MuiAppBar-colorPrimary {
    background-color: ${(props) => props.theme.layout.header.bgColor};
  }
  &.MuiPaper-elevation4 {
    box-shadow: ${(props) => props.theme.layout.header.boxShadow};
  }
`;
const StyledH2 = styled.h2`
  margin-top: ${(props) => props.theme.layout.header.title.marginTop};
  font-size: ${(props) => props.theme.fonts.size.apptitle};
  font-weight: ${(props) => props.theme.fonts.weight.semiBold};
  float: ${(props) => props.theme.layout.header.title.float};
  text-shadow: ${(props) => props.theme.layout.header.title.textShadow};
  color: ${(props) => props.theme.layout.header.title.color};
  margin-right: ${(props) => props.theme.layout.header.title.marginRight};
  @media screen and (max-width: 1100px) {
    font-size: ${(props) => props.theme.fonts.size.subtitle1};
    margin-top: 0;
  }
`;
const StyledLogo = styled.img`
  height: ${(props) => props.theme.layout.header.logo.height};
  width: ${(props) => props.theme.layout.header.logo.width};
  @media screen and (max-width: 1100px) {
    font-size: ${(props) => props.theme.fonts.size.subtitle1};
    margin-top: 0;
  }
`;
const StyledLink = styled(Link)`
  text-decoration: none;
  color: white;
  margin-top: ${(props) => props.theme.layout.header.logo.marginTop};
  display: flex;
  margin-left: 6%;
`;
const GridWithBg = styled(Grid)`
background-image: ${(props) => props.theme.layout.header.bgImage};
background-repeat: ${(props) => props.theme.layout.header.bgRepeat};
background-size: contain;
min-width: 648px;
min-height: 200px;
`;
function HeaderComponent(props) {
  const { t } = useTranslation();

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <StyledAppBar id='header' container>
          <Grid container>
            <GridWithBg item xs={12} md={8} xl={8} >
              <StyledLink to='/' >
                <StyledLogo src={props.logo} />
              </StyledLink>
            </GridWithBg>
            <Grid item xs={12} md={4} xl={4}>
              <StyledH2>{t(props.translations?.headerTitle)}</StyledH2>
            </Grid>
          </Grid>
        </StyledAppBar>
        {props.children}
      </Box>
    </>
  );
}

export default withTheme(HeaderComponent);
