import React, { Component } from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import { Snackbar } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { resetSuccessAndError, permissionsError } from '../actions';
import { useHistory } from 'react-router-dom';
import { logout } from '@pgi/common/fe/utility';

const ErrorSnackbar = styled(Snackbar)`
  &.MuiSnackbar-root {
    height: 30px;
    width: 300px;
  }
  & .MuiSnackbarContent-root {
    background-color: ${(props) => props.theme.colors.error};
  }
  & .MuiSnackbarContent-message {
    color: ${(props) => props.theme.colors.bg};
    font-weight: bold;
  }
`;
const SuccessSnackbar = styled(Snackbar)`
  &.MuiSnackbar-root {
    height: 30px;
    width: 300px;
  }
  & .MuiSnackbarContent-root {
    background-color: ${(props) => props.theme.colors.success};
  }
  & .MuiSnackbarContent-message {
    color: ${(props) => props.theme.colors.bg};
  }
`;

function SnackBar(props) {
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    if (props.error) {
      if (props.error.includes(417)) {
        setErrorMsg(t('errorMsg.417'));
      } else if (props.error.includes(406)) {
        setErrorMsg(t('errorMsg.406'));
      } else if (props.error.includes(403)) {
        setErrorMsg(t('errorMsg.403'));
        logout();
        history.push('/access');
      } else if (props.error.includes(404)) {
        setErrorMsg(t('errorMsg.404'));
      } else if (props.error.includes(500)) {
        setErrorMsg(t('errorMsg.500'));
      } else {
        setErrorMsg(props.error);
      }
    } else if (props.success) {
      setSuccessMsg(props.success.msg);
    } else {
      setSuccessMsg('');
      setErrorMsg('');
    }
  }, [props.error, props.success]);

  const handleClose = () => {
    setSuccessMsg('');
    setErrorMsg('');
    props.resetSuccessAndError();
  };

  return (
    <>
      <ErrorSnackbar
        open={errorMsg ? true : false}
        message={errorMsg}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={3000}
        onClose={handleClose}
      />

      <SuccessSnackbar
        open={successMsg ? true : false}
        message={successMsg}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={3000}
        onClose={handleClose}
      />
    </>
  );
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    error: state.updates.error,
    success: state.updates.success,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetSuccessAndError: (data) => {
      dispatch(resetSuccessAndError(data));
    },
    permissionsError: (data) => {
      dispatch(permissionsError(data));
    },
  };
};

export default withTheme(
  connect(mapStateToProps, mapDispatchToProps)(SnackBar)
);
