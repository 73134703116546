import React, { Component } from 'react';
import { Button, Paper, Divider } from '@material-ui/core/';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';
import {
  createVcVaccine,
  downloadPdf,
  sendCertificate,
  loadTracking,
  loadHealthOrg,
  sendingCertificateWithoutEmailError,
  reloadVaccineQrcode,
} from '../actions';
import { useTranslation } from 'react-i18next';
import sendIcon from './assets/send-icon.svg';
import printIcon from './assets/print-icon.svg';
import qrcodeIcon from './assets/qrcodeIcon.svg';
import QrModal from './qrmodal';
import icon from './assets/icon-Atention.svg';
import { parseDate, parseTime, hasPermissions, getTime } from '../commons';
import { RightContentDiv } from '@pgi/common/fe/styles/common-styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import BreadCrumbs from './breadcrumbs';

const StyledDiv = styled.div`
  margin-left: 5%;
`;
const StyledDivider = styled(Divider)`
  margin-bottom: 50px;
`;
const StyledPaper = styled(Paper)`
  &.MuiPaper-rounded {
    border-radius: 12px;
    height: 330px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  padding: 25px;
  width: 95%;
  margin-top: 30px;
  padding-top: 10px;
`;
const ButtonOutlined = styled(Button)`
  &.MuiButton-root {
    color: ${(props) => props.theme.buttons.secondary.color};
    border-radius: 50px;
    margin-right: 30px;
    padding: 12px 16px;
    font-size: ${(props) => props.theme.fonts.size.subtitle2};
    font-weight: ${(props) => props.theme.fonts.weight.semiBold};
    line-height: 16px;
    letter-spacing: 1.25px;
    text-align: left;
  }
  &.MuiButton-root:hover {
    background-color: ${(props) => props.theme.buttons.secondary.bg};
  }
`;
const ButtonGreen = styled(Button)`
  &.MuiButton-root {
    color: white;
    border-radius: 12px;
    background-color: #6bdd3e;
    padding: 12px 16px;
    font-size: ${(props) => props.theme.fonts.size.subtitle2};
    font-weight: ${(props) => props.theme.fonts.weight.semiBold};
    line-height: 16px;
    letter-spacing: 1.25px;
    text-align: left;
  }
  &.MuiButton-root:hover {
    background-color: #6bdd3e;
  }
`;
const StyledOverline = styled.div`
  font-family: ${(props) => props.theme.fonts.openSans};
  font-size: ${(props) => props.theme.fonts.size.overline};
  font-weight: ${(props) => props.theme.fonts.weight.semiBold};
  letter-spacing: 1.5px;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
const StyledTitle = styled.h2`
  font-family: ${(props) => props.theme.fonts.openSans};
  font-size: ${(props) => props.theme.fonts.size.headline6};
  font-weight: ${(props) => props.theme.fonts.weight.semiBold};
`;
const StyledP = styled.p`
  font-family: ${(props) => props.theme.fonts.openSans};
  font-size: ${(props) => props.theme.fonts.size.subtitle1};
  font-weight: ${(props) => props.theme.fonts.weight.regular};
`;
const StyledSubtitle = styled.p`
  font-family: ${(props) => props.theme.fonts.openSans};
  font-size: ${(props) => props.theme.fonts.size.subtitle2};
  font-weight: ${(props) => props.theme.fonts.weight.regular};
  color: rgba(0, 0, 0, 0.6);
`;
const StyledIcon = styled.img`
  height: 24px;
  width: 24px;
  cursor: pointer;
`;
const StyledButtonIcon = styled.img`
  margin-right: 10px;
`;
const StyledPageTitle = styled.h2`
  align-self: flex-start;
  margin-top: 1%;
  font-family: ${(props) => props.theme.fonts.openSans};
  font-size: ${(props) => props.theme.fonts.size.headline6};
  font-weight: ${(props) => props.theme.fonts.weight.semiBold};
  line-height: 24px;
  letter-spacing: 0.15px;
  text-align: left;
`;
function VaccinationDetails(props) {
  const { t } = useTranslation();
  const { vaccinationId } = useParams();
  const [qrCode, setQrCode] = useState(false);
  const history = useHistory();

  const handleClose = () => {
    setQrCode(false);
  };

  useEffect(() => {
    props.reloadVaccineQrcode();
  }, []);

  useEffect(() => {
    if (!hasPermissions(props.permissions, { admin: 'O' })) {
      props.loadTracking({ trackingId: vaccinationId });
    } else {
      history.push('/patients');
    }
  }, [vaccinationId, props.permissions]);

  useEffect(() => {
    setQrCode(props.vc);
  }, [props.vc]);

  const validateAndSendCertificate = () => {
    if (!props?.vaccination?.Patient.email) {
      props.sendCertificateWithoutEmailError('Patient details do not have the email address to send the certificate.');
      return;
    }
    props.sendCertificate({ trackingId: vaccinationId });
  };

  return (
    <>
      {qrCode && <QrModal qrCode={qrCode} handleClose={handleClose} />}
      <RightContentDiv>
        {props.vaccination && (
          <>
            <BreadCrumbs />
            <StyledPageTitle>{t('general.vaccdetails')}</StyledPageTitle>
            <StyledPaper key={props.vaccination.id} variant='outlined'>
              <StyledOverline>
                {props.vaccination.Vaccine &&
                  props.vaccination.Vaccine.manufacturer.toUpperCase()}
              </StyledOverline>
              <StyledTitle>
                {props.vaccination.Vaccine &&
                  props.vaccination.Vaccine.profilaxis}
              </StyledTitle>
              <StyledP>
                {parseDate(props.vaccination.vaccinationDate)} • {' '}
                {getTime(props.vaccination.vaccinationDate)} • {' '}
                {props.vaccination.shotNumber} out of 2 •{' '}
                {props.vaccination.HealthOrg &&
                  props.vaccination.HealthOrg.name}
              </StyledP>
              <Row>
                <ButtonOutlined
                  variant='outlined'
                  onClick={() =>
                    props.downloadPdf({ trackingId: vaccinationId })
                  }
                >
                  <StyledButtonIcon src={printIcon} alt='print-icon' />
                  {t('buttons.print')}
                </ButtonOutlined>
                <ButtonOutlined
                  variant='outlined'
                  onClick={validateAndSendCertificate}
                >
                  <StyledButtonIcon src={sendIcon} alt='send-icon' />
                  {t('buttons.send')}
                </ButtonOutlined>
                <ButtonOutlined
                  variant='outlined'
                  onClick={() =>
                    props.createVcVaccine({ trackingId: vaccinationId })
                  }
                >
                  <StyledButtonIcon src={qrcodeIcon} alt='qrcode-icon' />
                  {t('buttons.scan')}
                </ButtonOutlined>
              </Row>
              <div>
                <StyledSubtitle>
                  {t('vaccdetails.nextdate')}:{' '}
                  <b>{parseDate(props.vaccination.nextDate)}</b>
                </StyledSubtitle>
                <StyledSubtitle>
                  {t('vaccdetails.nexttime')}:{' '}
                  {parseTime(props.vaccination.nextDate)}
                </StyledSubtitle>
              </div>
              <StyledDivider />
              <ArrowBackIcon
                onClick={() => history.goBack()}
                style={{ cursor: 'pointer' }}
              />
            </StyledPaper>
          </>
        )}
      </RightContentDiv>
    </>
  );
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    mainContact: state.updates.mainContact,
    vaccination: state.updates.vaccination,
    healthorg: state.updates.healthorg,
    vc: state.updates.vc,
    permissions: state.updates.permissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createVcVaccine: (data) => {
      dispatch(createVcVaccine(data));
    },
    downloadPdf: (data) => {
      dispatch(downloadPdf(data));
    },
    sendCertificate: (data) => {
      dispatch(sendCertificate(data));
    },
    sendCertificateWithoutEmailError: (data) => {
      dispatch(sendingCertificateWithoutEmailError(data));
    },
    loadTracking: (data) => {
      dispatch(loadTracking(data));
    },
    loadHealthOrg: (data) => {
      dispatch(loadHealthOrg(data));
    },
    reloadVaccineQrcode: (data) => {
      dispatch(reloadVaccineQrcode(data));
    },
  };
};

export default withTheme(
  connect(mapStateToProps, mapDispatchToProps)(VaccinationDetails)
);
