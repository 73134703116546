import React from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Drawer, Divider, Hidden, Grid } from '@material-ui/core';
import SidebarMenu from './sidebarmenu';
import hoLogo from './assets/logo-sta-ana.svg';
import { Link } from 'react-router-dom';

const StyledDrawer = styled(Drawer)`
  & .MuiDrawer-paper {
    width: ${(props) => props.theme.layout.sidebar.width};
    padding-left: 10px;
    padding-right: 0px;
    padding-top: 18px;
    z-index: 1500;
  }
`;
const SmDrawer = styled(Drawer)`
  &. MuiDrawer-root {
    z-index: 100 !important;
  }
  & .MuiDrawer-paper {
    width: 80%;
    max-width: 300px;
    top: 8%;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 50px;
  }
  & .MuiBackdrop-root {
    top: 8%;
  }
`;
const StyledLogo = styled.img`
  width: ${(props) => props.theme.layout.sidebar.logo.width};
  height: ${(props) => props.theme.layout.sidebar.logo.height};
`;

const StyledTitle = styled.h5`
  font-family: ${(props) => props.theme.fonts.openSans};
  font-size: ${(props) => props.theme.fonts.size.headline6};
  font-weight: ${(props) => props.theme.fonts.weight.headline6};
`;
const StyledDivider = styled(Divider)`
  &.MuiDivider-root {
    margin-top: 20px;
  }
`;

function SidebarView(props) {
  const { t } = useTranslation();
  const tenant = JSON.parse(localStorage.getItem('health_org'));
  const HOLogo = `data:image/${tenant?.logo?.ext};base64,${tenant?.logo?.value}`;
  return (
    <>
      <Hidden smDown>
        <Grid item >
          <StyledDrawer variant='permanent' id="sidebar-nav">
            <Link to='/patients'>
              <StyledLogo src={HOLogo} />
            </Link>
            {/* <StyledTitle>{t('subtitle.mos')}</StyledTitle> */}
            <StyledDivider />
            <SidebarMenu />
          </StyledDrawer>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <SmDrawer variant='temporary' anchor='left' open={props.isMenuOpen}>
          <Link to='/patients'>
            <StyledLogo src={hoLogo} />
          </Link>
          <StyledTitle>{t('subtitle.mos')}</StyledTitle>
          <StyledDivider />
          <SidebarMenu />
        </SmDrawer>
      </Hidden>
    </>
  );
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    mainContact: state.updates.mainContact,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTheme(
  connect(mapStateToProps, mapDispatchToProps)(SidebarView)
);
