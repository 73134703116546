import { Container, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import ScanQrCode from './ScanQrCode';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import hotelBg from '../assets/hotel/hotel-header-bg.png';
import hotelLogo from '../assets/hotel/tower-logo.png';
import './Home.css';
import { Link, useHistory } from 'react-router-dom';
import Header from './Header';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,

    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    qrcode: {
        padding: '24px',
        textAlign: 'center',
        zIndex: 1,
        position: 'relative',
        top: '-50px',
        display: 'inline-block',
        borderRadius: '12px',
        boxShadow: '4px 11px 40px 0px rgba(0, 0, 0, 0.1)'
    },
    h2: {
        fontSize: '56px',
        fontWeight: '200',
        lineHeight: '64px',
        color: '#333333',
        fontFamily: 'Nunito Sans, sans-serif',
    },
    welcome: {
        marginTop: '8%',
        marginBottom: '5%',
        fontFamily: 'Nunito Sans, sans-serif',
    },
    p: {
        fontSize: '18px',
        color: '#33333380',
        maxWidth: '406px',
        fontFamily: 'Nunito Sans, sans-serif',
    },
    ul: {
        listStyleType: 'none',
        margin: 0,
        padding: 0,
        overflow: 'hidden',
        float: 'right'
    },
    li: {
        display: 'inline-block',
    },
    link: {
        color: 'rgb(255, 255, 255)',
        textAlign: 'center',
        padding: '16px',
        textDecoration: 'none',
        fontFamily: 'Nunito Sans, sans-serif',
        fontWeight: 200
    },
    menuContainer: {

    },
    headerContainer: {
        paddingTop: '40px',
        paddingRight: '81px',
        paddingLeft: '51px',
    },
    hotelContanier: {
        [theme.breakpoints.up('xl')]: {
            backgroundColor: 'red',
        },
    }
}));
const Home = (props) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const history = useHistory();
    const classes = useStyles();

    const handleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    history.listen(() => {
        setIsMenuOpen(false);
    });

    return (
        <React.Fragment>
            <Container maxWidth='xl' spacing={0} style={{ padding: 0, background: 'rgb(237, 241, 245)', height: 'fit-content' }} className={classes.hotelContanier}>
                <Grid container direction="row" justifyContent="center" >
                    <Grid container style={{ backgroundImage: `url(${hotelBg})`, height: '50vh', maxHeight: '489px' }} className={classes.headerContainer}>
                        <Grid item xs>
                            <Link to='/example/hotel'><img src={hotelLogo} alt='hotel-logo' style={{ width: '211px' }} /></Link>
                        </Grid>
                        <Grid item xs className={classes.menuContainer}>
                            <Header {...props} handleMenu={handleMenu} isMenuOpen={isMenuOpen} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ minHeight: '50vh' }}>
                        <Grid container alignItems='center' justifyContent='center' >
                            <Grid container item xs={12} sm={12} md={7} lg={7} direction="row" justifyContent='center' spacing={2} style={{ marginBottom: '0' }}>
                                <Grid item xs={12} sm={12} md={6} style={{ marginBottom: '12%' }}>
                                    <div className={classes.welcome}>
                                        <h2 className={classes.h2} >Welcome</h2>
                                        <h2 className={classes.h2}>to our hotel!</h2>
                                    </div>
                                    <p className={classes.p}>In order to prove your vaccination status and check-in, please scan the QR code with the <b>MY</b>ID App to verify as vaccinated.</p>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Paper className={classes.qrcode}>
                                        <ScanQrCode />
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment>
    );
};

export default Home;
