import React, { Component } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import { AppBar } from '@material-ui/core';
import defaultLogo from './assets/logo-sta-ana.png';
import { useTranslation } from 'react-i18next';
import { resetSuccessAndError } from '../actions';
import SnackBar from './snackbar';
import { Link } from 'react-router-dom';
import HeaderComponent from '@pgi/common/fe/components/header';

const StyledAppBar = styled(AppBar)`
  height: ${(props) => props.theme.layout.header.height};
  &.MuiAppBar-root {
    background-image: ${(props) => props.theme.layout.header.bgImage};
    background-repeat: ${(props) => props.theme.layout.header.bgRepeat};
    background-size: contain;
    padding-left: ${(props) => props.theme.layout.header.paddingLeft};
    @media screen and (max-width: 800px) {
      background-size: 100%;
    }
  }
  &.MuiAppBar-positionFixed {
    position: unset;
  }
  &.MuiAppBar-colorPrimary {
    background-color: ${(props) => props.theme.colors.transparent};
  }
  &.MuiPaper-elevation4 {
    box-shadow: ${(props) => props.theme.layout.header.boxShadow};
  }
`;
const StyledH2 = styled.h2`
  font-family: ${(props) => props.theme.fonts.openSans};
  font-size: ${(props) => props.theme.fonts.size.apptitle};
  @media screen and (max-width: 1100px) {
    font-size: ${(props) => props.theme.fonts.size.subtitle1};
    margin-top: 0;
  }
  font-weight: ${(props) => props.theme.fonts.weight.semiBold};
  z-index: 10;
  display: inline-block;
  float: right;
  color: ${(props) => props.theme.layout.header.title.color};
  width: 29%;
  text-align: right;
  margin-right: 2em;
  text-shadow: 0px 0px 1px black;
`;
const StyledLogo = styled.img`
  height: ${(props) => props.theme.layout.header.logo.height};
  width: ${(props) => props.theme.layout.header.logo.width};
  margin-top: ${(props) => props.theme.layout.header.logo.marginTop};
  z-index: 10;
  @media screen and (max-width: 1100px) {
    font-size: ${(props) => props.theme.fonts.size.subtitle1};
    margin-top: 0;
  }
`;
const StyledSpan = styled.span``;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: white;
`;
const StyledAppTitleDiv = styled.div`
  margin-top: ${(props) => props.theme.layout.header.title.marginTop};
  display: flex;
  flex-direction: column;
  align-items: end;
`;

function Header(props) {
  const { t } = useTranslation();

  useEffect(() => {
    props.resetSuccessAndError();
  }, []);
  const hoLogo = props.tenantinfo?.logo?.value || defaultLogo;
  return (
    <>
      <HeaderComponent {...props} logo={hoLogo} />
      <SnackBar {...props} />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    tenantinfo: state.tenant,
    error: state.updates.error,
    success: state.updates.success,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetSuccessAndError: (data) => {
      dispatch(resetSuccessAndError(data));
    },
  };
};

export default withTheme(connect(mapStateToProps, mapDispatchToProps)(Header));
