import { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import {
  storePermissions,
  storeUser,
  storeToken,
  resetSuccessAndError,
  storeMainContact,
} from '../../actions';
// import API from '../../api';
import API from '@pgi/common/fe/api';
import { Grid, TextField, Button, FormGroup } from '@material-ui/core';
import { useToken } from '../../storage';
import Header from '../header';
import { useTranslation } from 'react-i18next';
import styled, { withTheme } from 'styled-components';
import SnackBar from '../snackbar';
import { useHistory } from 'react-router-dom';

const StyledTitle = styled.div`
  font-family: ${(props) => props.theme.fonts.openSans};
  font-size: ${(props) => props.theme.fonts.size.headline5};
  font-weight: ${(props) => props.theme.fonts.weight.bold};
  line-height: 32px;
  letter-spacing: 0.18px;
  text-align: center;
  color: ${(props) => props.theme.colors.primary};
`;
const StyledGrid = styled(Grid)`
  &.MuiGrid-container {
  }
`;
const StyledP = styled.p`
  font-family: ${(props) => props.theme.fonts.openSans};
  font-size: ${(props) => props.theme.fonts.size.subtitle1};
  font-weight: ${(props) => props.theme.fonts.weight.regular};
  line-height: 24px;
  letter-spacing: 0.15px;
  text-align: center;
  width: 25%;
  min-width: 200px;
  margin: 20px;
`;
const StyledBar = styled.div`
  background-color: ${(props) => props.theme.colors.primary};
`;
const MAX_TIME = 60;

const StyledTextField = styled(TextField)`
  & .MuiFilledInput-root {
    background-color: ${(props) => props.theme.colors.bg};
    border: 1px solid rgba(0, 0, 0, 0.12);
    transition: unset;
    border-radius: 4px;
  }
  & .MuiFilledInput-underline:before {
    border: 0;
  }
  & .MuiFilledInput-underline:after {
    border-color: ${(props) => props.theme.colors.onSurface};
  }
  & .MuiFilledInput-root:hover {
    background-color: ${(props) => props.theme.colors.bg};
  }
  & .MuiFilledInput-root.Mui-focused {
    background-color: ${(props) => props.theme.colors.bg};
  }
  & .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.colors.onSurface};
  }
  & .MuiInputBase-input.Mui-disabled {
    background-color: ${(props) => props.theme.colors.bg};
    opacity: unset;
    border-radius: 4px;
  }
  & .MuiFilledInput-root.Mui-disabled {
    background-color: unset;
    color: ${(props) => props.theme.colors.disabled};
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
  & .MuiInputLabel-formControl {
    top: 20px;
    font-family: ${(props) => props.theme.fonts.openSans};
    font-size: ${(props) => props.theme.fonts.size.subtitle1};
    font-weight: ${(props) => props.theme.fonts.weight.regular};
    line-height: 24px;
    letter-spacing: 0.15px;
    text-align: left;
  }
`;

const PrimaryButton = styled(Button)`
  &.MuiButton-contained {
    min-width: 100px;
    margin-top: 25px;
    border-radius: ${(props) => props.theme.buttons.primary.radius};
    background-color: ${(props) => props.theme.buttons.primary.bg};
    color: ${(props) => props.theme.buttons.primary.color};
    font-family: ${(props) => props.theme.fonts.openSans};
    font-weight: ${(props) => props.theme.fonts.weight.semiBold};
    font-size: ${(props) => props.theme.fonts.size.subititle2};
    letter-spacing: ${(props) => props.theme.fonts.letter.button};
    margin-left: 10px;
  }
  &.MuiButton-contained:hover {
    background-color: ${(props) => props.theme.buttons.primary.bg};
    color: ${(props) => props.theme.buttons.primary.color};
  }
  &.MuiButton-contained.Mui-disabled {
    color: ${(props) => props.theme.buttons.disabled.color};
    background-color: ${(props) => props.theme.buttons.disabled.bg};
  }
`;

let email, pass = '';

function Login(props) {
  const [token, setToken] = useToken();
  const [qrcode, setQrcode] = useState('');
  const [error, setError] = useState('');
  const [counter, setCounter] = useState(0);
  const { t } = useTranslation();
  const history = useHistory();

  const countRef = useRef(counter);
  countRef.current = counter;

  useEffect(() => {
    props.resetSuccessAndError();
  }, []);

  const onMessage = (message) => {
    try {
      const parsed = message.data;
      if (parsed.token) {
        setToken(parsed.token);
        API.setAuthToken(parsed.token);
        localStorage.setItem('token', parsed.token);
        localStorage.setItem('permissions', JSON.stringify(parsed.permissions));
        localStorage.setItem('mainContact', JSON.stringify(parsed.mainContact));
        props.storePermissions(parsed.permissions);
        props.storeMainContact(parsed.mainContact);
        props.storeUser(parsed);
        props.storeToken(parsed.token);
        history.push('/patients');
      }
    } catch (e) {
      console.log(e);
    }
  };

  const validation = () => {
    if (email.length > 0) {
      if (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email)) {
        return true;
      } else {
        window.alert(`invalid email ${email}`);
      }
    }
    return false;
  };

  const handleInputChange = (e) => {
    switch (e.target.name) {
      case 'email':
        email = e.target.value;
        break;
      case 'pass':
        pass = e.target.value;
        break;
    }
  };

  const handleSubmit = async () => {
    try {
      //const users = await API.post('/bypass_mos_email/', { email: email });
      const users = await API.post('/bypass-api/mos/', { email: email, token: pass });
      email = '';
      pass = '';
      // console.log('bypass user', users);
      if (users) {
        onMessage(users);
      }
    } catch (error) {
      console.log('this error', error);
      window.alert(error);
    }
    // if (validation()) {
    //   console.log('here', email);
    //   try {
    //     const users = await API.post('/bypass_mos_email/', { email: email });
    //     email = '';
    //     // console.log('bypass user', users);
    //     if (users) {
    //       onMessage(users);
    //     }
    //   } catch (error) {
    //     console.log('this error', error);
    //     window.alert(error);
    //   }
    // } else {
    //   console.log('not submitted', email);
    // }
  };

  const onKeyUp = (event) => {
    if (event.key === 'Enter' || event.charCode === 13 || event.code === 'NumpadEnter') {
      handleSubmit();
    }
  };

  return (
    <>
      <Header />
      <SnackBar />
      <FormGroup className='form_label' onKeyPress={onKeyUp}>
        <StyledGrid
          container
          direction='column'
          justify='center'
          alignItems='center'
        >
          <StyledTitle>{t('subtitle.mos')}</StyledTitle>
          <StyledP>{t('general.login')}</StyledP>
          <div>Please submit email to login</div>
          <StyledTextField
            name='email'
            //value={fields.identifier}
            onChange={handleInputChange}
            placeholder='Email'
          />
          <br />
          <StyledTextField
            name='pass'
            type='password'
            //value={fields.identifier}
            onChange={handleInputChange}
            placeholder='Password'
          />
          <br />
          <PrimaryButton
            type='submit'
            onClick={handleSubmit}
            variant='contained'
            disableElevation
          > {t('buttons.submit')}</PrimaryButton>
        </StyledGrid>
      </FormGroup>
    </>
  );
}
const mapStateToProps = (state /*, ownProps*/) => {
  return {
    error: state.updates.error,
    success: state.updates.success,
    hoId: state.updates.hoId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    storePermissions: (data) => {
      dispatch(storePermissions(data));
    },
    storeMainContact: (data) => {
      dispatch(storeMainContact(data));
    },
    storeUser: (data) => {
      dispatch(storeUser(data));
    },
    storeToken: (data) => {
      dispatch(storeToken(data));
    },
    resetSuccessAndError: (data) => {
      dispatch(resetSuccessAndError(data));
    },
  };
};

export default withTheme(connect(mapStateToProps, mapDispatchToProps)(Login));
