import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { withTheme } from 'styled-components';
import PublicHeader from '@pgi/common/fe/components/publicheader';
import defaultLogo from '../assets/logo-sta-ana.png';
import SnackBar from '../snackbar';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getTenantInfo } from 'src/actions';
import backgroundGrey from '../assets/grey-waves.svg';
import backgroundBlue from '../assets/blue-waves.svg';

const TENANT_TYPE = 'health_org';
const LandingLayout = (props) => {
    const translations = {
        headerTitle: 'avatarbar.title',
    };

    const [phaLogo, setPhaLogo] = useState(defaultLogo);
    const [logoUrl, setLogoUrl] = useState('/');
    const [background, setBackground] = useState(backgroundBlue);
    let tenantObj = {};
    useEffect(() => {
        if (Object.keys(props.tenant).length) {
            localStorage.setItem('health_org', JSON.stringify(props.tenant));
            localStorage.setItem('is_health_org', true);
        }
        tenantObj = JSON.parse(localStorage.getItem('health_org'));
        setBackground(backgroundGrey);
        setPhaLogo(`data:image/${tenantObj?.logo?.ext};base64,${tenantObj?.logo?.value}`);
        setLogoUrl(`/?tenant_id=${tenantObj?.tenant_id}`);
    }, [props.tenant]);
    const properties = { ...props, translations, logo: phaLogo, url: logoUrl };

    return (
        <React.Fragment>
            <Box sx={{ flexGrow: 1 }}>
                {phaLogo && <PublicHeader {...properties} logo={phaLogo} url={logoUrl} background={background} />}
                <Box style={{ marginTop: '5%' }}>
                    {props.children}
                </Box>
            </Box>
            <SnackBar {...props} />
        </React.Fragment>
    );
};
const mapStateToProps = (state) => {
    return {
        tenant: state.updates.tenant,
        error: state.updates.error,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getTenantInfo: (data) => {
            dispatch(getTenantInfo(data));
        }
    };
};
export default withTheme(connect(mapStateToProps, mapDispatchToProps)(LandingLayout));
