import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, Card, Grid } from '@material-ui/core';
import styled, { withTheme } from 'styled-components';
import requestIcon from './assets/app-request-icon.svg';
import mosIcon from './assets/app-request-access.svg';
import RequestCard from '@pgi/common/fe/components/requestcard';
import TabCard from '@pgi/common/fe/components/tabcard';
import {
  StyledContainer,
  StyledDiv,
  StyledTitle,
  StyledText,
  RequestButton,
  AccessButton,
  StyledP,
  Bold,
} from '@pgi/common/fe/styles/styles';

function Mos(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const requestTrans = {
    title: 'subtitle.access',
    btnText: 'request.button',
  };
  const appTrans = {
    title: 'subtitle.mos',
    btnText: 'mos.button',
  };
  const requestProps = {
    ...props,
    translations: requestTrans,
    appUri: '/request',
    appIcon: requestIcon,
    btntype: RequestButton,
  };
  const accessProps = {
    ...props,
    translations: appTrans,
    appUri: '/access',
    appIcon: mosIcon,
    btntype: AccessButton,
  };

  return (
    <>
      <StyledDiv>
        <Grid container direction="row" justifyContent="center" spacing={2}>
          <Grid item xs={10} sm={3} md={3} lg={2} xl={2}>
            <TabCard {...requestProps} variant='outlined'>
            <StyledP>
              {t('request.paragraph1')}
              <Bold>{t('request.bold')}</Bold>
              {t('request.paragraph2')}
            </StyledP>
            </TabCard>
          </Grid>
          <Grid item xs={10} sm={3} md={3} lg={2} xl={2}>
            <TabCard {...accessProps}>
              <StyledP>{t('mos.paragraph')}</StyledP>
            </TabCard>
          </Grid>
        </Grid>
      </StyledDiv>
    </>
  );
}

const mapStateToProps = (/*state , ownProps*/) => {
  return {};
};

const mapDispatchToProps = (/*dispatch*/) => {
  return {};
};

export default withTheme(connect(mapStateToProps, mapDispatchToProps)(Mos));
