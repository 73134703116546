import React from 'react';

import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    useMediaQuery,
    Button,
    useScrollTrigger,
    Slide,
    Menu,
    MenuItem,
    ListItemIcon
} from '@material-ui/core';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { BrowserRouter, Route, Switch, Link } from 'react-router-dom';

import MenuIcon from '@material-ui/icons/Menu';
import SchoolIcon from '@material-ui/icons/RoomService';
import PersonIcon from '@material-ui/icons/Room';
import BookmarksIcon from '@material-ui/icons/Note';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    title: {
        flexGrow: 1
    },
    ul: {
        listStyleType: 'none',
        margin: 0,
        padding: 0,
        overflow: 'hidden',

        float: 'right'
    },
    li: {

        display: 'inline-block',

    },
    link: {
        color: 'rgb(255, 255, 255)',
        textAlign: 'center',
        padding: '16px',
        textDecoration: 'none',
        fontFamily: 'Nunito Sans, sans-serif',
        fontWeight: 200
    },
}));

function HideOnScroll(props) {
    const { children } = props;
    const trigger = useScrollTrigger();

    return (
        <Slide appear={false} direction={'down'} in={!trigger}>
            {children}
        </Slide>
    );
}
const Header = (props) => {
    const classes = useStyles();
    const [anchor, setAnchor] = React.useState(null);
    const open = Boolean(anchor);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const handleMenu = (event) => {
        setAnchor(event.currentTarget);
    };
    return (
        <React.Fragment>
            <HideOnScroll {...props}>
                <BrowserRouter>
                    <AppBar position="static" style={{ background: 'transparent', boxShadow: 'none', }}>
                        {isMobile ? (
                            <>
                                <IconButton
                                    color='textPrimary'
                                    className={classes.menuButton}
                                    edge='end'
                                    aria-label='menu'
                                    onClick={handleMenu}
                                    style={{ justifyContent: 'right' }}
                                >
                                    <MenuIcon style={{ color: 'white' }} />
                                </IconButton>
                                <Menu
                                    id='menu-appbar'
                                    anchorEl={anchor}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right'
                                    }}
                                    KeepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right'
                                    }}
                                    open={open || props.isMenuOpen}
                                >
                                    <MenuItem
                                        onClick={() => setAnchor(null)}
                                        component={Link}
                                        to='/example/hotel'
                                    >
                                        <ListItemIcon>
                                            <SchoolIcon />
                                        </ListItemIcon>
                                        <Typography variant='h6'> About </Typography>
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => setAnchor(null)}
                                        component={Link}
                                        to='/example/hotel'
                                    >
                                        <ListItemIcon>
                                            <SchoolIcon />
                                        </ListItemIcon>
                                        <Typography variant='h6'> Service </Typography>
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => setAnchor(null)}
                                        component={Link}
                                        to='/example/hotel'
                                    >
                                        <ListItemIcon>
                                            <PersonIcon />
                                        </ListItemIcon>
                                        <Typography variant='h6'> Rooms</Typography>
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => setAnchor(null)}
                                        component={Link}
                                        to='/example/hotel'
                                    >
                                        <ListItemIcon>
                                            <BookmarksIcon />
                                        </ListItemIcon>
                                        <Typography variant='h6'> Blog </Typography>
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => setAnchor(null)}
                                        component={Link}
                                        to='/example/hotel'
                                    >
                                        <ListItemIcon>
                                            <BookmarksIcon />
                                        </ListItemIcon>
                                        <Typography variant='h6'> Contact </Typography>
                                    </MenuItem>

                                </Menu>
                            </>
                        ) : (
                            <div style={{ marginRight: '2rem' }}>
                                <ul className={classes.ul}>
                                    <li className={classes.li}><a className={classes.link} href='#'>About</a></li>
                                    <li className={classes.li}><a className={classes.link} href='#'>Service</a></li>
                                    <li className={classes.li}><a className={classes.link} href='#'>Rooms</a></li>
                                    <li className={classes.li}><a className={classes.link} href='#'>Blog</a></li>
                                    <li className={classes.li}><a className={classes.link} href='#'>Contact</a></li>
                                </ul>
                            </div>
                        )}

                    </AppBar>
                    <Switch>
                        <Route exact path='/example/hotel' {...props} />
                        <Route exact path='/example/hotel' />
                        <Route exact path='/example/hotel' />
                        <Route exact path='/example/hotel' />
                    </Switch>
                </BrowserRouter>
            </HideOnScroll>
        </React.Fragment >
    );
};

export default Header;
