import React, { Component } from 'react';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { DataGrid } from '@material-ui/data-grid';
import {
  Button,
  TextField,
  Box,
  InputAdornment,
  Menu,
  MenuItem,
} from '@material-ui/core';
import styled, { withTheme } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { loadPatients } from '../actions';
import { useTranslation } from 'react-i18next';
import addIcon from './assets/add-icon.svg';
import qrcodeIcon from './assets/qrcodeIcon.svg';
import searchIcon from './assets/search.svg';
import moreIcon from './assets/more.svg';
import Avatar from './avatar';
import { hasPermissions, parseDate } from '../commons';
import BreadCrumbs from './breadcrumbs';
import { PrimaryButton, StyledDataGrid } from '@pgi/common/fe/styles/common-styles';

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 98.5%;
`;
const StyledBtnsDiv = styled.div`
  display: flex;
`;

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
`;

const StyledTextField = styled(TextField)`
  background-color: ${(props) => props.theme.colors.bg};
  & .MuiOutlinedInput-root {
    height: 40px;
    font-size: ${(props) => props.theme.fonts.size.caption};
    font-weight: ${(props) => props.theme.fonts.weight.regular};
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: left;
    border-radius: 12px;
    border-color: ${(props) => props.theme.colors.bg};
  }
  &.MuiFormControl-root {
    border-radius: 12px;
  }
  & .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.colors.bg};
  }
`;

const StyledMenu = styled(Menu)`
  & .MuiPaper-elevation0 {
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.04),
      0px 1px 18px rgba(0, 0, 0, 0.012), 0px 3px 5px rgba(0, 0, 0, 0.1);
  }
  &.MuiPaper-paper {
    padding: 12px;
    border-radius: 12px;
  }
  & .MuiPaper-rounded {
    border-radius: 12px;
  }
`;
const StyledNameCol = styled.div`
  display: flex;
  font-weight: ${(props) => props.theme.fonts.weight.semiBold};
`;
const MoreButton = styled(Button)`
  &.MuiButton-root:hover {
    background-color: ${(props) => props.theme.colors.transparent};
  }
`;

function PatientList(props) {
  const history = useHistory();
  const [input, setInput] = useState('');
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(false);
  const [matches, setMatches] = useState();
  const [currentId, setCurrentId] = useState(null);

  useEffect(() => {
    props.loadPatients();
  }, []);

  useEffect(() => {
    const matchesArr = [];
    props.patients &&
      props.patients.map((patient) => {
        if (
          patient.firstName.toLowerCase().includes(input.toLowerCase()) ||
          patient.lastName.toLowerCase().includes(input.toLowerCase()) ||
          parseDate(patient.birthdate).includes(input)
        ) {
          if (matchesArr.includes(patient)) {
            return matchesArr;
          } else {
            matchesArr.push(patient);
          }
        }

        return null;
      });
    setMatches(matchesArr);
  }, [input, props.patients]);

  const addNew = () => {
    history.push('patients/new');
  };

  const handleClick = (e) => {
    setAnchorEl(e.target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns = [
    {
      field: 'firstName',
      headerName: 'NAME',
      flex: 1,
      type: 'string',
      sortable: true,
      renderCell: (params) => (
        <StyledNameCol>
          <Avatar firstName={params.row.name} lastName={params.row.lastName} />
          {params.row.firstName} {params.row.lastName}
        </StyledNameCol>
      ),
    },
    {
      field: 'socialSecurity',
      headerName: 'PHIL HEALTH ID',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'birthdate',
      headerName: 'DATE OF BIRTH',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: (params) => <>{parseDate(params.row.birthdate)}</>,
    },
    {
      field: 'email',
      headerName: 'EMAIL ADDRESS',
      type: 'number',
      flex: 1,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'actions',
      headerName: 'ACTIONS',
      type: 'number',
      flex: 0.5,
      sortable: false,
      renderCell: () => (
        <>
          <MoreButton
            onClick={handleClick}
            aria-controls='simple-menu'
            aria-haspopup='true'
          >
            <img src={moreIcon} alt='more-icon' />
          </MoreButton>
          <StyledMenu
            id='simple-menu'
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
            transformOrigin={{ vertical: 'center', horizontal: 'center' }}
            onClose={handleClose}
            elevation={0}
          >
            <MenuItem
              onClick={() => history.push(`patients/profile/${currentId}`)}
            >
              {t('actionMenu.profile')}
            </MenuItem>
            {!hasPermissions(props.permissions, { admin: 'O' }) ? (
              <MenuItem
                onClick={() =>
                  history.push({
                    pathname: `patients/profile/${currentId}`,
                    form: true,
                  })
                }
              >
                {t('actionMenu.vaccination')}
              </MenuItem>
            ) : null}
          </StyledMenu>
        </>
      ),
    },
  ];

  return (
    <StyledDiv>
      <BreadCrumbs />
      <StyledBox>
        <StyledBtnsDiv>
          <PrimaryButton onClick={addNew} variant='contained' disableElevation style={{  marginRight: '15px'  }}>
            <img src={addIcon} alt='add-icon' />
            {t('buttons.addpatient')}
          </PrimaryButton>
          {/* <PrimaryButton onClick='#' variant='contained' disableElevation>
            <img src={qrcodeIcon} alt='scan-icon' style={{marginRight: '5px'}} /> {t('buttons.onboardingwithmyidapp')}
          </PrimaryButton> */}
        </StyledBtnsDiv>
        <StyledTextField
          placeholder={t('general.searchNameAndDate')}
          variant='outlined'
          value={input}
          onChange={(e) => setInput(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <img src={searchIcon} alt='search-icon' />
              </InputAdornment>
            ),
          }}
        />
      </StyledBox>
      <div style={{ height: 400, width: '100%' }}>
        <StyledDataGrid
          columns={columns}
          rows={matches ? matches : props.patients}
          autoHeight={true}
          disableColumnMenu
          hideFooterSelectedRowCount
          pageSize={10}
          sortingOrder={['desc', 'asc']}
          onRowClick={(param) => {
            setCurrentId(param.id);
          }}
          sortModel={[
            {
              field: 'firstName',
              sort: 'asc',
            },
          ]}
        />
      </div>
    </StyledDiv>
  );
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    patients: state.updates.patients,
    hoId: state.updates.hoId,
    mainContact: state.updates.mainContact,
    permissions: state.updates.permissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadPatients: (data) => {
      dispatch(loadPatients(data));
    },
  };
};

export default withTheme(
  connect(mapStateToProps, mapDispatchToProps)(PatientList)
);
