import React, { useEffect, useState } from 'react';
import {
  InputLabel,
  Divider,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Button,
  TextField,
  Container,
} from '@material-ui/core';
import { connect } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import { resetSuccessAndError, submitRequest, loadHealthOrg } from '../actions';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import validator from 'validator';
import { StyledTextField } from '@pgi/common/fe/styles/common-styles';

const StyledBg = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.theme.colors.bg};
`;
const StyledContainer = styled(Container)`
  &.MuiContainer-root {
    width: 50%;
    background-color: ${(props) => props.theme.colors.bg};
    @media (max-width: 768px) {
      width: 90%;
      margin-top: 25%;
    }
  }
  padding-bottom: 50px;
  margin-top: 2%;
`;

const StyledFormGroup = styled(FormGroup)`
  margin-bottom: 20px;
  & .MuiFormControl-root {
    width: 48%;
  }
  &.MuiFormGroup-row {
    justify-content: space-between;
  }
  & .MuiInputBase-root {
    width: 100%;
  }
`;
const AdminFormGroup = styled(FormGroup)`
  &.MuiFormGroup-row {
    width: 100%;
    justify-content: space-between;
  }
  &.MuiFormGroup-row > div {
    width: 48%;
  }
`;
const StyledTitle = styled.h5`
  font-family: ${(props) => props.theme.fonts.openSans};
  font-size: ${(props) => props.theme.fonts.size.headline6};
  font-weight: ${(props) => props.theme.fonts.weight.bold};
  text-align: left;
  color: ${(props) => props.theme.colors.blackVariant};
`;
const StyledSubtitle = styled.h4`
  margin-top: 30px;
  font-weight: ${(props) => props.theme.fonts.weight.semiBold};
  font-size: ${(props) => props.theme.fonts.size.subtitle1};
  font-family: ${(props) => props.theme.fonts.openSans};
  color: ${(props) => props.theme.colors.formSubTitle};
`;
const StyledButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
const StyledInputLabel = styled(InputLabel)`
  &.MuiFormLabel-root {
    font-family: ${(props) => props.theme.fonts.openSans};
    font-size: ${(props) => props.theme.fonts.size.overline};
    font-weight: ${(props) => props.theme.fonts.weight.semiBold};
    line-height: 16px;
    letter-spacing: ${(props) => props.theme.fonts.letter.overline};
    text-align: left;
    margin-bottom: 10px;
    margin-top: 30px;
  }
`;

const StyledDivider = styled(Divider)`
  &.MuiDivider-root {
    margin-top: 35px;
    margin-bottom: 35px;
  }
`;
const CheckboxFormGroup = styled(FormGroup)`
  margin-top: 30px;
  margin-bottom: 30px;
`;
const StyledCheckbox = styled(Checkbox)`
  &.MuiCheckbox-colorSecondary.Mui-checked {
    color: ${(props) => props.theme.checkbox.color};
  }
  &.MuiIconButton-colorSecondary:hover {
    background-color: ${(props) => props.theme.checkbox.bg};
  }
  &.MuiCheckbox-colorSecondary.Mui-checked:hover {
    background-color: ${(props) => props.theme.checkbox.bg};
  }
`;
const PrimaryButton = styled(Button)`
  &.MuiButton-contained {
    min-width: 100px;
    border-radius: ${(props) => props.theme.buttons.roundedPrimary.borderRadius};
    background-color: ${(props) => props.theme.buttons.primary.bg};
    color: ${(props) => props.theme.buttons.primary.color};
    font-family: ${(props) => props.theme.fonts.openSans};
    font-weight: ${(props) => props.theme.fonts.weight.semiBold};
    font-size: ${(props) => props.theme.fonts.size.subititle2};
    letter-spacing: ${(props) => props.theme.fonts.letter.button};
    margin-left: 10px;
  }
  &.MuiButton-contained:hover {
    background-color: ${(props) => props.theme.buttons.primary.bg};
    color: ${(props) => props.theme.buttons.primary.color};
  }
  &.MuiButton-contained.Mui-disabled {
    color: ${(props) => props.theme.buttons.disabled.color};
    background-color: ${(props) => props.theme.buttons.disabled.bg};
  }
`;
const SecondaryButton = styled(Button)`
  &.MuiButton-contained {
    color: ${(props) => props.theme.buttons.secondary.color};
    background-color: ${(props) => props.theme.buttons.secondary.bg};
    font-family: ${(props) => props.theme.fonts.openSans};
    font-weight: ${(props) => props.theme.fonts.weight.semiBold};
    font-size: ${(props) => props.theme.fonts.size.subititle2};
    letter-spacing: ${(props) => props.theme.fonts.letter.button};
  }
  &.MuiButton-contained:hover {
    color: ${(props) => props.theme.buttons.secondary.color};
    background-color: ${(props) => props.theme.buttons.secondary.bg};
  }
  &.MuiButton-contained.Mui-disabled {
    color: ${(props) => props.theme.buttons.disabled.color};
    background-color: ${(props) => props.theme.buttons.disabled.bg};
  }
`;
const StyledWarning = styled.div`
  color: ${(props) => props.theme.colors.error};
  font-size: 14px;
`;
const StyledFCLabel = styled(FormControlLabel)`
  & .MuiTypography-body1 {
    font-family: ${(props) => props.theme.fonts.openSans};
    font-size: ${(props) => props.theme.fonts.size.caption};
    font-weight: ${(props) => props.theme.fonts.weight.regular};
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: left;
  }
`;
function RequestForm(props) {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);
  const [disabledNext, setDisabledNext] = useState(true);
  const [disabledInput, setDisabledInput] = useState(false);
  const history = useHistory();
  const { pathname } = useLocation();
  const hoId = pathname.split('/').pop();
  const [errors, setErrors] = useState({});
  const [fields, setFields] = useState({
    firstName: '',
    lastName: '',
    streetAddress: '',
    postalCode: '',
    district: '',
    city: '',
    state: '',
    country: '',
    licenseID: '',
    email: '',
    phoneNumber: '',
  });

  useEffect(() => {
    if (
      fields.firstName &&
      fields.lastName &&
      fields.streetAddress &&
      fields.postalCode &&
      fields.district &&
      fields.city &&
      fields.state &&
      fields.country &&
      fields.licenseID &&
      fields.email &&
      fields.phoneNumber &&
      checked &&
      !Object.keys(errors).length
    ) {
      setDisabledNext(false);
    } else {
      setDisabledNext(true);
    }
  }, [fields, checked]);

  useEffect(() => {
    props.resetSuccessAndError();
  }, []);

  useEffect(() => {
    if (hoId === 'request') {
      props.loadHealthOrg({ hoId: 0 });
    } else {
      props.loadHealthOrg({ hoId });
    }
  }, []);
  useEffect(() => {
    if (props.success) {
      setFields({
        firstName: '',
        lastName: '',
        streetAddress: '',
        postalCode: '',
        district: '',
        city: '',
        state: '',
        country: '',
        licenseID: '',
        email: '',
        phoneNumber: '',
      });
      const tenant = JSON.parse(localStorage.getItem('health_org'));
      history.push(`/?tenant_id=${tenant.tenant_id}`);
    }
  }, [props.success]);
  const handleClick = () => {
    setDisabledInput(!disabledInput);
  };

  const handleSubmit = () => {
    setErrors({});
    props.submitRequest({ fields: { ...fields }, healthOrgId: hoId });
  };
  const errorMessages = {
    email: 'Invalid Email',
    postalCode: 'Invalid Postal Code, Allowed only numbers with min 3 & max 7 digits',
    firstName: 'Allowed only alphabets  of max 50 characters.',
    lastName: 'Allowed only alphabets  of max 50 characters.',
    country: 'The code is not according to ISO standards.',
    noSpaceInPhNumber: 'Spaces not allowed in phone number.',
    phoneNumber: 'Not a valid phone number.'
  };
  const validateInput = (e) => {
    const inputValue = e.target.value;
    const onlyText = /^[a-zA-Z\s]+$/;
    const noSpace = /^\S*$/;
    switch (e.target.name) {
      case 'licenseID': {
        const { licenseID, ...newErrorObject } = errors;
        setErrors(newErrorObject);
      }
        break;
      case 'email': {
        if (!validator.isEmail(inputValue)) {
          setErrors({ ...errors, [e.target.name]: errorMessages.email });
          setDisabledNext(true);
          return;
        }
        const { email, ...newErrorObject } = errors;
        setErrors(newErrorObject);
      }
        break;
      case 'postalCode':
        if (!validator.isPostalCode(inputValue, 'any')) {
          setErrors({ ...errors, postalCode: errorMessages.postalCode });
          setDisabledNext(true);
          return;
        } else {
          const { postalCode, ...newErrorObject } = errors;
          setErrors(newErrorObject);
        }
        break;
      case 'firstName': {
        if (!onlyText.test(inputValue) || inputValue.length > 50) {
          setErrors({ ...errors, firstName: errorMessages.firstName });
          setDisabledNext(true);
          return;
        }
        const { firstName, ...newErrorObject } = errors;
        setErrors(newErrorObject);
      }
        break;
      case 'lastName': {
        if (!onlyText.test(inputValue) || inputValue.length > 50) {
          setErrors({ ...errors, lastName: errorMessages.lastName });
          setDisabledNext(true);
          return;
        }

        const { lastName, ...newErrorObject } = errors;
        setErrors(newErrorObject);
      }
        break;
      case 'country': {
        if (!validator.isISO31661Alpha2(inputValue) && !validator.isISO31661Alpha3(inputValue)) {
          setErrors({ ...errors, country: errorMessages.country });
          setDisabledNext(true);
          return;
        }
        const { country, ...newErrorObject } = errors;
        setErrors(newErrorObject);
      }
        break;
      case 'phoneNumber': {
        if (!noSpace.test(inputValue)) {
          setErrors({ ...errors, phoneNumber: errorMessages.noSpaceInPhNumber });
          setDisabledNext(true);
          return;
        }
        if (!validator.isMobilePhone(inputValue)) {
          setErrors({ ...errors, phoneNumber: errorMessages.phoneNumber });
          setDisabledNext(true);
          return;
        }
        const { phoneNumber, ...newErrorObject } = errors;
        setErrors(newErrorObject);
      }
        break;
    }
  };
  const handleInputChange = (e) => {
    validateInput(e);
    setFields({ ...fields, [e.target.name]: e.target.value });
  };

  /**
   * Handle serverside errors
   */
  useEffect(() => {
    if (props.validationError && Object.keys(props.validationError).length) {
      const errorObj = {};
      for (const fieldName in props.validationError[0]) {
        errorObj[fieldName] = props.validationError[0][fieldName];
      }
      setErrors({ ...errors, ...errorObj });
      return setDisabledInput(true);
    }
  }, [props.validationError]);

  return (
    <StyledBg>
      <StyledContainer>
        <StyledTitle>
          {t('requestform.title1')} {props.healthorg} {t('requestform.title2')}
        </StyledTitle>
        <StyledWarning>{t('general.mandatory')}</StyledWarning>
        <FormGroup>
          <StyledInputLabel>{t('requestform.ho.label')}</StyledInputLabel>
          <StyledTextField
            name='ho'
            value={props.healthorg || ''}
            disabled={true}
            variant='outlined'
          />
        </FormGroup>
        <StyledSubtitle>{t('requestform.subtitle.identity')}:</StyledSubtitle>
        <FormGroup>
          <StyledInputLabel>
            {t('requestform.licenseID.label')}
          </StyledInputLabel>
          <StyledTextField
            name='licenseID'
            value={fields.licenseID}
            onChange={handleInputChange}
            disabled={disabledInput}
            variant='filled'
            placeholder={t('requestform.licenseID.placeholder')}
            error={errors?.licenseID ? errors?.licenseID : ''}
            helperText={errors?.licenseID ? errors?.licenseID : ''}
            autoComplete='off'
          />
        </FormGroup>
        <AdminFormGroup row>
          <FormGroup>
            <StyledInputLabel>{t('commonfields.firstName')}</StyledInputLabel>
            <StyledTextField
              name='firstName'
              value={fields.firstName}
              onChange={handleInputChange}
              disabled={disabledInput}
              variant='filled'
              placeholder={t('requestform.firstName')}
              error={errors?.firstName ? errors?.firstName : ''}
              helperText={errors?.firstName ? errors?.firstName : ''}
              autoComplete='off'
            />
          </FormGroup>
          <FormGroup>
            <StyledInputLabel>{t('commonfields.lastName')}</StyledInputLabel>
            <StyledTextField
              name='lastName'
              value={fields.lastName}
              onChange={handleInputChange}
              disabled={disabledInput}
              variant='filled'
              placeholder={t('requestform.lastName')}
              error={errors?.lastName ? errors?.lastName : ''}
              helperText={errors?.lastName ? errors?.lastName : ''}
              autoComplete='off'
            />
          </FormGroup>
        </AdminFormGroup>
        <StyledInputLabel>{t('requestform.subtitle.address')}</StyledInputLabel>
        <StyledFormGroup row>
          <StyledTextField
            name='streetAddress'
            placeholder={t('commonfields.streetAddress')}
            value={fields.streetAddress}
            onChange={handleInputChange}
            disabled={disabledInput}
            variant='filled'
            autoComplete='off'
          />
          <StyledTextField
            name='postalCode'
            placeholder={t('commonfields.postalCode')}
            value={fields.postalCode}
            onChange={handleInputChange}
            disabled={disabledInput}
            variant='filled'
            error={errors?.postalCode ? errors?.postalCode : ''}
            helperText={errors?.postalCode ? errors?.postalCode : ''}
            autoComplete='off'
          />
        </StyledFormGroup>
        <StyledFormGroup row>
          <StyledTextField
            name='district'
            placeholder={t('commonfields.district')}
            value={fields.district}
            onChange={handleInputChange}
            disabled={disabledInput}
            variant='filled'
            autoComplete='off'
          />
          <StyledTextField
            name='city'
            placeholder={t('commonfields.city')}
            value={fields.city}
            onChange={handleInputChange}
            disabled={disabledInput}
            variant='filled'
            autoComplete='off'
          />
        </StyledFormGroup>
        <StyledFormGroup row>
          <StyledTextField
            name='state'
            placeholder={t('commonfields.state')}
            value={fields.state}
            onChange={handleInputChange}
            disabled={disabledInput}
            variant='filled'
            autoComplete='off'
          />
          <StyledTextField
            name='country'
            placeholder={t('commonfields.countryId')}
            value={fields.country}
            onChange={handleInputChange}
            disabled={disabledInput}
            variant='filled'
            error={errors?.country ? errors?.country : ''}
            helperText={errors?.country ? errors?.country : ''}
            autoComplete='off'
          />
        </StyledFormGroup>
        <AdminFormGroup row>
          <FormGroup>
            <StyledInputLabel>{t('commonfields.email')}</StyledInputLabel>
            <StyledTextField
              name='email'
              value={fields.email}
              onChange={handleInputChange}
              disabled={disabledInput}
              variant='filled'
              placeholder={t('requestform.email')}
              error={errors?.email ? errors?.email : ''}
              helperText={errors?.email ? errors?.email : ''}
              autoComplete='off'
            />
          </FormGroup>
          <FormGroup>
            <StyledInputLabel>{t('commonfields.phone')}</StyledInputLabel>
            <StyledTextField
              name='phoneNumber'
              value={fields.phoneNumber}
              onChange={handleInputChange}
              disabled={disabledInput}
              variant='filled'
              placeholder={t('requestform.phoneNr')}
              error={errors?.phoneNumber ? errors?.phoneNumber : ''}
              helperText={errors?.phoneNumber ? errors?.phoneNumber : ''}
              autoComplete='off'
            />
          </FormGroup>
        </AdminFormGroup>
        <CheckboxFormGroup>
          <StyledFCLabel
            control={
              <StyledCheckbox onChange={(e) => setChecked(e.target.checked)} />
            }
            label={t('requestform.signature')}
            disabled={disabledInput}
          />
        </CheckboxFormGroup>
        <StyledDivider />
        <StyledButtonGroup>
          {disabledInput ? (
            <>
              <SecondaryButton
                onClick={handleClick}
                variant='contained'
                disableElevation
              >
                {t('buttons.editInfo')}
              </SecondaryButton>
              <PrimaryButton
                onClick={handleSubmit}
                variant='contained'
                disableElevation
              >
                {t('buttons.sendInfo')}
              </PrimaryButton>
            </>
          ) : (
            <PrimaryButton
              disabled={disabledNext}
              onClick={handleClick}
              variant='contained'
              disableElevation
            >
              {t('buttons.next')}
            </PrimaryButton>
          )}
        </StyledButtonGroup>
      </StyledContainer>
    </StyledBg>
  );
}
const mapStateToProps = (state) => {
  return {
    error: state.updates.error,
    success: state.updates.success,
    validationError: state.updates.validationError,
    healthorg: state.updates.healthorg,
    tenant: state.updates.tenant
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    submitRequest: (data) => {
      dispatch(submitRequest(data));
    },
    resetSuccessAndError: (data) => {
      dispatch(resetSuccessAndError(data));
    },
    loadHealthOrg: (data) => {
      dispatch(loadHealthOrg(data));
    },
  };
};

export default withTheme(
  connect(mapStateToProps, mapDispatchToProps)(RequestForm)
);
