import { format, parse } from 'date-format-parse';

export const parseDate = (item) => {
  const d = parse(item, 'YYYY-MM-DDTHH:mm:ss');

  return format(d, 'DD/MM/YYYY');
};

export const parseDateFormat = (item) => {
  const d = parse(item, 'YYYY-MM-DDTHH:mm:ss');

  return format(d, 'YYYY-MM-DD');
};

export const parseDateAndTime = (item) => {
  const d = parse(item, 'YYYY-MM-DDTHH:mm:ss');

  return format(d, 'DD/MM/YYYY hh:mm:ss');
};

export const parseTime = (item) => {
  const d = parse(item, 'YYYY-MM-DDTHH:mm:ss');

  return format(d, 'hh:mm');
};

export const getTime = (item) => {
  const d = new Date(item);

  return d.getHours() + ':' + d.getMinutes();
};

export const hasPermissions = (ownedPermissions, toCheck) => {
  for (const resource in toCheck) { // Go through all resource permissions to check
    if (ownedPermissions[resource]) {
      for (const permission of toCheck[resource]) {
        if (!ownedPermissions[resource].includes(permission)) {
          return false;
        }
      }
    } else { // Does not have this resource...
      return false;
    }
  }

  return true;
};