import image from '../components/assets/grey-waves.svg';
import expandimage from '../components/assets/expand.svg';

export const theme2 = {
  colors: {
    bg: '#FFFFFF',
    nobg: 'rgba(209, 209, 214, 0.23)',
    onSurface: '#000000',
    paragraph: 'rgba(0, 0, 0, 0.6)',
    primary: '#0089D0',
    success: '#70c500',
    formSubTitle: '#2e2e30',
    error: '#FF004B',
    transparent: 'transparent',
    breadcrumbs: 'rgba(0, 0, 0, 0.54)',
    disabled: 'rgba(0, 0, 0, 0.38)',
    blackVariant: '#000000de',
    apptitle: '#4a4a4a',
  },
  fonts: {
    openSans: '"Open Sans", sans-serif',
    montserrat: '"Montserrat", sans-serif',
    weight: {
      subtitle2: '600',
      subtitle1: '600',
      headline6: '600',
      semiBold: '600',
      regular: '400',
      bold: '700',
      extraBold: '800',
    },
    size: {
      caption: '12px',
      subtitle2: '14px',
      subtitle1: '16px',
      subtitle3: '18px',
      headline6: '20px',
      headline5: '24px',
      overline: '10px',
      apptitle: '28px',
      paragraph: '0.8em',
    },
    letter: {
      button: '1.25px',
      overline: '1.5px',
    },
  },
  buttons: {
    primary: {
      color: '#FFFFFF',
      bg: '#009BEB',
      radius: '12px',
    },
    secondary: {
      color: '#009BEB',
      bg: 'transparent',
      radius: '12px',
      padding: '12px',
    },
    disabled: {
      color: 'rgba(0, 0, 0, 0.38)',
      bg: 'rgba(209, 209, 214, 0.23)',
      radius: '12px',
      padding: '12px',
    },
    roundedPrimary: {
      background: '#0089d0',
      color: 'white',
      padding: '12px',
      borderRadius: '100px',
      height: '45px',
    },
    roundedSecondary: {
      background: '#71c400',
      color: 'white',
      padding: '12px',
      borderRadius: '100px',
      height: '45px',
    },
    outlined: {
      bg: 'white',
      color: '#0089D0',
    },
    access: {
      background: '#ffffff',
      color: '#333333',
    }
  },
  menuItem: {
    height: '50px',
    display: 'flex',
    justify: 'start',
    pl: '10px',
    mt: '5px',
    mb: '5px',
    radius: '4px',
    bg: '#E3F2FD',
    color: '#009BEB',
  },
  avatarIcon: {
    height: '40px',
    width: '40px',
    radius: '50%',
  },
  accessIcon: {
    height: '50px',
  },
  card: {
    padding: '35px 12px',
    display: 'flex',
    direction: 'column',
    justify: 'space-between',
    align: 'center',
    width: '300px',
    height: '390px',
    box: 'border-box',
    mr: '10%',
    radius: '20px',
  },
  checkbox: {
    color: '#009BEB',
    bg: 'rgba(1, 135, 134, 0.08)',
  },
  root: {
    display: 'flex',
    'flex-direction': 'column',
  },
  background: 'white',
  layout: {
    header: {
      bgImage: `url(${image})`,
      bgRepeat: 'no-repeat',
      bgColor: 'transparent',
      bgSize: '60% auto',
      height: '25%',
      boxShadow: 'none',
      paddingLeft: '8%',
      logo: {
        marginTop: '1.4%',
        height: '73px',
        // width: '18%',
      },
      title: {
        marginTop: '2%',
        float: 'right',
        textShadow: '1px 0px 1px #22acf0',
        color: '#4a4a4a',
        marginRight: '4%',
        fontFamily: 'OpenSans-Bold',
        letterSpacing: '-0.98px',
      },
    },
    sidebar: {
      width: '18%',
      logo: {
        width: '70%',
        height: '80%'
      },
      title: {
        fontSize: '25px',
        fontWeight: '600',
      },
    },
  },
  bodyText: {
    color: '#00000061',
    fontSize: '0.76em',
  },
  sidebarmenu: {
    menulist: {
      width: '15%',
      height: '100%',
    },
  },
  buttonContainer: {
    marginTop: '20%',
  },
  table: {
    header: {
      titleText: {
        color: '#009beb',
      },
      downArrow: {
        bgImage: `url(${expandimage})`,
      },
    },
  },
};
