import { connect } from 'react-redux';
import {
  storePermissions,
  storeUser,
  storeToken,
  resetSuccessAndError,
  storeMainContact,
} from '../actions';
import Header from './header';
import SnackBar from './snackbar';
import LogIn from '@pgi/common/fe/components/login';
import LandingLayout from './layout/landinglayout';

function Login(props) {
  const translations = {
    styledTitle: 'subtitle.mos',
    loginText: 'general.login',
  };
  const WEBSOCKET = '/api/login/mos';
  const GOTOURL = '/patients';
  const properties = { ...props, translations, WEBSOCKET, GOTOURL };

  return (
    <>
      <LandingLayout {...props}>
        <LogIn {...properties} />
      </LandingLayout>
    </>
  );
}
const mapStateToProps = (state /*, ownProps*/) => {
  return {
    error: state.updates.error,
    success: state.updates.success,
    hoId: state.updates.hoId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    storePermissions: (data) => {
      dispatch(storePermissions(data));
    },
    storeMainContact: (data) => {
      dispatch(storeMainContact(data));
    },
    storeUser: (data) => {
      dispatch(storeUser(data));
    },
    storeToken: (data) => {
      dispatch(storeToken(data));
    },
    resetSuccessAndError: (data) => {
      dispatch(resetSuccessAndError(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
