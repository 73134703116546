import React, { Component } from 'react';
import { connect } from 'react-redux';
import PatientForm from './patientform';
import VaccinationList from './vaccinationlist';
import PatientDetails from './patientdetails';
import { loadPatient } from '../actions';
import { useParams, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Divider, Paper } from '@material-ui/core/';
import styled from 'styled-components';
import BreadCrumbs from './breadcrumbs';
import { RightContentDiv } from '@pgi/common/fe/styles/common-styles';

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;
const StyledPaper = styled(Paper)`
  padding: 30px 0px;
  margin-right: 50px;
  width: 45%;
  min-width: 300px;
  margin-bottom: 50px;
`;
const StyledDivider = styled(Divider)`
  &.MuiDivider-root {
    margin-bottom: 30px;
  }
`;
const StyledColumn = styled.div`
  margin-left: 5%;
  display: flex;
  flex-direction: column;
`;

function PatientProfile(props) {
  const { patientId } = useParams();
  const [edit, setEdit] = useState(false);
  const location = useLocation();
  const [openForm, setOpenForm] = useState(false);

  useEffect(() => {
    props.loadPatient({ patientId });
  }, []);

  useEffect(() => {
    if (location.form === true) {
      setOpenForm(true);
    } else {
      setOpenForm(false);
    }
  });

  const handleClick = () => {
    setEdit(!edit);
  };

  return (
    <RightContentDiv>
      <BreadCrumbs />
      <StyledDiv>
        <StyledPaper elevation={0}>
          {edit ? (
            <PatientForm patient={props.patient} edit={handleClick} />
          ) : (
            <PatientDetails patient={props.patient} handleClick={handleClick} />
          )}
        </StyledPaper>
        <StyledDivider orientation="vertical" flexItem />
        <VaccinationList patientDetails={props.patient} openForm={openForm} />
      </StyledDiv>
    </RightContentDiv>
  );
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    patient: state.updates.patient,
    hoId: state.updates.hoId,
    mainContact: state.updates.mainContact,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadPatient: (data) => {
      dispatch(loadPatient(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientProfile);
