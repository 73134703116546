import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Sidebarview from '../sidebarview';
import Avatarbar from '../avatarbar';
import SnackBar from '../snackbar';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        background: '#d1d1d63b',
        height: 'auto',
        minHeight: '100%'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        minHeight: '100vh'
    },
    gridAlignment: {
        paddingLeft: '20px',
        paddingTop: '20px'
    },
    avtarbarGrid: {
        background: '#ffffff',
        paddingLeft: '20px'
    },
    outerDiv: {
        // boxSizing: 'content-box'
    }
}));

const InnerLayout = (props) => {
    const history = useHistory();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const classes = useStyles();

    const handleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    history.listen(() => {
        setIsMenuOpen(false);
    });
    return (
        <React.Fragment>
            <div className={classes.root}>
                <Grid container maxWidth="xl" >
                    <Grid item xs={2}>
                        <Sidebarview isMenuOpen={isMenuOpen} style={{ boxSizing: 'content-box' }} />
                    </Grid>
                    <Grid item xs={10}>
                        <Grid container style={{ padding: '10px 0 0 10px' }} className={classes.outerDiv}>
                            <Grid container item xs={12} lg={12} spacing={3} className={classes.avtarbarGrid}>
                                <Avatarbar handleMenu={handleMenu} />
                            </Grid>
                            <Grid container item xs={12} spacing={3} style={{ padding: '16px' }} className={classes.gridAlignment}></Grid>
                            <Grid container item xs={12} spacing={3} className={classes.gridAlignment}>
                                <SnackBar />
                                {props.children}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </React.Fragment>
    );
};

export default InnerLayout;