import React, { Component } from 'react';
import { useEffect, useState } from 'react';
import {
  FormControl,
  FormGroup,
  Button,
  TextField,
  Paper,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withTheme } from 'styled-components';
import { loadVaccines, submitVaccination } from '../actions';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { StyledTextField } from '@pgi/common/fe/styles/common-styles';
import validator from 'validator';

const StyledPaper = styled(Paper)`
  &.MuiPaper-rounded {
    border-radius: 12px;
    border: 1px solid #009beb;
  }
  &.MuiPaper-root {
    background-color: rgba(0, 155, 235, 0.08);
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 24px 14px;
    box-sizing: border-box;
    margin-bottom: 50px;
  }
`;
const AdminFormGroup = styled(FormGroup)`
  &.MuiFormGroup-row {
    width: 100%;
    justify-content: space-between;
  }
  &.MuiFormGroup-row > div {
    width: 48%;
  }
`;
const StyledInputLabel = styled(InputLabel)`
  &.MuiFormLabel-root {
    font-size: ${(props) => props.theme.fonts.size.overline};
    font-weight: ${(props) => props.theme.fonts.weight.semiBold};
    line-height: 16px;
    letter-spacing: 1.5px;
    text-align: left;
    margin-bottom: 10px;
    margin-top: 30px;
  }
`;

const StyledSelect = styled(Select)`
  background-color: ${(props) => props.theme.colors.bg};
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  &.MuiFilledInput-root {
    background-color: ${(props) => props.theme.colors.bg};
    transition: unset;
  }
  &.MuiFilledInput-underline:before {
    border: 0;
  }
  &.MuiFilledInput-underline:after {
    border-color: ${(props) => props.theme.colors.onSurface};
  }
  &.MuiFilledInput-root:hover {
    background-color: ${(props) => props.theme.colors.bg};
  }
  &.MuiFilledInput-root.Mui-focused {
    background-color: ${(props) => props.theme.colors.bg};
  }
  &.MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.colors.onSurface};
  }
  &.MuiInputBase-input.Mui-disabled {
    background-color: ${(props) => props.theme.colors.bg};
    opacity: unset;
    border-radius: 4px;
  }
  &.MuiFilledInput-root.Mui-disabled {
    background-color: ${(props) => props.theme.colors.bg};
    color: ${(props) => props.theme.colors.disabled};
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
`;
const StyledButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin-top: 30px;
`;
const PrimaryButton = styled(Button)`
  &.MuiButton-contained {
    height: 36px;
    width: 105px;
    border-radius: 12px;
    background-color: ${(props) => props.theme.buttons.primary.bg};
    color: ${(props) => props.theme.buttons.primary.color};
    margin-left: 10px;
  }
  &.MuiButton-contained:hover {
    background-color: ${(props) => props.theme.buttons.primary.bg};
    color: ${(props) => props.theme.buttons.primary.color};
  }
  &.MuiButton-contained.Mui-disabled {
    color: ${(props) => props.theme.colors.disabled};
    background-color: #d1d1d63b;
  }
`;
const SecondaryButton = styled(Button)`
  &.MuiButton-contained {
    color: ${(props) => props.theme.buttons.secondary.color};
    background-color: ${(props) => props.theme.colors.transparent};
    box-shadow: unset;
  }
  &.MuiButton-contained:hover {
    color: ${(props) => props.theme.buttons.secondary.color};
    background-color: ${(props) => props.theme.colors.transparent};
    box-shadow: unset;
  }
  &.MuiButton-contained.Mui-disabled {
    color: ${(props) => props.theme.colors.disabled};
    background-color: ${(props) => props.theme.colors.bg};
  }
`;
const StyledFC = styled(FormControl)`
  & .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.colors.onSurface};
  }
`;
const StyledTitle = styled.h3`
  margin-top: 10px;
  font-size: 18px;
  font-weight: ${(props) => props.theme.fonts.weight.regular};
  line-height: 24px;
  letter-spacing: 0.15px;
  align-self: flex-start;
  color: ${(props) => props.theme.colors.primary};
`;
const StyledWarning = styled.div`
  color: ${(props) => props.theme.colors.error};
  font-size: 14px;
  font-family: ${(props) => props.theme.fonts.openSans};
`;


function VaccinationForm(props) {
  const { t } = useTranslation();
  const hoId = props.hoId;
  const [vaccineTypes, setVaccineTypes] = useState();
  const [batches, setBatches] = useState();
  const { patientId } = useParams();
  const [errors, setErrors] = useState({});
  const [fields, setFields] = useState({
    type: '',
    batch: '',
    doseNr: '',
    vaccinationDate: '',
    vaccinationTime: '',
    nextVaccinationDate: '',
    nextVaccinationTime: '',
    country: '',
    pcrCenterName: '',
    pcrDate: '',
  });

  const [disabledSubmit, setDisabledSubmit] = useState(true);

  useEffect(() => {
    props.loadVaccines();
  }, []);

  const doses = ['1 out of 2', '2 out of 2'];

  useEffect(() => {
    if (
      fields.type &&
      fields.batch &&
      fields.doseNr &&
      fields.vaccinationDate &&
      fields.vaccinationTime &&
      fields.nextVaccinationDate &&
      fields.nextVaccinationTime &&
      fields.pcrCenterName &&
      fields.country &&
      fields.pcrDate &&
      !Object.keys(errors).length
    ) {
      setDisabledSubmit(false);
    } else {
      setDisabledSubmit(true);
    }
  }, [fields]);

  useEffect(() => {
    const types = [];
    props.vaccines &&
      props.vaccines.map((vacc) => {
        if (types.includes(vacc.profilaxis)) {
          return types;
        } else {
          types.push(vacc.profilaxis);
        }
        setVaccineTypes([...types]);

        return null;
      });
  }, [props.vaccines]);

  useEffect(() => {
    const batches = [];
    props.vaccines &&
      props.vaccines.map((vacc) => {
        if (vacc.profilaxis === fields.type) {
          batches.push(vacc.batch);
        }
        setBatches([...batches]);

        return null;
      });
  }, [fields.type]);

  const handleSubmit = () => {
    let vaccineId;
    props.vaccines.map((vaccine) => {
      if (
        vaccine.profilaxis === fields.type &&
        vaccine.batch === fields.batch
      ) {
        vaccineId = vaccine.id;

        return vaccineId;
      }

      return null;
    });
    props.submitVaccination({ ...fields, hoId, patientId, vaccineId });
    props.handleCancel();
  };

  const validateInput = (e) => {
    switch (e.target.name) {
      case 'vaccinationDate': {
        if (!validator.isDate(e.target.value)) {
          setErrors({ ...errors, [e.target.name]: 'Invalid Date' });
          return setDisabledSubmit(true);
        }
        if (validator.isAfter(e.target.value, fields.nextVaccinationDate)) {
          setErrors({ ...errors, vaccinationDate: 'Date must be before the next date of vaccination.' });
          return setDisabledSubmit(true);
        }
        const { vaccinationDate, ...newErrorObject } = errors;
        setErrors(newErrorObject);
      }
        break;
      case 'nextVaccinationDate': {
        if (!validator.isDate(e.target.value)) {
          setErrors({ ...errors, nextVaccinationDate: 'Invalid Date' });
          return setDisabledSubmit(true);
        }
        if (validator.isBefore(e.target.value, fields.vaccinationDate)) {
          setErrors({ ...errors, nextVaccinationDate: 'Next date must be after the date of vaccination.' });
          return setDisabledSubmit(true);
        }
        const { nextVaccinationDate, ...newErrorObject } = errors;
        setErrors(newErrorObject);
      }
        break;
      case 'pcrDate': {
        if (!validator.isDate(e.target.value)) {
          setErrors({ ...errors, pcrDate: 'Invalid Date' });
          return setDisabledSubmit(true);
        }
        const { nextVaccinationDate, ...newErrorObject } = errors;
        setErrors(newErrorObject);
      }
        break;
    }
  };

  const handleInputChange = (e) => {
    validateInput(e);
    setFields({ ...fields, [e.target.name]: e.target.value });
  };

  return (
    <>
      <StyledPaper elevation={0}>
        <StyledTitle>{t('vaccinationform.title')}</StyledTitle>
        <StyledWarning>{t('general.mandatory')}</StyledWarning>
        <FormGroup>
          <StyledInputLabel id='vaccine-type-label'>
            {t('vaccinationform.type')}
          </StyledInputLabel>
          <StyledFC variant='filled'>
            <InputLabel id='vaccine-type-label'>
              {t('commonfields.select')}
            </InputLabel>
            <StyledSelect
              labelid='vaccine-type-label'
              name='type'
              value={fields.type}
              onChange={handleInputChange}
            >
              {vaccineTypes &&
                vaccineTypes.map((type) => {
                  return (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  );
                })}
            </StyledSelect>
          </StyledFC>
          <AdminFormGroup row>
            <FormGroup>
              <StyledInputLabel id='vaccine-batch-label'>
                {t('vaccinationform.batch')}
              </StyledInputLabel>
              <StyledFC variant='filled'>
                <InputLabel id='vaccine-batch-label'>
                  {t('commonfields.select')}
                </InputLabel>
                <StyledSelect
                  labelid='vaccine-batch-label'
                  name='batch'
                  value={fields.batch}
                  onChange={handleInputChange}
                  variant='filled'
                  disabled={fields.type ? false : true}
                >
                  {batches &&
                    batches.map((batch) => {
                      return (
                        <MenuItem key={batch} value={batch}>
                          {batch}
                        </MenuItem>
                      );
                    })}
                </StyledSelect>
              </StyledFC>
            </FormGroup>
            <FormGroup className='right-small-field'>
              <StyledInputLabel id='dose-number'>
                {t('vaccinationform.doseNr')}
              </StyledInputLabel>
              <StyledFC variant='filled'>
                <InputLabel id='dose-number'>
                  {t('commonfields.select')}
                </InputLabel>
                <StyledSelect
                  labelid='vaccine-doses-label'
                  name='doseNr'
                  label='selectoption'
                  value={fields.doseNr}
                  onChange={handleInputChange}
                  variant='filled'
                //disabled={fields.type ? false : true}
                >
                  {doses.map((valuee, index) => {
                    return (
                      <MenuItem key={valuee} value={index + 1}>
                        {valuee}
                      </MenuItem>
                    );
                  })}
                </StyledSelect>
              </StyledFC>
              {/* <FormControl>
                <StyledTextField
                  labelid='dose-number'
                  name='doseNr'
                  value={fields.doseNr}
                  onChange={handleInputChange}
                  variant='filled'
                  type='number'
                  placeholder={t('vaccinationform.dosePlaceholder')}
                />
              </FormControl> */}
            </FormGroup>
          </AdminFormGroup>
          <AdminFormGroup row>
            <FormGroup>
              <StyledInputLabel>{t('vaccinationform.date')}</StyledInputLabel>
              <StyledTextField
                name='vaccinationDate'
                value={fields.vaccinationDate}
                onChange={handleInputChange}
                type='date'
                InputLabelProps={{
                  shrink: true,
                }}
                variant='filled'
                className='date-wrapper-class'
                error={errors?.vaccinationDate ? errors?.vaccinationDate : ''}
                helperText={errors?.vaccinationDate ? errors?.vaccinationDate : ''}
              />
            </FormGroup>
            <FormGroup>
              <StyledInputLabel>
                {t('vaccinationform.nextDate')}
              </StyledInputLabel>
              <StyledTextField
                name='nextVaccinationDate'
                value={fields.nextVaccinationDate}
                onChange={handleInputChange}
                type='date'
                InputLabelProps={{
                  shrink: true,
                }}
                variant='filled'
                className='date-wrapper-class'
                error={errors?.nextVaccinationDate ? errors?.nextVaccinationDate : ''}
                helperText={errors?.nextVaccinationDate ? errors?.nextVaccinationDate : ''}
              />
            </FormGroup>
          </AdminFormGroup>
          <AdminFormGroup row>
            <FormGroup className='right-small-field'>
              <StyledInputLabel>{t('vaccinationform.time')}</StyledInputLabel>
              <StyledTextField
                name='vaccinationTime'
                value={fields.vaccinationTime}
                onChange={handleInputChange}
                type='time'
                InputLabelProps={{
                  shrink: true,
                }}
                variant='filled'
              />
            </FormGroup>
            <FormGroup>
              <StyledInputLabel>
                {t('vaccinationform.nextTime')}
              </StyledInputLabel>
              <StyledTextField
                name='nextVaccinationTime'
                value={fields.nextVaccinationTime}
                onChange={handleInputChange}
                type='time'
                InputLabelProps={{
                  shrink: true,
                }}
                variant='filled'
              />
            </FormGroup>
          </AdminFormGroup>
          <FormGroup>
            <StyledInputLabel>{t('vaccinationform.country')}</StyledInputLabel>
            <StyledTextField
              name='country'
              value={fields.country}
              onChange={handleInputChange}
              type='text'
              variant='filled'
              placeholder={t('vaccinationform.countryPlaceholder')}
            />
          </FormGroup>
          <AdminFormGroup row>
            <FormGroup>
              <StyledInputLabel>
                {t('vaccinationform.pcrDate')}
              </StyledInputLabel>
              <StyledTextField
                name='pcrDate'
                value={fields.pcrDate}
                onChange={handleInputChange}
                type='date'
                InputLabelProps={{
                  shrink: true,
                }}
                variant='filled'
                className='date-wrapper-class'
              />
            </FormGroup>
            <FormGroup>
              <StyledInputLabel>
                {t('vaccinationform.pcrCenterName')}
              </StyledInputLabel>
              <StyledTextField
                name='pcrCenterName'
                value={fields.pcrCenterName}
                onChange={handleInputChange}
                type='text'
                variant='filled'
                placeholder={t('vaccinationform.pcrPlaceholder')}
                className='date-wrapper-class'
              />
            </FormGroup>
          </AdminFormGroup>
        </FormGroup>
        <StyledButtonGroup>
          <SecondaryButton
            onClick={props.handleCancel}
            disableElevation
            variant='contained'
            color='primary'
          >
            {t('buttons.cancel')}
          </SecondaryButton>
          <PrimaryButton
            disabled={disabledSubmit}
            disableElevation
            onClick={handleSubmit}
            variant='contained'
            color='primary'
          >
            {t('buttons.submit')}
          </PrimaryButton>
        </StyledButtonGroup>
      </StyledPaper>
    </>
  );
}
const mapStateToProps = (state /*, ownProps*/) => {
  return {
    vaccines: state.updates.vaccines,
    hoId: state.updates.hoId,
    mainContact: state.updates.mainContact,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadVaccines: (data) => {
      dispatch(loadVaccines(data));
    },
    submitVaccination: (data) => {
      dispatch(submitVaccination(data));
    },
  };
};

export default withTheme(
  connect(mapStateToProps, mapDispatchToProps)(VaccinationForm)
);
