import React from 'react';
import TabCard from '@pgi/common/fe/components/tabcard';
import { useTranslation } from 'react-i18next';
import requestIcon from './assets/app-request-icon.svg';
import issuingAccess from './assets/issuing-access.svg';
import mosAccess from '@pgi/common/fe/components/assets/request-icon.svg';
import {
    RequestButton,
    NewAccessButton,
    StyledTabAccess,
} from '@pgi/common/fe/styles/styles';
import { Divider, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { connect } from 'react-redux';

const StyledSubtitle = styled.div`
  text-align: center;
  font-size: ${(props) => props.theme.fonts.size.headline6};
  font-weight: ${(props) => props.theme.fonts.weight.bold};
  font-family: ${(props) => props.theme.fonts.openSans};
  text-align: center;
  //padding: 0 14%;
`;

const StyledTabText = styled.div`
    text-align: center;
    font-family: ${(props) => props.theme.fonts.openSans};
    font-size: ${(props) => props.theme.bodyText.fontSize};
    font-weight: ${(props) => props.theme.fonts.weight.regular};
    color: ${(props) => props.theme.bodyText.color};
    margin-bottom: 24px;
    margin-top: 24px;
`;
const StyledTabLine = styled.p`
    text-align: center;
    font-family: ${(props) => props.theme.fonts.openSans};
    color: ${(props) => props.theme.colors.blackVariant};
    letter-spacing: 0.4px;
    margin-bottom: 24px;
    margin-top: 24px;
    padding: 0 4%;
    text-shadow: 0px 0px 0px black;
`;


const Landing = (props) => {
    const { t } = useTranslation();
    const requestTrans = {
        title: 'subtitle.access',
        btnText: 'request.button',
    };
    const issuingTrans = {
        title: 'subtitle.issuing_access',
        btnText: 'buttons.access',
    };
    const mosTrans = {
        title: 'avatarbar.title',
        btnText: 'buttons.access',
    };
    const requestProps = {
        ...props,
        translations: requestTrans,
        appUri: `/request/${props.tenantinfo.tenant_id}`,
        appIcon: requestIcon,
        btntype: RequestButton,
    };
    const mosProps = {
        ...props,
        translations: mosTrans,
        appUri: `/access?tenant_id=${props.tenantinfo.tenant_id}`,
        appIcon: mosAccess,
        btntype: NewAccessButton,
        background: '#0089d0',
        color: '#ffffff'
    };
    const issuingProps = {
        ...props,
        translations: issuingTrans,
        isExternal: true,
        appUri: `${process.env.REACT_APP_ISSUING_DOMAIN}/vcissuingsystem?tenant_id=${props.tenantinfo.tenant_id}&backurl=${window.location.origin}`,
        appIcon: issuingAccess,
        btntype: NewAccessButton,
        background: '#71c400',
        color: '#ffffff',
        buttonBg: '#ffffff'
    };

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
        },
        marginAuto: {
            margin: '0 auto'
        },
        textAboveTab: {
            maxWidth: '330px',
            margin: '0 auto'
        }
    }));
    const classes = useStyles();

    return (
        <>
            {props.tenantinfo.tenant_id &&
                <Grid container className={classes.root} maxWidth="xl" spacing={0} direction="row"
                    justifyContent="center"
                    alignItems="center">
                <Grid item xs={12} lg={3} sm={4} md={3}>
                    <Grid item xs={10} lg={10} className={classes.marginAuto}>
                        <div className={classes.textAboveTab}>
                                <StyledSubtitle>Become a Certified Health Worker</StyledSubtitle>
                                <StyledTabText>
                                    To become a certified health worker it's necessary to request to access. Your request will be evaluated by the admin of the health organisation, if approved you will receive a credential that will allow you to access to the Medical Office System.
                                </StyledTabText>
                            </div>
                            <TabCard {...requestProps} variant='outlined'>
                                <StyledTabLine>
                                    Request to become a certified
                                    health worker within
                                    your health organization
                                </StyledTabLine>
                            </TabCard>
                        </Grid>
                    </Grid>
                    <Divider orientation="vertical" flexItem style={{ marginLeft: '1.2%', marginRight: '1%' }} />
                <Grid item xs={12} lg={3} sm={4} md={3}>
                    <Grid item xs={10} lg={10} className={classes.marginAuto}>
                        <div className={classes.textAboveTab}>
                                <StyledSubtitle>Access the Health Organisation's Issuing System</StyledSubtitle>
                                <StyledTabText>
                                    With the credential received from the Public Health Authority you, as the admin of the health organisation, can access the My Identity System issuing verifiable credentials to health workers.
                                </StyledTabText>
                            </div>
                            <TabCard {...issuingProps} variant='outlined'>
                                <StyledTabAccess>Access your health organization <br />
                                    My IDentity System
                                </StyledTabAccess>
                            </TabCard>
                        </Grid>
                    </Grid>
                    <Divider orientation="vertical" flexItem style={{ marginLeft: '1.2%', marginRight: '1%' }} />
                <Grid item xs={12} lg={3} sm={4} md={3}>
                    <Grid item xs={10} lg={10} className={classes.marginAuto}>
                        <div className={classes.textAboveTab}>
                                <StyledSubtitle>Access the Health Organisation's Medical Office System</StyledSubtitle>
                                <StyledTabText>
                                    You as administrator of the health organisation or as health worker can access the Medical Office System with the verifiable credential received from the
                                    My IDentity System.
                                </StyledTabText>
                            </div>
                            <TabCard {...mosProps} variant='outlined'>
                                <StyledTabAccess>
                                    Access your health organization <br />
                                    medical office system
                                </StyledTabAccess>
                            </TabCard>
                        </Grid>
                    </Grid>
                </Grid >
            }
        </>

    );
};
const mapStateToProps = (state) => {
    return {
        tenantinfo: state.updates.tenant,
        error: state.updates.error,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Landing);
