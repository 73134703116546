import React, { Component } from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { MenuList, MenuItem, Link } from '@material-ui/core/';
import styled from 'styled-components';
import { withTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { hasPermissions } from '../commons';
import patientsIcon from './assets/patientsIcon.svg';
import patientsIconBlue from './assets/icon-trustees-blue.svg';
import listIcon from './assets/icon-list.svg';
import listIconBlue from './assets/icon-list-blue.svg';
import homeIcon from './assets/icon-home.svg';
import homeIconBlue from './assets/icon-home-blue.svg';

const StyledMenuItem = styled(MenuItem)`
  &.MuiListItem-gutters {
    padding-left: 0;
  }
  &.MuiMenuItem-root {
    height: ${(props) => props.theme.menuItem.height};
    display: ${(props) => props.theme.menuItem.display};
    justify-content: ${(props) => props.theme.menuItem.justify};
    padding-left: ${(props) => props.theme.menuItem.pl};
    margin-top: ${(props) => props.theme.menuItem.mt};
    margin-bottom: ${(props) => props.theme.menuItem.mb};
    font-family: ${(props) => props.theme.fonts.openSans};
    font-size: ${(props) => props.theme.fonts.size.subtitle2};
    font-weight: ${(props) => props.theme.fonts.weight.semiBold};
    flex-wrap: wrap;
  }
  &.MuiListItem-root.Mui-selected {
    background-color: ${(props) => props.theme.menuItem.bg};
    border-radius: ${(props) => props.theme.menuItem.radius};
    color: ${(props) => props.theme.menuItem.color};
  }
  &.MuiListItem-root.Mui-selected:hover {
    background-color: ${(props) => props.theme.menuItem.bg};
    border-radius: ${(props) => props.theme.menuItem.radius};
  }
  &.MuiListItem-root.Mui-selected > a {
    color: ${(props) => props.theme.menuItem.color};
  }
  &.MuiMenuItem-root:hover {
    border-radius: ${(props) => props.theme.menuItem.radius};
  }
  .MuiLink-underlineHover:hover {
    text-decoration: none;
  }
`;
const StyledMenuList = styled(MenuList)`
  height: ${(props) => props.theme.sidebarmenu.menulist.height};
  &.MuiList-root {
    padding-right: 14px;
  }
`;

const StyledIcon = styled.img`
  margin-right: 4%;
`;

const StyledLink = styled(Link)`
  &.MuiTypography-root {
    text-decoration: none;
    color: ${(props) => props.theme.colors.onSurface};
  }
`;

function SidebarMenu(props) {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(null);
  const [permissions, setPermissions] = useState(false);

  const updateSelected = (index) => {
    setSelected(index);
  };

  useEffect(() => {
    if (hasPermissions(props.permissions, { admin: 'O' })) {
      setPermissions(true);
    } else {
      setPermissions(false);
    }
  }, [props.permissions]);

  return (
    <StyledMenuList component="nav">
      <StyledLink component={RouterLink} to="/patients" underline="none">
        <StyledMenuItem
          button
          onClick={() => updateSelected(0)}
          selected={selected === 0}
        >
          <StyledIcon src={selected === 0 ? homeIconBlue : homeIcon} />
          <span>{t('general.home')}</span>
        </StyledMenuItem>
      </StyledLink>
      <StyledLink component={RouterLink} to="/patients" underline="none">
        <StyledMenuItem
          button
          onClick={() => updateSelected(1)}
          selected={selected === 1}
        >
          <StyledIcon src={selected === 1 ? patientsIconBlue : patientsIcon} />
          {t('general.patients')}
        </StyledMenuItem>
      </StyledLink>
      {permissions ? (
        <StyledLink
          component={RouterLink}
          to="/vaccinebatches"
          underline="none"
        >
          <StyledMenuItem
            button
            onClick={() => updateSelected(2)}
            selected={selected === 2}
          >
            <StyledIcon src={selected === 2 ? listIconBlue : listIcon} />
            {t('general.vb')}
          </StyledMenuItem>
        </StyledLink>
      ) : null}
    </StyledMenuList>
  );
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    permissions: state.updates.permissions,
    mainContact: state.updates.mainContact,
    patinets: state.updates.patients,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTheme(
  connect(mapStateToProps, mapDispatchToProps)(SidebarMenu)
);
