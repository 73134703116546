import React from 'react';
import styled from 'styled-components';
import { Button, Card } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '@pgi/common/fe/styles/fonts.css';

const StyledSubtitle = styled.div`
  text-align: center;
  font-size: ${(props) => props.theme.fonts.size.subtitle3};
  font-weight: ${(props) => props.theme.fonts.weight.extraBold};
  font-family: ${(props) => props.theme.fonts.openSans};
`;
const StyledIcon = styled.img`
  height: ${(props) => props.theme.accessIcon.height};
`;
const StyledP = styled.p`
  font-size: ${(props) => props.theme.fonts.size.subtitle1};
  font-weight: ${(props) => props.theme.fonts.weight.regular};
  text-align: center;
  color: ${(props) => props.theme.colors.paragraph};
  line-height: 24px;
  width: 90%;
  font-family: ${(props) => props.theme.fonts.openSans};
  letter-spacing: 0.15px;
`;

const StyledCard = styled(Card)`
  padding: ${(props) => props.theme.card.padding};
  display: ${(props) => props.theme.card.display};
  flex-direction: ${(props) => props.theme.card.direction};
  justify-content: ${(props) => props.theme.card.justify};
  align-items: ${(props) => props.theme.card.align};
  max-width: ${(props) => props.theme.card.width};
  height: ${(props) => props.theme.card.height};
  box-sizing: ${(props) => props.theme.card.box};
  margin: 0 auto;
  &.MuiPaper-rounded {
    border-radius: ${(props) => props.theme.card.radius};
  }
`;
const TabCard = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const translations = props.translations;
  const ButtonType = props.btntype;
  return (
    <>
      <StyledCard variant='outlined' style={{ background: props.background, color: props.color }}>
        <StyledSubtitle>{t(translations.title)}</StyledSubtitle>
        <StyledIcon src={props.appIcon} />
        {props.children}
        <ButtonType onClick={() => (props?.isExternal ? window.location = props.appUri : history.push(props.appUri))} target="_blank">
          {t(translations.btnText)}
        </ButtonType>
      </StyledCard>
    </>
  );
};

export default TabCard;
