import React, { Component } from 'react';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs, Link } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';
import patients from './assets/icon-trustees-gray.svg';
import list from './assets/icon-list-gray.svg';

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.colors.breadcrumbs};
  display: flex;
  justify-content: baseline;
`;
const StyledBreadcrumbs = styled(Breadcrumbs)`
  &.MuiTypography-root {
  }
  & .MuiTypography-colorPrimary {
    color: ${(props) => props.theme.colors.breadcrumbs};
  }
  &.MuiTypography-root .MuiBreadcrumbs-separator {
    margin-left: 4px;
    margin-right: 4px;
  }
  &.MuiTypography-root .MuiBreadcrumbs-li {
    font-weight: ${(props) => props.theme.fonts.weight.semiBold};
  }
`;
const StyledIcon = styled.img`
  margin-right: 10px;
`;
const StyledDiv = styled.div`
  text-decoration: none;
  color: ${(props) => props.theme.colors.breadcrumbs};
  display: flex;
  justify-content: baseline;
`;
const StyledBreadcrumbWrapper = styled.div`
  display: flex;
  margin-bottom: 8px;
  padding-top: 11px;
`;

function BreadCrumbs() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [icon, setIcon] = useState();

  const parts = pathname.split('/');

  useEffect(() => {
    if (parts[1] === 'patients') {
      setIcon(patients);
    } else {
      setIcon(list);
    }
  }, []);

  const GetLinks = () => {
    let n = parts.length;
    const breadcrumbs = [];
    if (n > 3) {
      n = n - 1;
    }
    if (n > 1) {
      let href = '/';
      for (let i = 1; i < n - 1; i++) {
        href += parts[i].toLowerCase();
        breadcrumbs.push(
          <StyledLink key={parts[i]} href={href}>
            {t(`breadcrumbs.${parts[i].toLowerCase()}`)}
          </StyledLink>
        );
      }
      breadcrumbs.push(
        <StyledDiv key={parts[n - 1]}>
          {parts[1] === 'vaccination' ? 'Patient Profile / ' + t('breadcrumbs.2') : t(`breadcrumbs.${parts[n - 1].toLowerCase()}`)}
        </StyledDiv>
      );

      return breadcrumbs;
    }

    return null;
  };

  return (
    <>
      <StyledBreadcrumbWrapper>
        <StyledIcon src={icon} alt='breadcrumbs-icon' />
        <StyledBreadcrumbs>{GetLinks()}</StyledBreadcrumbs>
      </StyledBreadcrumbWrapper>
    </>
  );
}

const mapStateToProps = (/* state */) => {
  return {};
};

const mapDispatchToProps = (/* dispatch */) => {
  return {};
};

export default withTheme(
  connect(mapStateToProps, mapDispatchToProps)(BreadCrumbs)
);
