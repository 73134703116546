import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import { Dialog, Paper, Button } from '@material-ui/core/';
import close from './assets/close.svg';
import protectoriaLogo from './assets/protectoria_logo.svg';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';


const StyledPaper = styled(Paper)`
  &.MuiPaper-rounded {
    border-radius: 12px;
  }
  max-height: 500px;
  max-width: 460px;
  padding: 30px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  &.MuiPaper-elevation1 {
    box-shadow: none;
  }
`;
const StyledButton = styled(Button)`
  align-self: flex-end;
  &.MuiButton-root:hover {
    background-color: ${(props) => props.theme.colors.bg};
  }
  &.MuiButton-root {
    padding: 0;
    padding-left: 48px;
  }
`;
const Row = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
`;
const StyledHeadline = styled.h3`
  font-family: ${(props) => props.theme.fonts.openSans};
  font-size: ${(props) => props.theme.fonts.size.headline5};
  font-weight: ${(props) => props.theme.fonts.weight.bold};
  line-height: 32px;
  letter-spacing: 0.18px;
  text-align: center;
  color: #5db358;
`;
const StyledLogo = styled.img`
  margin-right: 30px;
`;
const StyledP = styled.p`
  font-family: ${(props) => props.theme.fonts.openSans};
  font-size: ${(props) => props.theme.fonts.size.subtitle2};
  font-weight: ${(props) => props.theme.fonts.weight.regular};
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: center;
`;
const StyledDialog = styled(Dialog)`
  & .MuiDialog-paperWidthSm {
    max-width: 100%;
    padding: 20px;
    max-height: 500px;
    max-width: 520px;
  }
`;
const StyledQRCode = styled(QRCode)`
  align-self: center;
  margin: 30px;
`;

function QrModal(props) {
  const { t } = useTranslation();

  return (
    <StyledDialog open={props.qrCode}>
      <StyledButton
          onClick={() => {
            props.handleClose();
          }}
        >
          <img src={close} alt="close-icon" />
        </StyledButton>
      <StyledPaper>
        <Row>
          {/* <StyledLogo src={protectoriaLogo} alt="protectoria-logo" /> */}
          <StyledHeadline>{t('qrmodal.title')}</StyledHeadline>
        </Row>
        <StyledQRCode value={props.qrCode} size={250} />
        <StyledP>{t('qrmodal.paragraph')}</StyledP>
      </StyledPaper>
    </StyledDialog>
  );
}

const mapStateToProps = (/*, ownProps*/) => {
  return {};
};

const mapDispatchToProps = () => {
  return {};
};

export default withTheme(connect(mapStateToProps, mapDispatchToProps)(QrModal));
