import API from '@pgi/common/fe/api';
import { getMocks } from './mock.js';
import FileDownload from 'js-file-download';

export const storePermissions = (data) => async (dispatch) => {
  dispatch({ type: 'PERMISSIONS', payload: data });
};

export const storeMainContact = (data) => async (dispatch /*, getState*/) => {
  dispatch({ type: 'MAIN_CONTACT', payload: data });
};

export const loadPatients = () => async (dispatch) => {
  let response;
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.get('/api/patients');
    } else {
      response = getMocks()['/api/patients'];
    }
    dispatch({ type: 'PATIENTS_LOADED', payload: response.data });
  } catch (e) {
    dispatch({ type: 'PATIENTS_ERROR', payload: e.toJSON().message });
  }
};

export const loadPatient = (data) => async (dispatch) => {
  let response;
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.get(`/api/patients/${data.patientId}`);
    } else {
      response = getMocks()['/api/patient'];
    }
    dispatch({ type: 'PATIENT_LOADED', payload: response.data });
  } catch (e) {
    dispatch({ type: 'PATIENT_ERROR', payload: e.toJSON().message });
  }
};

export const loadVaccines = () => async (dispatch) => {
  let response;
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.get('/api/vaccines');
    } else {
      response = getMocks()['/api/vaccines'];
    }
    dispatch({ type: 'VACCINES_LOADED', payload: response.data });
  } catch (e) {
    dispatch({ type: 'VACCINES_ERROR', payload: e.toJSON().message });
  }
};

export const submitRequest = (data) => async (dispatch) => {
  let response;
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.post('/papi/registrationhw', data);
    } else {
      response = getMocks()['/papi/registrationhw'];
    }
    dispatch({ type: 'HW_CREATED', payload: response.data });
  } catch (e) {
    dispatch({ type: 'HW_CREATED_ERROR', payload: e.toJSON().message });
    dispatch({ type: 'HW_CREATED_VALIDATION_ERROR', payload: e.response.data.fields });
  }
};

export const createPatient = (data) => async (dispatch) => {
  let response;
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.post('/api/patient', data);
    } else {
      response = getMocks()['/api/patient'];
    }
    dispatch({ type: 'PATIENT_CREATED', payload: response.data });
  } catch (e) {
    dispatch({ type: 'PATIENT_CREATED_ERROR', payload: { message: e?.toJSON().message, errorFields: e.response.data.fields } });
  }
};

export const editPatient = (data) => async (dispatch) => {
  let response;
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.patch('/api/patient', data);
    } else {
      response = getMocks()['/api/patient'];
    }
    dispatch({ type: 'PATIENT_EDITED', payload: response.data });
  } catch (e) {
    dispatch({ type: 'PATIENT_EDITED_ERROR', payload: e.toJSON().message });
  }
};

export const createVaccineBatch = (data) => async (dispatch) => {
  let response;
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.post('/api/vaccine', data);
    } else {
      response = getMocks()['/api/vaccine'];
    }
    dispatch({ type: 'VACC_BATCH_CREATED', payload: response.data });
  } catch (e) {
    dispatch({ type: 'VACC_BATCH_ERROR', payload: e.toJSON().message });
  }
};

export const submitVaccination = (data) => async (dispatch) => {
  let response;
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.post('/api/tracking', data);
    } else {
      response = getMocks()['/api/vaccination'];
    }
    dispatch({ type: 'VACCINATION_CREATED', payload: response.data });
  } catch (e) {
    dispatch({
      type: 'VACCINATION_CREATED_ERROR',
      payload: e.toJSON().message,
    });
  }
};

export const createVcVaccine = (data) => async (dispatch) => {
  let response;
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.post('/api/createoffer/vaccine', data);
    } else {
      response = getMocks()['/api/vaccination'];
    }
    dispatch({ type: 'VACC_VC_CREATED', payload: response.data });
  } catch (e) {
    dispatch({ type: 'VACC_VC_ERROR', payload: e.toJSON().message });
  }
};
export const reloadVaccineQrcode = (data) => async (dispatch) => {
  dispatch({ type: 'VACC_VC_RELOADED' });
};

export const storeUser = (data) => async (dispatch) => {
  dispatch({ type: 'USER', payload: data });
};

export const downloadPdf = (data) => async (dispatch) => {
  let response;
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.download('/api/certificate', data);
      FileDownload(response.data, `vaccination-${data.trackingId}.pdf`);
    } else {
      response = getMocks()['/api/certificate'];
    }
    dispatch({ type: 'PDF_CREATED', payload: response.data });
  } catch (e) {
    dispatch({ type: 'PDF_ERROR', payload: e.toJSON().message });
  }
};

export const sendCertificate = (data) => async (dispatch) => {
  let response;
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.post('/api/sendcertificate', data);
    } else {
      response = getMocks()['/api/certificate'];
    }
    dispatch({ type: 'CERTIFICATE_SENT', payload: response.data });
  } catch (e) {
    dispatch({ type: 'CERTIFICATE_SENT_ERROR', payload: e.response.data.error.email ? e.response.data.error.email : e.toJSON().message });
  }
};
export const sendingCertificateWithoutEmailError = (data) => (dispatch) => {
  dispatch({ type: 'CERTIFICATE_SENT_WITHOUT_EMAIL_ERROR', payload: data });
};
export const loadTracking = (data) => async (dispatch) => {
  let response;
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.get(`/api/tracking/${data.trackingId}`);
    } else {
      response = getMocks()['/api/tracking/:trackingId'];
    }
    dispatch({ type: 'TRACKING_LOADED', payload: response.data });
  } catch (e) {
    dispatch({ type: 'TRACKING_LOADED_ERROR', payload: e.toJSON().message });
  }
};

export const loadHealthOrg = (data) => async (dispatch) => {
  let response;
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.get(`/healthorgs/${data.hoId}`);
    } else {
      response = getMocks()['/healthorgs/:hoId'];
    }
    dispatch({ type: 'HO_LOADED', payload: response.data });
  } catch (e) {
    dispatch({ type: 'HO_LOADED_ERROR', payload: e.toJSON().message });
  }
};
export const getTenantInfo = (data) => async (dispatch) => {
  let response;
  try {
    if (!process.env.REACT_APP_MOCK) {
      response = await API.get(`/papi/tenant/${data.id}/${data.type}`);
    } else {
      response = getMocks()['/papi/tenant/:id/:type'];
    }
    dispatch({ type: 'TENANT_LOADED', payload: response.data });
  } catch (e) {
    dispatch({ type: 'TENANT_LOADED_ERROR', payload: e.toJSON().message });
  }
};
export const storeToken = (data) => async (dispatch) => {
  dispatch({ type: 'STORE_TOKEN', payload: data });
};

export const resetSuccessAndError = (data) => async (dispatch) => {
  dispatch({ type: 'RESET_ERROR_AND_SUCCESS', payload: data });
};

export const permissionsError = (data) => async (dispatch) => {
  // clear local storage if user has logged out from server
  localStorage.removeItem('mainContact');
  localStorage.removeItem('permissions');
  localStorage.removeItem('token');
  window.location.reload();
  dispatch({ type: 'PERMISSIONS_ERROR', payload: data });
};
