import { Container, Grid } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import hotelVerified from '../assets/hotel/safe-travels.png';
import hotelBg from '../assets/hotel/hotel-header-bg.png';
import hotelLogo from '../assets/hotel/tower-logo.png';
import { Link, useHistory, useLocation } from 'react-router-dom';
import './Home.css';
import Header from './Header';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,

    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    qrcode: {
        padding: '24px',
        textAlign: 'center',
        zIndex: 1,
        position: 'relative',
        top: '-50px',
        display: 'inline-block',
        borderRadius: '12px',
        boxShadow: 'none',
        background: 'transparent',
    },
    h2: {
        fontSize: '56px',
        fontWeight: '200',
        lineHeight: '64px',
        color: '#333333b5',
        fontFamily: 'Nunito Sans, sans-serif',
    },
    verified: {
        color: '#9ac42f',
        fontFamily: 'Nunito Sans, sans-serif',
        fontSize: '56px',
        fontWeight: '900',
        textAlign: 'center'
    },
    welcome: {
        marginTop: '8%',
        marginBottom: '5%',
        fontFamily: 'Nunito Sans, sans-serif',
    },
    p: {
        fontSize: '31px',
        color: '#33333380',
        maxWidth: '350px',
        fontFamily: 'Nunito Sans, sans-serif',
        lineHeight: '32px',
        textAlign: 'center',
        margin: '0 auto',
    },
    ul: {
        listStyleType: 'none',
        margin: 0,
        padding: 0,
        overflow: 'hidden',

        float: 'right'
    },
    li: {
        display: 'inline-block',
    },
    link: {
        color: 'rgb(255, 255, 255)',
        textAlign: 'center',
        padding: '16px',
        textDecoration: 'none',
        fontFamily: 'Nunito Sans, sans-serif',
        fontWeight: 200
    },
    menuContainer: {

    },
    headerContainer: {
        paddingTop: '40px',
        paddingRight: '81px',
        paddingLeft: '51px',
    }

}));
const HotelVerified = (props) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const history = useHistory();
    const classes = useStyles();
    const location = useLocation();
    const [vaccineStatus, setVaccineStatus] = useState(null);
console.log(location.state);
    useEffect(() => {
        if(location.state) {
            const vaccineData = JSON.parse(location.state?.vaccineStatus);
            setVaccineStatus(vaccineData.data?.vaccineStatus);
        }
     }, [location]);

    const handleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    history.listen(() => {
        setIsMenuOpen(false);
    });
    return (
        <React.Fragment>
            <Container maxWidth='xl' spacing={0} style={{ padding: 0, background: 'rgb(237, 241, 245)', height: 'fit-content' }}>
                <Grid container direction="row" justifyContent="center" >
                    <Grid container style={{ backgroundImage: `url(${hotelBg})`, height: '50vh', maxHeight: '489px' }} className={classes.headerContainer}>
                        <Grid item xs>
                            <Link to='/example/hotel'><img src={hotelLogo} alt='hotel-logo' style={{ width: '211px' }} /></Link>
                        </Grid>
                        <Grid item xs className={classes.menuContainer}>
                            <Header {...props} handleMenu={handleMenu} isMenuOpen={isMenuOpen} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ minHeight: '50vh' }}>
                        <Grid container alignItems='center' justifyContent='center' >
                            <Grid container item xs={12} sm={12} md={7} lg={7} direction="row" justifyContent='center' spacing={2} style={{ marginBottom: '0' }}>
                                <Grid item xs={12} sm={12} md={6} style={{ marginBottom: '12%', width: '50%' }}>
                                    <div className={classes.welcome}>
                                        <h2 className={classes.verified}>{vaccineStatus === 2 ?  'Verified': 'Not Verified'}</h2>
                                        <p className={classes.p}>{vaccineStatus === 2 ?  'The customer is verified as vaccinated.' : 'The customer is not fully vaccinated.'}</p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Paper className={classes.qrcode}>
                                        <img src={hotelVerified} height='350px' width='350px' alt='hotel-verified' />
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment>
    );
};

export default HotelVerified;
