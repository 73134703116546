import React from 'react';
import {
  FormGroup,
  Container,
  InputLabel,
  Divider,
  InputBase,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import { createPatient } from '../actions';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import editIcon from './assets/editIcon.svg';
import { parseDateFormat } from '../commons';

const StyledContainer = styled(Container)`
  height: 100%;
`;

const StyledSubtitle = styled.h4`
  font-weight: ${(props) => props.theme.fonts.weight.semiBold};
  font-size: ${(props) => props.theme.fonts.size.subtitle2};
  line-height: 24px;
  letter-spacing: 0.15px;
  margin-right: 50px;
  color: #2e2e30;
`;

const StyledInputLabel = styled(InputLabel)`
  &.MuiFormLabel-root {
    font-size: ${(props) => props.theme.fonts.size.overline};
    font-weight: ${(props) => props.theme.fonts.weight.semiBold};
    line-height: 16px;
    letter-spacing: 1.5px;
    text-align: left;
    margin-bottom: 10px;
    margin-top: 30px;
  }
`;
const AdminFormGroup = styled(FormGroup)`
  &.MuiFormGroup-row {
    width: 100%;
    justify-content: space-between;
  }
  &.MuiFormGroup-row > div {
    width: 48%;
  }
`;
const StyledDivider = styled(Divider)`
  &.MuiDivider-root {
    margin-top: 35px;
    margin-bottom: 35px;
  }
`;
const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

function PatientDetails(props) {
  const { t } = useTranslation();

  return (
    <>
      <StyledContainer>
        <StyledDiv>
          <StyledSubtitle>{t('patientprofile.title')}</StyledSubtitle>
          <img src={editIcon} onClick={props.handleClick} alt="edit-icon" />
        </StyledDiv>
        <AdminFormGroup row>
          <FormGroup>
            <StyledInputLabel>{t('commonfields.firstName')}</StyledInputLabel>
            <InputBase
              name="firstName"
              value={props.patient.firstName || ''}
              disabled
            />
          </FormGroup>
          <FormGroup>
            <StyledInputLabel>{t('commonfields.lastName')}</StyledInputLabel>
            <InputBase
              name="lastName"
              value={props.patient.lastName || ''}
              disabled
            />
          </FormGroup>
        </AdminFormGroup>
        <AdminFormGroup row>
          <FormGroup>
            <StyledInputLabel>
              {t('requestform.subtitle.address')}
            </StyledInputLabel>
            <InputBase
              name="streetAddress"
              value={props.patient.address || ''}
              disabled
            />
          </FormGroup>
          <FormGroup>
            <StyledInputLabel>
              {t('commonfields.postalCode').toUpperCase()}
            </StyledInputLabel>
            <InputBase
              name="postalCode"
              value={
                (props.patient.data && props.patient.data.postalCode) || ''
              }
              disabled
            />
          </FormGroup>
        </AdminFormGroup>
        <AdminFormGroup row>
          <FormGroup>
            <StyledInputLabel>
              {t('commonfields.district').toUpperCase()}
            </StyledInputLabel>
            <InputBase
              name="district"
              value={(props.patient.data && props.patient.data.district) || ''}
              disabled
            />
          </FormGroup>
          <FormGroup>
            <StyledInputLabel>
              {t('commonfields.city').toUpperCase()}
            </StyledInputLabel>
            <InputBase name="city" value={props.patient.city || ''} disabled />
          </FormGroup>
        </AdminFormGroup>
        <AdminFormGroup row>
          <FormGroup>
            <StyledInputLabel>
              {t('commonfields.state').toUpperCase()}
            </StyledInputLabel>
            <InputBase
              name="state"
              value={(props.patient.data && props.patient.data.state) || ''}
              disabled
            />
          </FormGroup>
          <FormGroup>
            <StyledInputLabel>
              {t('commonfields.countryId').toUpperCase()}
            </StyledInputLabel>
            <InputBase
              name="country"
              value={props.patient.country || ''}
              disabled
            />
          </FormGroup>
        </AdminFormGroup>
        <AdminFormGroup row>
          <FormGroup>
            <StyledInputLabel>{t('patientform.birthDate')}</StyledInputLabel>
            <InputBase
              name="birthDate"
              value={parseDateFormat(props.patient.birthdate) || ''}
              disabled
              type="date"
            />
          </FormGroup>
          <FormGroup>
            <StyledInputLabel id="sex-label">
              {t('patientform.sex')}
            </StyledInputLabel>
            <InputBase name="sex" value={props.patient.sex || ''} disabled />
          </FormGroup>
        </AdminFormGroup>
        <AdminFormGroup row>
          <FormGroup>
            <StyledInputLabel>{t('commonfields.email')}</StyledInputLabel>
            <InputBase
              name="email"
              value={props.patient.email || ''}
              disabled
            />
          </FormGroup>
          <FormGroup>
            <StyledInputLabel>{t('commonfields.phone')}</StyledInputLabel>
            <InputBase
              name="phoneNumber"
              value={props.patient.phone || ''}
              disabled
            />
          </FormGroup>
        </AdminFormGroup>
        <AdminFormGroup>
          <StyledInputLabel>{t('patientform.ssn')}</StyledInputLabel>
          <InputBase
            name="socialSecurityNr"
            value={props.patient.socialSecurity || ''}
            disabled
          />
        </AdminFormGroup>
        <StyledDivider />
        <StyledSubtitle>{t('patientform.emergencycontact')}</StyledSubtitle>
        <AdminFormGroup row>
          <FormGroup>
            <StyledInputLabel>{t('patientform.contactName')}</StyledInputLabel>
            <InputBase
              name="contactName"
              placeholder="Insert contact name"
              value={props.patient.contactName || ''}
              disabled
            />
          </FormGroup>
          <FormGroup>
            <StyledInputLabel>
              {t('patientform.contactDetails')}
            </StyledInputLabel>
            <InputBase
              name="contactDetails"
              value={props.patient.contactDetails || ''}
              disabled
            />
          </FormGroup>
        </AdminFormGroup>
      </StyledContainer>
    </>
  );
}
const mapStateToProps = (state /*, ownProps*/) => {
  return {
    patient: state.updates.patient,
    mainContact: state.updates.mainContact,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createPatient: (data) => {
      dispatch(createPatient(data));
    },
  };
};

export default withTheme(
  connect(mapStateToProps, mapDispatchToProps)(PatientDetails)
);
