import React, { Component } from 'react';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import {
  AppBar,
  Button,
  Popper,
  Paper,
  MenuList,
  MenuItem,
  ClickAwayListener,
  Grid,
} from '@material-ui/core';
import Avatar from './avatar';
import menu from './assets/more.svg';
import { hasPermissions } from '../commons';
import expand from './assets/expand_more_24px.svg';
import { useTranslation } from 'react-i18next';
import { logout } from '@pgi/common/fe/utility';

const StyledAppBar = styled(AppBar)`
  &.MuiAppBar-root {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.04));
    color: ${(props) => props.theme.colors.onSurface};
    z-index: 1400;
  }
  &.MuiAppBar-positionFixed {
    // left: 15%;
    background-color: ${(props) => props.theme.colors.bg};
    // position: unset;
  }
  height: 60px;
  display: flex;
  justify-content: space-between;
`;
const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 3%;
  align-items: end;
  float: right;
  margin-right: 0;
  position: relative;
`;
const StyledTitleDiv = styled.div`
  width: 39%;
  position: fixed;
  left: 16em;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
const StyledTitle = styled.span`
  font-family: ${(props) => props.theme.fonts.openSans};
  font-size: ${(props) => props.theme.fonts.size.subtitle1};
  font-weight: ${(props) => props.theme.fonts.weight.bold};
`;
const StyledIdTitle = styled.h6`
  font-family: ${(props) => props.theme.fonts.openSans};
  font-size: ${(props) => props.theme.fonts.size.subtitle2};
  font-weight: ${(props) => props.theme.fonts.weight.regular};
  color: ${(props) => props.theme.colors.primary};
  font-size: 21px;
  margin: 22px 0 22px 0px;
  letter-spacing: -0.74px;
`;
const StyledSubtitle = styled.span`
  font-family: ${(props) => props.theme.fonts.openSans};
  font-size: ${(props) => props.theme.fonts.size.subtitle2};
  font-weight: ${(props) => props.theme.fonts.weight.regular};
  color: rgba(0, 0, 0, 0.6);
`;
const StyledPopper = styled(Popper)`
  width: auto;
  min-width: 300px;
  margin-top: 10px;
  z-index: 1;
`;
const StyledButton = styled(Button)`
  &.MuiButton-root:hover {
    background-color: ${(props) => props.theme.colors.transparent};
  }
  &.MuiButton-root:active {
    transition: unset;
  }
`;

function AvatarBar(props) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [permissions, setPermissions] = useState(false);

  useEffect(() => {
    if (props.mainContact.Healthworker) {
      setFirstName(props.mainContact.Healthworker.firstName);
      setLastName(props.mainContact.Healthworker.lastName);
    }
  }, [props.mainContact]);

  const handleToggle = (e) => {
    setOpen(!open);
    setAnchorEl(e.currentTarget);
  };

  useEffect(() => {
    if (hasPermissions(props.permissions, { admin: 'O' })) {
      setPermissions(true);
    } else {
      setPermissions(false);
    }
  }, [props.permissions]);

  return (
    <>
      {/*  spacing={3} */}
      <Grid container>
        <Grid item xs={1} style={{
          position: 'absolute',
          left: '10px'
        }}>
        <Button onClick={() => props.handleMenu()}>
          <img src={menu} alt='menu-icon' />
        </Button>
        </Grid>
        <Grid item xs={6} sm md={6} lg={8} xl={10}>
          <StyledIdTitle>{t('avatarbar.title')}</StyledIdTitle>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={4} xl>
          <Grid item >
            <StyledDiv>
              <Avatar firstName={firstName} lastName={lastName} />
              <Column>
                <StyledTitle>
                  {firstName} {lastName}
                </StyledTitle>
                {permissions ? (
                  <StyledSubtitle>{t('avatarbar.admin')}</StyledSubtitle>
                ) : (
                  <StyledSubtitle>{t('avatarbar.hw')}</StyledSubtitle>
                )}
              </Column>
              <StyledButton onClick={handleToggle} disableRipple>
                <img src={expand} alt='expand-icon' />
              </StyledButton>
              <StyledPopper
                open={open}
                anchorEl={anchorEl}
                transition
                disablePortal
                placement='bottom-end'
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleToggle}>
                    <MenuList>
                      <MenuItem onClick={logout}>Logout</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </StyledPopper>
            </StyledDiv>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    mainContact: state.updates.mainContact,
    token: state.updates.token,
    permissions: state.updates.permissions,
    patients: state.updates.patients,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTheme(
  connect(mapStateToProps, mapDispatchToProps)(AvatarBar)
);
