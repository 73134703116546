import React, { Component } from 'react';
import { Button, Paper } from '@material-ui/core/';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import VaccinationForm from './vaccinationform';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { loadPatient } from '../actions';
import styled from 'styled-components';
import addIcon from './assets/add-icon.svg';
import infoIcon from './assets/icon-Atention.svg';
import { parseDate, parseTime, hasPermissions, getTime } from '../commons';

const StyledDiv = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const StyledPaper = styled(Paper)`
  &.MuiPaper-root {
    height: 56px;
    padding: 16px 24px;
    width: 80%;
    border-radius: 12px;
    padding: 8px, 16px, 8px, 16px;
    background-color: rgba(33, 33, 33, 0.08);
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    font-size: 14px;
    font-style: italic;
    font-weight: ${(props) => props.theme.fonts.weight.regular};
    line-height: 24px;
    letter-spacing: 0.1px;
    text-align: left;
    color: ${(props) => props.theme.colors.paragraph};
    margin-top: 4%;
  }
  & .MuiPaper-rounded {
    border-radius: 12px;
  }
`;
const StyledList = styled(Paper)`
  &.MuiPaper-rounded {
    border-radius: 12px;
    margin-top: 30px;
  }
  &.MuiPaper-elevation0 {
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.04), 0px 3px 3px rgba(0, 0, 0, 0.12),
      0px 1px 8px rgba(0, 0, 0, 0.1);
  }
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 170px;
  width: 71%;
  left: 915px;
  top: 184px;
  padding: 22px;
`;

const StyledTitle = styled.div`
  font-family: ${(props) => props.theme.fonts.openSans};
  font-size: ${(props) => props.theme.fonts.size.headline6};
  font-weight: ${(props) => props.theme.fonts.weight.bold};
  line-height: 24px;
  letter-spacing: 0.15px;
  text-align: left;
`;
const StyledDate = styled.span`
  color: #0089d0;
  font-size: ${(props) => props.theme.fonts.size.subtitle2};
  font-weight: ${(props) => props.theme.fonts.weight.semiBold};
  line-height: 16px;
  letter-spacing: 1.25px;
  text-align: left;
`;
const StyledButton = styled(Button)`
  &.MuiButtonBase-root {
    background-color: #2196f3;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    transform: translate(0, 50%);
  }
  &.MuiButtonBase-root:hover {
    background-color: #2196f3;
  }
`;
const StyledSubtitle = styled.h2`
  align-self: flex-start;
  margin-left: 10%;
  font-family: ${(props) => props.theme.fonts.openSans};
  font-size: ${(props) => props.theme.fonts.size.headline6};
  font-weight: ${(props) => props.theme.fonts.weight.semiBold};
  line-height: 24px;
  letter-spacing: 0.15px;
  text-align: left;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const StyledIcon = styled.img`
  height: 25px;
  width: 25px;
  cursor: pointer;
`;
const StyledOverline = styled.div`
  font-family: ${(props) => props.theme.fonts.openSans};
  font-size: ${(props) => props.theme.fonts.size.overline};
  font-weight: ${(props) => props.theme.fonts.weight.semiBold};
  line-height: 16px;
  text-align: left;
`;
const StyledP = styled.p`
  font-family: ${(props) => props.theme.fonts.openSans};
  font-size: ${(props) => props.theme.fonts.size.subtitle1};
  font-weight: ${(props) => props.theme.fonts.weight.regular};
  line-height: 24px;
  letter-spacing: 0.15px;
  text-align: left;
`;
const StyledInfo = styled.div`
  font-family: ${(props) => props.theme.fonts.openSans};
  font-size: ${(props) => props.theme.fonts.size.subtitle2};
  font-weight: ${(props) => props.theme.fonts.weight.regular};
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;
`;
const StyledBlue = styled.p`
  color: ${(props) => props.theme.colors.primary};
`;

function VaccinationList(props) {
  const { t } = useTranslation();
  const [formOpen, setFormOpen] = useState();
  const history = useHistory();
  const { patientId } = useParams();

  useEffect(() => {
    props.loadPatient({ patientId });
  }, [props.vaccination, patientId]);

  useEffect(() => {
    if (props.openForm) {
      setFormOpen(true);
    }
  }, [props.openForm]);

  const handleClick = (id) => {
    history.push(`/vaccination/${id}`);
  };

  const handleCancel = () => {
    setFormOpen(false);
  };

  return (
    <StyledDiv>
      <StyledSubtitle>{t('vaccinationlist.title')}</StyledSubtitle>
      {props.patient &&
      props.patient.Trackings &&
      props.patient.Trackings.length !== 0 ? (
        <>
          {props.patient.Trackings.map((tracking) => {
            return (
              <StyledList key={tracking.id} elevation={0} square={false}>
                <Row>
                  <StyledOverline>
                    {tracking.Vaccine.manufacturer.toUpperCase()}
                  </StyledOverline>
                  {!hasPermissions(props.permissions, { admin: 'O' }) ? (
                    <StyledIcon
                      src={infoIcon}
                      alt='info-icon'
                      onClick={() => handleClick(tracking.id, tracking)}
                    />
                  ) : null}
                </Row>
                <StyledTitle>{tracking.Vaccine.profilaxis}</StyledTitle>
                <StyledP>
                  {parseDate(tracking.vaccinationDate)} •{' '}
                  {getTime(tracking.vaccinationDate)} • {' '}
                  {tracking.shotNumber} out of 2 • {tracking.HealthOrg.name}
                </StyledP>
                <StyledInfo>
                  <StyledBlue>
                    {t('vaccdetails.nextdate')}:{' '}
                    <StyledDate>{parseDate(tracking.nextDate)}</StyledDate>
                  </StyledBlue>
                  <p>
                    {t('vaccdetails.nexttime')}: {parseTime(tracking.nextDate)}
                  </p>
                </StyledInfo>
              </StyledList>
            );
          })}
        </>
      ) : (
        <>
          <StyledPaper elevation={0}>
            <div>{t('vaccinationlist.NA')}</div>
          </StyledPaper>
        </>
      )}
      {!hasPermissions(props.permissions, { admin: 'O' }) ? (
        <>
          <StyledButton onClick={() => setFormOpen(true)}>
            <img src={addIcon} alt='add-icon' />
          </StyledButton>
          {formOpen && <VaccinationForm handleCancel={handleCancel} />}
        </>
      ) : null}
    </StyledDiv>
  );
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    hoId: state.updates.hoId,
    patient: state.updates.patient,
    vaccination: state.updates.vaccination,
    mainContact: state.updates.mainContact,
    permissions: state.updates.permissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadPatient: (data) => {
      dispatch(loadPatient(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VaccinationList);
