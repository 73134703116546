import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Mos from './mos';
import Landing from './landing';
import { connect } from 'react-redux';
import { getTenantInfo } from '../actions';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const TENANT_TYPE = 'health_org';

const DefaultLanding = (props) => {
    const query = useQuery();
    const [showDefaultPage, setShowDefaultPage] = useState(true);
    const tenantId = query.get('tenant_id');
    const tenantObj = JSON.parse(localStorage.getItem('health_org'));

    useEffect(async () => {
        if (tenantId) {
            await props.getTenantInfo({ id: tenantId, type: TENANT_TYPE });
        }
    }, [tenantId]);
    // if tenant info do not exist or if its different then one in url, load from backend
    useEffect(() => {
        if (tenantId || Object.keys(props.tenant).length) {
            setShowDefaultPage(false);
        }
    }, [tenantId, props.tenant]);

    return (
        <React.Fragment>
            {showDefaultPage ? <Mos /> : <Landing {...props} />}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        tenant: state.updates.tenant,
        error: state.updates.error,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getTenantInfo: (data) => {
            dispatch(getTenantInfo(data));
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(DefaultLanding);