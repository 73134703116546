import axios from 'axios';

class API {
  constructor(url) {
    let token = '';
    try {
      token = localStorage.getItem('token');
    } catch (e) {
      console.log(e);
    }
    const service = axios.create({
      baseURL: url,
      headers: { 'X-Token': token },
    });
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;
    this.token = token;
  }

  setAuthToken = (token) => {
    this.token = token;
  };

  handleSuccess = (response) => {
    return response;
  };

  handleError = (error) => {
    return Promise.reject(error);
  };

  redirectTo = (document, path) => {
    document.location = path;
  };

  get = (path) => {
    return this.service.get(path, {
      method: 'GET',
      responseType: 'json',
      headers: { 'X-Token': this.token },
    });
  };

  patch = (path, payload) => {
    return this.service.request(path, {
      method: 'PATCH',
      responseType: 'json',
      data: payload,
      headers: { 'X-Token': this.token },
    });
  };

  post = (path, payload) => {
    return this.service.request(path, {
      method: 'POST',
      responseType: 'json',
      data: payload,
      headers: { 'X-Token': this.token },
    });
  };

  download = (path, payload) => {
    return this.service.request(path, {
      method: 'POST',
      responseType: 'blob',
      data: payload,
      headers: { 'X-Token': this.token },
    });
  };

  put = (path, payload) => {
    return this.service.request(path, {
      method: 'PUT',
      responseType: 'json',
      data: payload,
      headers: { 'X-Token': this.token },
    });
  };

  delete = (path, payload) => {
    return this.service.request(path, {
      method: 'DELETE',
      responseType: 'json',
      data: payload,
      headers: { 'X-Token': this.token },
    });
  };
}

export default new API(
  process.env.REACT_APP_API_URL || 'http://localhost:3200'
);
//ADD SSI_TOKEN HERE
