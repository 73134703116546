import React, { Component } from 'react';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Box, TextField, InputAdornment } from '@material-ui/core/';

import styled from 'styled-components';
import { loadVaccines, permissionsError } from '../actions';
import { useTranslation } from 'react-i18next';
import addIcon from './assets/add-icon.svg';
import searchIcon from './assets/search.svg';
import { hasPermissions, parseDate } from '../commons';
import BreadCrumbs from './breadcrumbs';
import {
  PrimaryButton,
  StyledDataGrid,
} from '@pgi/common/fe/styles/common-styles';

const StyledBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;
const StyledTextField = styled(TextField)`
  background-color: ${(props) => props.theme.colors.bg};
  & .MuiOutlinedInput-root {
    height: 40px;
    font-size: ${(props) => props.theme.fonts.size.caption};
    font-weight: ${(props) => props.theme.fonts.weight.regular};
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: left;
    border-radius: 12px;
    border-color: ${(props) => props.theme.colors.bg};
  }
  &.MuiFormControl-root {
    border-radius: 12px;
  }
  & .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.colors.bg};
  }
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 98.5%;
  padding-left: 0%;
`;

function VaccineList(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const [input, setInput] = useState('');
  const [matches, setMatches] = useState([]);

  useEffect(() => {
    if (hasPermissions(props.permissions, { admin: 'O' })) {
      props.loadVaccines();
    } else {
      props.permissionsError('403');
      history.push('/patients');
    }
  }, [props.permissions]);

  useEffect(() => {
    const matchesArr = [];
    props.vaccines &&
      props.vaccines.map((vaccine) => {
        if (
          vaccine.profilaxis.toLowerCase().includes(input.toLowerCase()) ||
          parseDate(vaccine.arrivalDate).includes(input)
        ) {
          if (matchesArr.includes(vaccine)) {
            return matchesArr;
          } else {
            matchesArr.push(vaccine);
          }
        }

        return null;
      });
    setMatches(matchesArr);
  }, [input, props.vaccines]);

  const addNew = () => {
    history.push('vaccinebatches/new');
  };

  const columns = [
    {
      field: 'arrivalDate',
      headerName: 'ARRIVED ON',
      flex: 1.5,
      renderCell: (params) => <>{parseDate(params.row.arrivalDate)}</>,
    },
    { field: 'disease', headerName: 'DISEASE', flex: 1, sortable: false },
    { field: 'profilaxis', headerName: 'VACCINE', flex: 1, sortable: false },
    {
      field: 'product',
      headerName: 'MEDICINAL PRODUCT',
      flex: 2,
      sortable: false,
    },
    {
      field: 'manufacturer',
      headerName: 'MANUFACTURER',
      flex: 2,
      sortable: false,
    },
    {
      field: 'authorizationHolder',
      headerName: 'AUTHORIZATION HOLDER',
      flex: 2,
      sortable: false,
    },
    { field: 'batch', headerName: 'BATCH NR.', flex: 1, sortable: false },
    {
      field: 'doses',
      headerName: 'DOSES',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
    },
  ];

  return (
    <StyledDiv>
      <BreadCrumbs />
      <StyledBox>
        <PrimaryButton onClick={addNew} variant='contained' disableElevation>
          <img src={addIcon} alt='add-icon' />
          {t('buttons.addnew')}
        </PrimaryButton>
        <StyledTextField
          placeholder={t('general.searchNameVaccine')}
          variant='outlined'
          value={input}
          onChange={(e) => setInput(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <img src={searchIcon} alt='search-icon' />
              </InputAdornment>
            ),
          }}
        />
      </StyledBox>
      <StyledDataGrid
        columns={columns}
        rows={matches ? matches : props.vaccines}
        autoHeight={true}
        hideFooterSelectedRowCount
        pageSize={10}
        disableColumnMenu
        sortingOrder={['desc', 'asc']}
        sortModel={[
          {
            field: 'arrivalDate',
            sort: 'asc',
          },
        ]}
      />
    </StyledDiv>
  );
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    vaccines: state.updates.vaccines,
    hoId: state.updates.hoId,
    mainContact: state.updates.mainContact,
    permissions: state.updates.permissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadVaccines: (data) => {
      dispatch(loadVaccines(data));
    },
    permissionsError: (data) => {
      dispatch(permissionsError(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VaccineList);
