import React, { Component }  from 'react';
import {useEffect, useState} from 'react';
import { FormGroup, Button, TextField, Box, InputLabel, Divider } from '@material-ui/core';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withTheme } from 'styled-components';
import { createVaccineBatch, permissionsError } from '../actions';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from './breadcrumbs';
import { useHistory } from 'react-router-dom';
import {hasPermissions} from '../commons';
import { PrimaryButton, RightContentDiv } from '@pgi/common/fe/styles/common-styles';
import validator from 'validator';

const StyledContainer = styled(Box)`
&.MuiBox-root {
  width: 60%;
}
  padding-bottom: 50px;
`;
const StyledSubtitle = styled.h4`
font-weight: ${props=>props.theme.fonts.weight.semiBold};
font-size: ${props=>props.theme.fonts.size.headline6};
line-height: 24px;
letter-spacing: 0.15px;
`;
const StyledButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
const StyledInputLabel = styled(InputLabel)`
  &.MuiFormLabel-root {
    font-size: ${props=>props.theme.fonts.size.overline};
    font-weight: ${props=>props.theme.fonts.weight.semiBold};
    line-height: 16px;
    letter-spacing: 1.5px;
    text-align: left;
    margin-bottom: 10px;
    margin-top: 30px;
  }
`;
const AdminFormGroup = styled(FormGroup)`
&.MuiFormGroup-row {
  width: 100%;
  justify-content: space-between;
}
&.MuiFormGroup-row > div {
  width: 48%
}
`;

const StyledDivider = styled(Divider)`
  &.MuiDivider-root {
    margin-top: 35px;
    margin-bottom: 35px;
  }
`;

const SecondaryButton = styled(Button)`
&.MuiButton-contained {
  color: ${props => props.theme.buttons.secondary.color};
  background-color: ${props => props.theme.buttons.secondary.bg};
  font-family: ${props => props.theme.fonts.openSans};
  font-weight: ${props => props.theme.fonts.weight.semiBold};
  font-size: ${props => props.theme.fonts.size.subititle2};
  letter-spacing: ${props => props.theme.fonts.letter.button};
}
&.MuiButton-contained:hover {
  color: ${props => props.theme.buttons.secondary.color};
  background-color: ${props => props.theme.buttons.secondary.bg};
}
&.MuiButton-contained.Mui-disabled {
  color: ${props => props.theme.buttons.disabled.color};
  background-color: ${props => props.theme.buttons.disabled.bg};
}
`;
const StyledTextField = styled(TextField)`
  & .MuiFilledInput-root {
    background-color: ${(props) => props.theme.colors.bg};
    border: 1px solid rgba(0, 0, 0, 0.12);
    transition: unset;
    border-radius: 4px;
  }
  & .MuiFilledInput-underline:before {
    border: 0;
  }
  & .MuiFilledInput-underline:after {
    border-color: ${(props) => props.theme.colors.onSurface};
  }
  & .MuiFilledInput-root:hover {
    background-color: ${(props) => props.theme.colors.bg};
  }
  & .MuiFilledInput-root.Mui-focused {
    background-color: ${(props) => props.theme.colors.bg};
  }
  & .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.colors.onSurface};
  }
  & .MuiInputBase-input.Mui-disabled {
    background-color: ${(props) => props.theme.colors.bg};
    opacity: unset;
    border-radius: 4px;
  }
  & .MuiFilledInput-root.Mui-disabled {
    background-color: unset;
    color: ${(props) => props.theme.colors.disabled};
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
  & .MuiFilledInput-input {
    padding: 20px;
  }
`;
const StyledWarning = styled.div`
  color: ${(props) => props.theme.colors.error};
  font-size: 14px;
  font-family: ${(props) => props.theme.fonts.openSans};
`;

function VaccineForm(props) {
  const { t } = useTranslation();
  const hoId = props.hoId;
  const history = useHistory();
  const [disabledNext, setDisabledNext] = useState(true);
  const [disabledInput, setDisabledInput] = useState(false);
  const [errors, setErrors] = useState({});
  const [fields, setFields] = useState({
    arrivalDate: '',
    batchNr: '',
    doses: '',
    disease: '',
    vaccine: '',
    medicinalProduct: '',
    manufacturer: '',
    holder: ''
  });


  useEffect(()=>{
    if (!hasPermissions(props.permissions, {'admin': 'O'})) {
      props.permissionsError('403');
      history.push('/patients');
    }
  }, []);

  useEffect(()=>{
    if (
      fields.arrivalDate &&
      fields.batchNr &&
      !isNaN(parseInt(fields.batchNr)) &&
      fields.doses &&
      !isNaN(parseInt(fields.doses)) &&
      fields.disease &&
      fields.vaccine &&
      fields.medicinalProduct &&
      fields.manufacturer &&
      fields.holder
    ) {
      setDisabledNext(false);
    } else {
      setDisabledNext(true);
    }
  });

  useEffect(()=>{
    if (props.success) {
      setFields({
        arrivalDate: '',
        batchNr: '',
        doses: '',
        disease: '',
        vaccine: '',
        medicinalProduct: '',
        manufacturer: '',
        holder: ''
      });
      history.push('/vaccinebatches');
    }
  }, [props.success]);

  const handleNext = () => {
    setDisabledInput(true);
  };
  const handleEdit = () => {
    if (props.patient) {
      props.edit();
    }
    setDisabledInput(false);
  };

  const handleSubmit = () => {
    props.createVaccineBatch({...fields, hoId});
  };
  const errorMessages = {
    arrivalDate: 'Future dates not allowed in arrival date.',
    doses: 'Doses must be a number below 100.',
    batchNr: 'Batch number must be a number, strings not allowed.'
  };
  const validateInput = (e) => {
    const today = new Date();
    const nextDate = new Date(today.setDate(today.getDate() + 1));
    const inputValue = e.target.value;
    const onlyText = /^[a-zA-Z]+$/;
    const noSpace = /^\S*$/;
    const isSSN = /^(?!(000|666|9))\d{3}-?(?!(00))\d{2}-?(?!(0000))\d{4}$/;
    const inputName = e.target.name;
    switch (e.target.name) {
      case 'arrivalDate': {
        if (!validator.isDate(inputValue)) {
          setErrors({ ...errors, [e.target.name]: 'Invalid Date' });
          return setDisabledNext(true);
        }
        if (validator.isAfter(inputValue, nextDate.toDateString())) {
          setErrors({ ...errors, arrivalDate: errorMessages.arrivalDate });
          return setDisabledNext(true);
        }
        const { arrivalDate, ...newErrorObj } = errors;
        setErrors(newErrorObj);
      }
        break;
      case 'doses': {
        if (!validator.isNumeric(inputValue) || inputValue > 101) {
          setErrors({ ...errors, [e.target.name]: errorMessages.doses });
          return setDisabledNext(true);
        }
        const { doses, ...newErrorObj } = errors;
        setErrors(newErrorObj);
      }
        break;
      case 'batchNr': {
        if (!validator.isNumeric(inputValue)) {
          setErrors({ ...errors, [e.target.name]: errorMessages.batchNr });
          return setDisabledNext(true);
        }
        const { batchNr, ...newErrorObj } = errors;
        setErrors(newErrorObj);
      }
        break;
    }
  };
  const handleInputChange = (e) => {
    validateInput(e);
    setFields({...fields, [e.target.name]: e.target.value});
  };


  return (
    <>
      <RightContentDiv>
        <StyledContainer>
          <BreadCrumbs />
          <StyledSubtitle>{t('vaccineform.title')}</StyledSubtitle>
          <StyledWarning>{t('general.mandatory')}</StyledWarning>
          <AdminFormGroup row>
            <FormGroup>
              <StyledInputLabel>
                {t('vaccineform.arrivalDate')}
              </StyledInputLabel>
              <StyledTextField
                name='arrivalDate'
                value={fields.arrivalDate}
                onChange={handleInputChange}
                type='date'
                InputLabelProps={{
                  shrink: true,
                }}
                variant='filled'
                disabled={disabledInput}
                placeholder='11/05/2021'
                error={errors?.arrivalDate ? errors?.arrivalDate : ''}
                helperText={errors?.arrivalDate ? errors?.arrivalDate : ''}
              />
            </FormGroup>
            <FormGroup>
              <StyledInputLabel>{t('vaccineform.disease')}</StyledInputLabel>
              <StyledTextField
                name='disease'
                value={fields.disease}
                onChange={handleInputChange}
                variant='filled'
                disabled={disabledInput}
                placeholder='Insert text'
                autoComplete='off'
              />
            </FormGroup>
          </AdminFormGroup>
          <AdminFormGroup>
            <StyledInputLabel>{t('vaccineform.vaccine')}</StyledInputLabel>
            <StyledTextField
              name='vaccine'
              value={fields.vaccine}
              onChange={handleInputChange}
              variant='filled'
              disabled={disabledInput}
              placeholder='Insert text'
              autoComplete='off'
            />
          </AdminFormGroup>
          <AdminFormGroup>
            <StyledInputLabel>
              {t('vaccineform.medicinalProduct')}
            </StyledInputLabel>
            <StyledTextField
              name='medicinalProduct'
              value={fields.medicinalProduct}
              onChange={handleInputChange}
              variant='filled'
              disabled={disabledInput}
              placeholder='Insert text'
              autoComplete='off'
            />
          </AdminFormGroup>
          <AdminFormGroup row>
            <FormGroup>
              <StyledInputLabel>
                {t('vaccineform.manufacturer')}
              </StyledInputLabel>
              <StyledTextField
                name='manufacturer'
                value={fields.manufacturer}
                onChange={handleInputChange}
                variant='filled'
                disabled={disabledInput}
                placeholder='Insert text'
                autoComplete='off'
              />
            </FormGroup>
            <FormGroup>
              <StyledInputLabel>{t('vaccineform.holder')}</StyledInputLabel>
              <StyledTextField
                name='holder'
                value={fields.holder}
                onChange={handleInputChange}
                variant='filled'
                disabled={disabledInput}
                placeholder='Insert text'
                autoComplete='off'
              />
            </FormGroup>
          </AdminFormGroup>
          <AdminFormGroup row>
            <FormGroup>
              <StyledInputLabel>{t('vaccineform.batchNr')}</StyledInputLabel>
              <StyledTextField
                name='batchNr'
                value={fields.batchNr}
                onChange={handleInputChange}
                variant='filled'
                disabled={disabledInput}
                placeholder='Insert number'
                error={errors?.batchNr ? errors?.batchNr : ''}
                helperText={errors?.batchNr ? errors?.batchNr : ''}
                autoComplete='off'
              />
            </FormGroup>
            <FormGroup>
              <StyledInputLabel>{t('vaccineform.doses')}</StyledInputLabel>
              <StyledTextField
                name='doses'
                value={fields.doses}
                onChange={handleInputChange}
                variant='filled'
                disabled={disabledInput}
                placeholder='Insert number'
                error={errors?.doses ? errors?.doses : ''}
                helperText={errors?.doses ? errors?.doses : ''}
                autoComplete='off'
              />
            </FormGroup>
          </AdminFormGroup>
          <StyledDivider />
          <StyledButtonGroup>
            {disabledInput ? (
              <>
                <SecondaryButton
                  onClick={handleEdit}
                  variant='contained'
                  disableElevation
                >
                  {props.patient ? t('buttons.cancel') : t('buttons.editForm')}
                </SecondaryButton>
                <PrimaryButton
                  onClick={handleSubmit}
                  variant='contained'
                  disableElevation
                >
                  {t('buttons.submit')}
                </PrimaryButton>
              </>
            ) : (
              <PrimaryButton
                disabled={disabledNext}
                onClick={handleNext}
                variant='contained'
                disableElevation
              >
                {t('buttons.next')}
              </PrimaryButton>
            )}
          </StyledButtonGroup>
        </StyledContainer>
      </RightContentDiv>
    </>
  );
}
const mapStateToProps = (state /*, ownProps*/) => {
  return {
    hoId: state.updates.hoId,
    success: state.updates.success,
    mainContact: state.updates.mainContact,
    permissions: state.updates.permissions
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createVaccineBatch: (data) => {
      dispatch(createVaccineBatch(data));
    },
    permissionsError: (data) => {
      dispatch(permissionsError(data));
    }
  };
};

export default withTheme(connect(mapStateToProps, mapDispatchToProps)(VaccineForm));
