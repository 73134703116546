import { Box, CircularProgress, Container, Grid } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import QRCode from 'qrcode.react';
import styled, { withTheme } from 'styled-components';
import { useHistory } from 'react-router';

const StyledBar = styled.div`
  background-color: ${(props) => props.theme.colors.primary};`;

const MAX_TIME = 100;

const ScanQrCode = (props) => {
    const [qrcode, setQrcode] = useState('');
    const [counter, setCounter] = useState(0);
    const history = useHistory();
    const countRef = useRef(counter);
    const [reload, setReload] = useState(true);
    countRef.current = counter;
    let timeout;
    const WEBSOCKET = '/api/hotelCheck';
    const decrement = (countRefCurrent) => {
        setCounter(countRefCurrent.current - 1);
        if (countRefCurrent.current > 0) {
            timeout = setTimeout(() => decrement(countRefCurrent), 1000);
        }
        // reload the qrcode if timer gets over
        if (countRefCurrent.current === 0) {
            setReload(true);
            setCounter(MAX_TIME);
        }
    };
    const onMessage = (message) => {
        const parsed = JSON.parse(message.data);
        try {
            if (parsed.data?.error === 0) {
                history.push({pathname: '/example/verified', state: { vaccineStatus: message.data }});
            }
            if (parsed.qrcode) {
                setQrcode(parsed.qrcode);
                setCounter(MAX_TIME);
                timeout = setTimeout(() => decrement(countRef), 1000);
                setReload(false);
            }
            if(parsed.error) {
                window.alert(parsed.message);
            }
        } catch (e) {
            console.log(e);
        }
    };
    const getQrCode = () => {
        const socket = new WebSocket(
            process.env.REACT_APP_SOCKET_URL
                ? `${process.env.REACT_APP_SOCKET_URL}${WEBSOCKET}`
                : `ws://localhost:3200${WEBSOCKET}`
        );
        socket.onmessage = (e) => onMessage(e);
        socket.onerror = (e) => {
            console.log(e);
        };
        socket.onopen = () => {
            socket.send({ login: 'request' });
        };

        return function () {
            clearTimeout(timeout);
            socket.close();
        };
    };
    useEffect(() => {
        if (reload === true) {
            getQrCode();
        }
    }, [reload]);

    return (
        <React.Fragment>
            {counter ? (
                <>
                    <QRCode value={qrcode} size={350} />
                    <div style={{ width: '350px', margin: '0 auto' }}>
                        <StyledBar className='bar' style={{ width: `${(counter / MAX_TIME) * 100}%` }} >
                            &nbsp;
                        </StyledBar>
                    </div>
                </>
            ) : (
                    <Box sx={{}}>
                        <div style={{ width: '350px', height: '350px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><CircularProgress /></div>
                    </Box>
            )}
        </React.Fragment>
    );
};

export default ScanQrCode;

