import React from 'react';
import { useState, useEffect, useRef } from 'react';
import API from '../api';
import QRCode from 'qrcode.react';
import { Grid } from '@material-ui/core';
import { useToken } from '../storage';
import { useTranslation } from 'react-i18next';
import styled, { withTheme } from 'styled-components';
import { useHistory } from 'react-router-dom';

const StyledTitle = styled.div`
  font-family: ${(props) => props.theme.fonts.openSans};
  font-size: ${(props) => props.theme.fonts.size.headline5};
  font-weight: ${(props) => props.theme.fonts.weight.bold};
  color: ${(props) => props.theme.colors.accessTitle};
  text-align: center;
`;

const StyledP = styled.p`
  font-family: ${(props) => props.theme.fonts.openSans};
  font-size: ${(props) => props.theme.bodyText.fontSize};
  color: ${(props) => props.theme.bodyText.color};
  font-weight: ${(props) => props.theme.fonts.weight.regular};
  text-align: center;
  width: 25%;
`;
const StyledBar = styled.div`
  background-color: ${(props) => props.theme.colors.primary};
`;

const MAX_TIME = 60;

function LogIn(props) {
  const [token, setToken] = useToken();
  const [qrcode, setQrcode] = useState('');
  const [error, setError] = useState('');
  const [counter, setCounter] = useState(0);
  const { t } = useTranslation();
  const transKeys = props.translations;
  const countRef = useRef(counter);
  countRef.current = counter;
  const history = useHistory();
  let timeout;

  const decrement = (countRefCurrent) => {
    setCounter(countRefCurrent.current - 1);
    if (countRefCurrent.current > 0) {
      timeout = setTimeout(() => decrement(countRefCurrent), 1000);
    }
  };

  const onMessage = (message) => {
    try {
      const parsed = JSON.parse(message.data);
      if (parsed.token) {
        setToken(parsed.token);
        API.setAuthToken(parsed.token);
        localStorage.setItem('token', parsed.token);
        localStorage.setItem('permissions', JSON.stringify(parsed.permissions));
        localStorage.setItem('mainContact', JSON.stringify(parsed.mainContact));
        props.storePermissions(parsed.permissions);
        // store main contact
        if (props.hasOwnProperty('storeMainContact')) {
          props.storeMainContact(parsed.mainContact);
        }
        props.storeUser(parsed);
        props.storeToken(parsed.token);
        history.push(props.GOTOURL);
      }
      if (parsed.qrcode) {
        setQrcode(parsed.qrcode);
        setCounter(MAX_TIME);
        timeout = setTimeout(() => decrement(countRef), 1000);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const socket = new WebSocket(
      process.env.REACT_APP_SOCKET_URL
        ? `${process.env.REACT_APP_SOCKET_URL}${props.WEBSOCKET}`
        : `ws://localhost:3200${props.WEBSOCKET}`
    );
    // const socket = new WebSocket('ws://localhost:3200/api/login/issuing');
    socket.onmessage = (e) => onMessage(e);
    socket.onerror = (e) => {
      setError(e);
    };
    socket.onopen = () => {
      socket.send({ login: 'request' });
    };

    return function () {
      clearTimeout(timeout);
      socket.close();
    };
  }, []);

  return (
    <>
      <Grid container direction='column' justify='center' alignItems='center'>
        <StyledTitle>{t(transKeys.styledTitle)}</StyledTitle>
        <StyledP>{t(transKeys.loginText)}</StyledP>
        <div className='qrcodeContainer'>
          {counter ? (
            <>
              <QRCode value={qrcode} size={250} />
              <StyledBar
                className='bar'
                style={{ width: `${(counter / MAX_TIME) * 100}%` }}
              >
                &nbsp;
              </StyledBar>
            </>
          ) : (
            <div>Please refresh the page...</div>
          )}
        </div>
      </Grid>
    </>
  );
}

export default withTheme(LogIn);
