import {
  InputLabel,
  Divider,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Button,
  TextField,
  Container,
} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import styled, { withTheme } from 'styled-components';

const PrimaryButton = styled(Button)`
  &.MuiButton-contained {
    display: flex;
    justify-content: space-around;
    height: 40px;
    min-width: 100px;
    border-radius: ${(props) =>
      props.theme.buttons.roundedPrimary.borderRadius};
    background-color: ${(props) =>
      props.theme.buttons.roundedPrimary.background};
    color: ${(props) => props.theme.buttons.roundedPrimary.color};
    font-size: ${(props) => props.theme.fonts.size.subtitle2};
    font-weight: ${(props) => props.theme.fonts.weight.semiBold};
    line-height: 16px;
    letter-spacing: 1.25px;
    text-align: left;
  }
  &.MuiButton-contained:hover {
    background-color: ${(props) =>
      props.theme.buttons.roundedPrimary.background};
    color: ${(props) => props.theme.buttons.roundedPrimary.color};
  }
  &.MuiButton-contained.Mui-disabled {
    color: ${(props) => props.theme.colors.disabled};
    background-color: ${(props) =>
    props.theme.buttons.disabled.bg};
  }
`;

const StyledDataGrid = styled(DataGrid)`
  &.MuiDataGrid-root {
    margin-top: 10px;
    background-color: ${(props) => props.theme.colors.bg};
    border: 0px;
    border-radius: 12px 12px 0px 0px;
    font-family: ${(props) => props.theme.fonts.openSans};
    font-size: ${(props) => props.theme.fonts.size.subtitle2};
    letter-spacing: 0.25px;
  }
  & .MuiDataGrid-colCellWrapper {
    background-color: #e3f2fd;
    color: ${(props) => props.theme.buttons.primary.bg};
    border-radius: 12px 12px 0px 0px;
  }
  & .MuiDataGrid-columnSeparator {
    visibility: hidden;
  }
  & .MuiDataGrid-footer {
    width: 79%;
    border: 0px;
    position: fixed;
    bottom: 5%;
  }
  & .MuiTablePagination-root {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  & .MuiTablePagination-root > * {
    font-size: ${(props) => props.theme.fonts.size.caption};
    font-weight: ${(props) => props.theme.fonts.weight.regular};
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: right;
    color: ${(props) => props.theme.colors.paragraph};
  }
  &.MuiDataGrid-root .MuiDataGrid-colCellTitle {
    letter-spacing: 1.25px;
    font-weight: ${(props) => props.theme.fonts.weight.semiBold};
  }
  &.MuiDataGrid-root .MuiDataGrid-columnsContainer {
    background-color: rgb(227, 242, 253);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: none;
  }
  &.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle,
  .columnTitles {
    color: ${(props) => props.theme.table.header.titleText.color};
    font-weight: ${(props) => props.theme.fonts.weight.semiBold};
  }
  .header-content-center .MuiDataGrid-columnHeaderTitleContainer {
    text-align: center;
    display: block;
    align-items: center;
  }
  &.MuiDataGrid-root button.MuiIconButton-sizeSmall {
    color: ${(props) => props.theme.table.header.titleText.color};
    background: #e3f2fd;
  }
  &.MuiDataGrid-root button.MuiIconButton-sizeSmall span {
    background-image: ${(props) => props.theme.table.header.downArrow.bgImage};
    background-position-x: inherit;
    width: 25px;
    background-position-y: center;
    height: 100%;
  }
  &.MuiDataGrid-root .MuiDataGrid-main .MuiSvgIcon-fontSizeSmall {
    color: ${(props) => props.theme.table.header.titleText.color};
    background: #e3f2fd;
    display: none;
  }
  &.MuiDataGrid-root .MuiDataGrid-main .MuiIconButton-root:hover {
    background-color: #e3f2fd;
  }
  &.MuiDataGrid-root .MuiDataGrid-cell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &.MuiDataGrid-root .MuiDataGrid-cell:focus,
  &.MuiDataGrid-root .MuiDataGrid-cell:focus-within,
  &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
  &.MuiDataGrid-root .MuiDataGrid-cell:focus {
    outline: none !important;
  }
  &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
  &.MuiDataGrid-root .MuiDataGrid-cell:focus {
    outline: none !important;
  }
  &.MuiDataGrid-root .nameColumn {
    line-height: normal !important;
  }
  &.MuiDataGrid-root .MuiBadge-badge {
    position: initial;
  }
  &.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
    padding: 0 0px;
  }
  &.MuiDataGrid-root .MuiDataGrid-columnHeader {
  }
`;
const RightContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 98.5%;
  padding-left: 0%;
`;
const StyledTextField = styled(TextField)`
  & .MuiFilledInput-root {
    background-color: ${(props) => props.theme.colors.bg};
    border: 1px solid rgba(0, 0, 0, 0.12);
    transition: unset;
    border-radius: 4px;
  }
  & .MuiFilledInput-underline:before {
    border: 0;
  }
  & .MuiFilledInput-underline:after {
    border-color: ${(props) => props.theme.colors.onSurface};
  }
  & .MuiFilledInput-root:hover {
    background-color: ${(props) => props.theme.colors.bg};
  }
  & .MuiFilledInput-root.Mui-focused {
    background-color: ${(props) => props.theme.colors.bg};
  }
  & .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.colors.paragraph};
  }
  & .MuiInputBase-input.Mui-disabled {
    background-color: ${(props) => props.theme.colors.bg};
    opacity: unset;
    border-radius: 4px;
  }
  & .MuiFilledInput-root.Mui-disabled {
    background-color: unset;
    color: ${(props) => props.theme.colors.disabled};
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
  & .MuiFormLabel-root {
    color: #00000066;
  }
  & .MuiFilledInput-input {
    padding: 20px;
  }
  &.date-wrapper-class {
    width: 100%;
  }
`;
const StyledInputLabel = styled(InputLabel)`
  &.MuiFormLabel-root {
    font-size: ${(props) => props.theme.fonts.size.overline};
    font-weight: ${(props) => props.theme.fonts.weight.semiBold};
    line-height: 16px;
    letter-spacing: 1.5px;
    text-align: left;
    margin-bottom: 10px;
    margin-top: 30px;
  }
`;
const StyledFormGroup = styled(FormGroup)`
  margin-bottom: 10px;
  & .MuiFormControl-root {
    width: 48%;
  }
  &.MuiFormGroup-row {
    justify-content: space-between;
    min-height: 85px;
  }
  & .MuiInputBase-root {
    width: 100%;
  }
`;
const AdminFormGroup = styled(FormGroup)`
  &.MuiFormGroup-row {
    width: 100%;
    justify-content: space-between;
    min-height: 140px;
  }
  &.MuiFormGroup-row > div {
    width: 48%;
  }
`;
export {
  PrimaryButton,
  StyledDataGrid,
  RightContentDiv,
  StyledTextField,
  StyledInputLabel,
  StyledFormGroup,
  AdminFormGroup
};
