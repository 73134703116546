import React, { Component } from 'react';
import { useEffect, useState } from 'react';
import {
  FormControl,
  FormGroup,
  Button,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Box,
  Container,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import { createPatient, editPatient } from '../actions';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { parseDate, parseDateFormat } from '../commons';
import { useHistory } from 'react-router-dom';
import BreadCrumbs from './breadcrumbs';
import {
  PrimaryButton,
  RightContentDiv,
  StyledTextField,
  StyledInputLabel,
} from '@pgi/common/fe/styles/common-styles';
import validator from 'validator';
import RequiredField from '@pgi/common/fe/components/requiredfield';

const StyledContainer = styled(Container)`
  &.MuiContainer-root {
    //width: ${(props) => (props.patient ? 'none' : '62%')};
  }
  padding-bottom: 50px;
  &.MuiContainer-root {
    margin-left: 0;
  }
`;

const StyledFormGroup = styled(FormGroup)`
  margin-bottom: 20px;
  & .MuiFormControl-root {
    width: 48%;
    min-width: 300px;
  }
  &.MuiFormGroup-row {
    justify-content: space-between;
    flex-wrap: wrap;
  }
  & .MuiInputBase-root {
    width: 100%;
  }
`;
const StyledSubtitle = styled.h4`
  font-weight: ${(props) => props.theme.fonts.weight.semiBold};
  font-size: ${(props) => props.theme.fonts.size.headline6};
  line-height: 24px;
  letter-spacing: 0.15px;
`;
const StyledButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
const AdminFormGroup = styled(FormGroup)`
  &.MuiFormGroup-row {
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  &.MuiFormGroup-row > div {
    width: 48%;
    min-width: 300px;
  }
`;
const StyledDivider = styled(Divider)`
  &.MuiDivider-root {
    margin-top: 35px;
    margin-bottom: 35px;
  }
`;

const SecondaryButton = styled(Button)`
  &.MuiButton-contained {
    color: ${(props) => props.theme.buttons.secondary.color};
    background-color: ${(props) => props.theme.buttons.secondary.bg};
    font-family: ${(props) => props.theme.fonts.openSans};
    font-weight: ${(props) => props.theme.fonts.weight.semiBold};
    font-size: ${(props) => props.theme.fonts.size.subititle2};
    letter-spacing: ${(props) => props.theme.fonts.letter.button};
  }
  &.MuiButton-contained:hover {
    color: ${(props) => props.theme.buttons.secondary.color};
    background-color: ${(props) => props.theme.buttons.secondary.bg};
  }
  &.MuiButton-contained.Mui-disabled {
    color: ${(props) => props.theme.buttons.disabled.color};
    background-color: ${(props) => props.theme.buttons.disabled.bg};
  }
`;
const StyledSelect = styled(Select)`
  background-color: ${(props) => props.theme.colors.bg};
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  &.MuiFilledInput-root {
    background-color: ${(props) => props.theme.colors.bg};
    transition: unset;
  }
  &.MuiFilledInput-underline:before {
    border: 0;
  }
  &.MuiFilledInput-underline:after {
    border-color: ${(props) => props.theme.colors.onSurface};
  }
  &.MuiFilledInput-root:hover {
    background-color: ${(props) => props.theme.colors.bg};
  }
  &.MuiFilledInput-root.Mui-focused {
    background-color: ${(props) => props.theme.colors.bg};
  }
  &.MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.colors.onSurface};
  }
  &.MuiInputBase-input.Mui-disabled {
    background-color: ${(props) => props.theme.colors.bg};
    opacity: unset;
    border-radius: 4px;
  }
  &.MuiFilledInput-root.Mui-disabled {
    background-color: unset;
    color: ${(props) => props.theme.colors.disabled};
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
`;
const StyledFC = styled(FormControl)`
  & .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.colors.onSurface};
  }
  & .MuiFilledInput-input {
    padding: 20px;
  }
`;
const StyledDateField = styled(TextField)`
  & .MuiFilledInput-root {
    background-color: ${(props) => props.theme.colors.bg};
    border: 1px solid rgba(0, 0, 0, 0.12);
    transition: unset;
    border-radius: 4px;
  }
  & .MuiFilledInput-underline:before {
    border: 0;
  }
  & .MuiFilledInput-underline:after {
    border-color: ${(props) => props.theme.colors.onSurface};
  }
  & .MuiFilledInput-root:hover {
    background-color: ${(props) => props.theme.colors.bg};
  }
  & .MuiFilledInput-root.Mui-focused {
    background-color: ${(props) => props.theme.colors.bg};
  }
  & .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.colors.onSurface};
  }
  & .MuiInputBase-input.Mui-disabled {
    background-color: ${(props) => props.theme.colors.bg};
    opacity: unset;
    border-radius: 4px;
  }
  & .MuiFilledInput-root.Mui-disabled {
    background-color: unset;
    color: ${(props) => props.theme.colors.disabled};
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
  & .MuiFilledInput-input {
    padding: 20px;
  }
`;
const StyledWarning = styled.div`
  color: ${(props) => props.theme.colors.error};
  font-size: ${(props) => props.theme.fonts.size.subtitle2}; ;
`;

function PatientForm(props) {
  const { t } = useTranslation();
  const params = useParams();
  const history = useHistory();
  const [errors, setErrors] = useState({});
  const [fields, setFields] = useState({
    firstName: (props.patient && props.patient.firstName) || '',
    lastName: (props.patient && props.patient.lastName) || '',
    streetAddress: (props.patient && props.patient.address) || '',
    postalCode:
      (props.patient && props.patient.data && props.patient.data.postalCode) ||
      '',
    district:
      (props.patient && props.patient.data && props.patient.data.district) ||
      '',
    city: (props.patient && props.patient.city) || '',
    state:
      (props.patient && props.patient.data && props.patient.data.state) || '',
    country: (props.patient && props.patient.country) || '',
    birthDate:
      (props.patient && parseDateFormat(props.patient.birthdate)) || '',
    sex: (props.patient && props.patient.sex) || '',
    email: (props.patient && props.patient.email) || '',
    phoneNumber: (props.patient && props.patient.phone) || '',
    socialSecurityNr: (props.patient && props.patient.socialSecurity) || '',
    contactName: (props.patient && props.patient.contactName) || '',
    contactDetails: (props.patient && props.patient.contactDetails) || '',
  });

  const [disabledNext, setDisabledNext] = useState(props.patient ? false : true);
  const [disabledInput, setDisabledInput] = useState(false);

  useEffect(() => {
      if (
        fields.firstName &&
        fields.lastName &&
        fields.streetAddress &&
        fields.postalCode &&
        fields.district &&
        fields.city &&
        fields.state &&
        fields.country &&
        fields.birthDate &&
        fields.sex &&
        fields.contactName &&
        fields.socialSecurityNr &&
        !Object.keys(errors).length
      ) {
        setDisabledNext(false);
      } else {
        setDisabledNext(true);
      }
  });

  const handleNext = () => {
    setDisabledInput(true);
  };
  const handleEdit = () => {
    if (props.patient) {
      props.edit();
    }
    setDisabledInput(false);
  };

  const handleSubmit = () => {
    setErrors({});
    if (props.patient) {
      props.editPatient({ ...fields, patientId: params.patientId });
      props.edit();
    } else {
      props.createPatient({ data: fields });
    }
  };

  useEffect(() => {
    if (props.success) {
      setFields({
        firstName: '',
        lastName: '',
        streetAddress: '',
        postalCode: '',
        district: '',
        city: '',
        state: '',
        country: '',
        birthDate: '',
        sex: '',
        email: '',
        phoneNumber: '',
        socialSecurityNr: '',
        contactName: '',
        contactDetails: '',
      });
      history.push('/patients');
    }
  }, [props.success]);
  // handle server side errors
  useEffect(() => {
    if (props.errorFields && Object.keys(props.errorFields).length) {
      for (const fieldName in props.errorFields[0]) {
        setErrors({ ...errors, [fieldName]: props.errorFields[0][fieldName] });
        return setDisabledInput(true);
      }
    }
  }, [props.errorFields]);

  const errorMessages = {
    birthDate: 'Birthdate must be before todays date.',
    firstName: 'Allowed max 50 characters and no numbers inside first name.',
    lastName: 'Allowed max 50 characters and no numbers inside last name.',
    postalCode: 'Invalid Postal Code, Allowed only numbers with min 3 & max 7 digits',
    country: 'The code is not according to ISO standards.',
    email: 'Invalid Email',
    phoneNumberNoSpace: 'Spaces not allowed in phone number.',
    phoneNumber: 'Not a valid phone number.',
    socialSecurityNr: 'Not a valid phil health id, valid format is (2 digits)-(9 digits)-(1 digit)',
    contactName: 'Allowed max 50 characters and no numbers inside conatct name.',

  };
  const validateInput = (e) => {
    const today = new Date();
    const inputValue = e.target.value;
    const onlyText = /^[a-zA-Z\s]+$/;
    const noSpace = /^\S*$/;
    // const isSSN = /^(?!(000|666|9))\d{3}-?(?!(00))\d{2}-?(?!(0000))\d{4}$/;
    const isSSN = /^[0-9]{2}[-][0-9]{9}[-][0-9]$/;
    const inputName = e.target.name;
    switch (e.target.name) {
      case 'birthDate': {
        if (!validator.isDate(e.target.value)) {
          setErrors({ ...errors, [e.target.name]: 'Invalid Date' });
          return setDisabledNext(true);
        }
        if (validator.isAfter(e.target.value, today.toDateString())) {
          setErrors({ ...errors, birthDate: errorMessages.birthDate });
          return setDisabledNext(true);
        }
        const { birthDate, ...newErrorObj } = errors;
        setErrors(newErrorObj);
      }
        break;
      case 'firstName': {
        if (!onlyText.test(inputValue) || inputValue.length > 50) {
          setErrors({ ...errors, firstName: errorMessages.firstName });
          setDisabledNext(true);
          return;
        }
        const { firstName, ...newErrorObject } = errors;
        setErrors(newErrorObject);
      }
        break;
      case 'lastName': {
        if (!onlyText.test(inputValue) || inputValue.length > 50) {
          setErrors({ ...errors, lastName: errorMessages.lastName });
          setDisabledNext(true);
          return;
        }

        const { lastName, ...newErrorObject } = errors;
        setErrors(newErrorObject);
      }
        break;
      case 'postalCode':
        if (!validator.isPostalCode(inputValue, 'any')) {
          setErrors({ ...errors, postalCode: errorMessages.postalCode });
          setDisabledNext(true);
          return;
        } else {
          const { postalCode, ...newErrorObject } = errors;
          setErrors(newErrorObject);
        }
        break;
      case 'country': {
        if (!validator.isISO31661Alpha2(inputValue) && !validator.isISO31661Alpha3(inputValue)) {
          setErrors({ ...errors, country: errorMessages.country });
          setDisabledNext(true);
          return;
        }
        const { country, ...newErrorObject } = errors;
        setErrors(newErrorObject);
      }
        break;
      case 'email': {
        if (inputValue && !validator.isEmail(inputValue)) {
          setErrors({ ...errors, email: errorMessages.email });
          setDisabledNext(true);
          return;
        }
        const { email, ...newErrorObject } = errors;
        setErrors(newErrorObject);
      }
        break;
      case 'phoneNumber':
      case 'contactDetails': {
        if (!noSpace.test(inputValue)) {
          setErrors({ ...errors, [inputName]: errorMessages.phoneNumberNoSpace });
          setDisabledNext(true);
          return;
        }
        if (inputValue && !validator.isMobilePhone(inputValue)) {
          setErrors({ ...errors, [inputName]: errorMessages.phoneNumber });
          setDisabledNext(true);
          return;
        }
        if (inputName === 'phoneNumber') {
          const { phoneNumber, ...newErrorObject } = errors;
          setErrors(newErrorObject);
        }
        if (inputName === 'contactDetails') {
          const { contactDetails, ...newErrorObject } = errors;
          setErrors(newErrorObject);
        }
      }
        break;
      case 'socialSecurityNr': {
        if (!isSSN.test(inputValue)) {
          setErrors({ ...errors, socialSecurityNr: errorMessages.socialSecurityNr });
          setDisabledNext(true);
          return;
        }

        const { socialSecurityNr, ...newErrorObject } = errors;
        setErrors(newErrorObject);
      }
        break;
      case 'contactName': {
        if (!onlyText.test(inputValue) || inputValue.length > 50) {
          setErrors({ ...errors, contactName: errorMessages.contactName });
          setDisabledNext(true);
          return;
        }

        const { contactName, ...newErrorObject } = errors;
        setErrors(newErrorObject);
      }
        break;
    }
  };

  const handleInputChange = (e) => {
    validateInput(e);
    setFields({ ...fields, [e.target.name]: e.target.value });
  };

  return (
    <>
      <RightContentDiv>
        <StyledContainer patient={props.patient}>
          {props.patient ? null : <BreadCrumbs />}
          <StyledSubtitle>
            {props.patient
              ? t('patientform.title.edit')
              : t('patientform.title.add')}
          </StyledSubtitle>
          {!props.patient ? (
            <StyledWarning>{t('general.mandatorymark')}</StyledWarning>
          ) : null}
          <AdminFormGroup row>
            <FormGroup>
              <StyledInputLabel>{t('commonfields.firstName')}<RequiredField /></StyledInputLabel>
              <StyledTextField
                name='firstName'
                value={fields.firstName}
                onChange={handleInputChange}
                disabled={disabledInput}
                variant='filled'
                placeholder={t('commonfields.firstNameLabel')}
                error={errors?.firstName ? errors?.firstName : ''}
                helperText={errors?.firstName ? errors?.firstName : ''}
              />
            </FormGroup>
            <FormGroup>
              <StyledInputLabel>{t('commonfields.lastName')}<RequiredField /></StyledInputLabel>
              <StyledTextField
                name='lastName'
                value={fields.lastName}
                onChange={handleInputChange}
                disabled={disabledInput}
                variant='filled'
                placeholder={t('commonfields.lastNameLabel')}
                error={errors?.lastName ? errors?.lastName : ''}
                helperText={errors?.lastName ? errors?.lastName : ''}
              />
            </FormGroup>
          </AdminFormGroup>
          <StyledInputLabel>{t('requestform.subtitle.address')}<RequiredField /></StyledInputLabel>
          <StyledFormGroup row>
            <StyledTextField
              name='streetAddress'
              placeholder={t('commonfields.streetAddress')}
              value={fields.streetAddress}
              onChange={handleInputChange}
              disabled={disabledInput}
              variant='filled'
            />
            <StyledTextField
              name='postalCode'
              placeholder={t('commonfields.postalCode')}
              value={fields.postalCode}
              onChange={handleInputChange}
              disabled={disabledInput}
              variant='filled'
              error={errors?.postalCode ? errors?.postalCode : ''}
              helperText={errors?.postalCode ? errors?.postalCode : ''}
            />
          </StyledFormGroup>
          <StyledFormGroup row>
            <StyledTextField
              name='district'
              placeholder={t('commonfields.district')}
              value={fields.district}
              onChange={handleInputChange}
              disabled={disabledInput}
              variant='filled'
            />
            <StyledTextField
              name='city'
              placeholder={t('commonfields.city')}
              value={fields.city}
              onChange={handleInputChange}
              disabled={disabledInput}
              variant='filled'
            />
          </StyledFormGroup>
          <StyledFormGroup row>
            <StyledTextField
              name='state'
              placeholder={t('commonfields.state')}
              value={fields.state}
              onChange={handleInputChange}
              disabled={disabledInput}
              variant='filled'
            />
            <StyledTextField
              name='country'
              placeholder={t('commonfields.countryId')}
              value={fields.country}
              onChange={handleInputChange}
              disabled={disabledInput}
              variant='filled'
              error={errors?.country ? errors?.country : ''}
              helperText={errors?.country ? errors?.country : ''}
            />
          </StyledFormGroup>
          <AdminFormGroup row>
            <FormGroup>
              <StyledInputLabel>{t('patientform.birthDate')}<RequiredField /></StyledInputLabel>
              <StyledDateField
                name='birthDate'
                value={fields.birthDate}
                onChange={handleInputChange}
                disabled={disabledInput}
                variant='filled'
                type='date'
                format='DD-MM-YYYY'
                placeholder='dd-mm-yyyy'
                error={errors?.birthDate ? errors?.birthDate : ''}
                helperText={errors?.birthDate ? errors?.birthDate : ''}
              />
            </FormGroup>
            <FormGroup>
              <StyledInputLabel id='sex-label'>{t('patientform.sex')}<RequiredField /></StyledInputLabel>
              <StyledFC variant='filled'>
                <InputLabel id='sex-label'>
                  {t('patientform.sexLabel')}
                </InputLabel>
                <StyledSelect
                  labelId='sex-label'
                  name='sex'
                  value={fields.sex}
                  disabled={disabledInput}
                  onChange={handleInputChange}
                  placeholder={t('commonfields.select')}
                >
                  <MenuItem value={'F'}>F</MenuItem>
                  <MenuItem value={'M'}>M</MenuItem>
                </StyledSelect>
              </StyledFC>
            </FormGroup>
          </AdminFormGroup>
          <AdminFormGroup row>
            <FormGroup>
              <StyledInputLabel>{t('commonfields.email')}</StyledInputLabel>
              <StyledTextField
                name='email'
                placeholder={t('commonfields.emailLabel')}
                value={fields.email}
                onChange={handleInputChange}
                disabled={disabledInput}
                variant='filled'
                error={errors?.email ? errors?.email : ''}
                helperText={errors?.email ? errors?.email : ''}
              />
            </FormGroup>
            <FormGroup>
              <StyledInputLabel>{t('commonfields.phone')}</StyledInputLabel>
              <StyledTextField
                name='phoneNumber'
                placeholder={t('commonfields.phoneLabel')}
                value={fields.phoneNumber}
                onChange={handleInputChange}
                disabled={disabledInput}
                variant='filled'
                error={errors?.phoneNumber ? errors?.phoneNumber : ''}
                helperText={errors?.phoneNumber ? errors?.phoneNumber : ''}
              />
            </FormGroup>
          </AdminFormGroup>
          <AdminFormGroup>
            <StyledInputLabel>{t('patientform.ssn')}<RequiredField /></StyledInputLabel>
            <StyledTextField
              name='socialSecurityNr'
              placeholder={t('patientform.ssnLabel')}
              value={fields.socialSecurityNr}
              onChange={handleInputChange}
              disabled={disabledInput}
              variant='filled'
              error={errors?.socialSecurityNr ? errors?.socialSecurityNr : ''}
              helperText={errors?.socialSecurityNr ? errors?.socialSecurityNr : ''}
            />
          </AdminFormGroup>
          <AdminFormGroup row>
            <FormGroup>
              <StyledInputLabel>{t('patientform.contactName')}<RequiredField /></StyledInputLabel>
              <StyledTextField
                name='contactName'
                placeholder={t('patientform.contactNameLabel')}
                value={fields.contactName}
                onChange={handleInputChange}
                disabled={disabledInput}
                variant='filled'
                error={errors?.contactName ? errors?.contactName : ''}
                helperText={errors?.contactName ? errors?.contactName : ''}
              />
            </FormGroup>
            <FormGroup>
              <StyledInputLabel>{t('patientform.contactDetails')}</StyledInputLabel>
              <StyledTextField
                name='contactDetails'
                placeholder={t('patientform.contactDetailsLabel')}
                value={fields.contactDetails}
                onChange={handleInputChange}
                disabled={disabledInput}
                variant='filled'
                error={errors?.contactDetails ? errors?.contactDetails : ''}
                helperText={errors?.contactDetails ? errors?.contactDetails : ''}
              />
            </FormGroup>
          </AdminFormGroup>
          <StyledDivider />
          <StyledButtonGroup>
            {disabledInput ? (
              <>
                <SecondaryButton
                  onClick={handleEdit}
                  variant='contained'
                  disableElevation
                >
                  {props.patient ? t('buttons.cancel') : t('buttons.editForm')}
                </SecondaryButton>
                <PrimaryButton
                  onClick={handleSubmit}
                  variant='contained'
                  disableElevation
                >
                  {t('buttons.submit')}
                </PrimaryButton>
              </>
            ) : (
              <PrimaryButton
                disabled={disabledNext}
                onClick={handleNext}
                variant='contained'
                disableElevation
              >
                {t('buttons.next')}
              </PrimaryButton>
            )}
          </StyledButtonGroup>
        </StyledContainer>
      </RightContentDiv>
    </>
  );
}
const mapStateToProps = (state /*, ownProps*/) => {
  return {
    success: state.updates.success,
    errorFields: state.updates.errorFields,
    mainContact: state.updates.mainContact,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createPatient: (data) => {
      dispatch(createPatient(data));
    },
    editPatient: (data) => {
      dispatch(editPatient(data));
    },
  };
};

export default withTheme(
  connect(mapStateToProps, mapDispatchToProps)(PatientForm)
);
