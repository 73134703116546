import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { loadHealthOrg, storePermissions } from './actions';
import './App.css';
import { ThemeProvider } from 'styled-components';
import { theme2 } from './themes/theme2';
import { usePermissions } from './storage';
import Login from './components/login';
import PatientList from './components/patientlist';
import VaccineList from './components/vaccinelist';
import RequestForm from './components/requestform';
import PatientProfile from './components/patientprofile';
import VaccinationDetails from './components/vaccinationdetails';
import VaccineForm from './components/vaccineform';
import PatientForm from './components/patientform';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import BypassLogin from './components/bypass/bypasslogin';
import InnerLayout from './components/layout/innerlayout';
import LandingLayout from './components/layout/landinglayout';
import DefaultLanding from './components/defaultlanding';
import Home from './components/hotel/Home';
import HotelVerified from './components/hotel/HotelVerified';

const loading = (
  <div className='pt-3 text-center'>
    <div className='sk-spinner sk-spinner-pulse'></div>
  </div>
);

function App(props) {
  const [permissions, setPermissions] = usePermissions();
  const token = props.token;
  useEffect(() => {
    if (permissions) {
      props.storePermissions(permissions);
    }
    document.title = 'Medical Office System - PGI';
  }, []);

  const mainPageProps = {
    ...props, ...{
      translations: {
        headerTitle: 'general.title',
      }
    }
  };
  function NoMatch() {
    const location = useLocation();
    return (
      <div className="container">
        <h2>Page Not Found at this Url: {location.pathname}</h2>
      </div>
    );
  }
  function PrivateRoute({ children, ...rest }) {
    return (
      <Route
        {...rest}
        render={(location) =>
          token ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: '/access',
              }}
            />
          )}
      />
    );
  }
  function LoginRoute({ children, ...rest }) {
    return (
      <Route
        {...rest}
        render={({ props }) =>
          rest.token ? (
            <Redirect to={{
              pathname: '/patients',
            }} />
          ) : (
            children
          )}
      />
    );
  }

  return (
    <ThemeProvider theme={theme2}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <BrowserRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <PrivateRoute path="/vaccinebatches/new" name="AddVaccine" {...props}>
                <InnerLayout {...props}>
                  <VaccineForm />
                </InnerLayout>
              </PrivateRoute>
              <PrivateRoute path="/vaccinebatches" name="VaccineList" {...props}>
                <InnerLayout {...props}>
                  <VaccineList />
                </InnerLayout>
              </PrivateRoute>
              <PrivateRoute path="/vaccination/:vaccinationId" name="Vaccination" {...props}>
                <InnerLayout {...props}>
                  <VaccinationDetails />
                </InnerLayout>
              </PrivateRoute>
              <PrivateRoute path="/patients/profile/:patientId" name="PatientProfile" {...props}>
                <InnerLayout {...props}>
                  <PatientProfile />
                </InnerLayout>
              </PrivateRoute>
              <PrivateRoute path="/patients/new" name="AddPatient" {...props}>
                <InnerLayout {...props}>
                  <PatientForm />
                </InnerLayout>
              </PrivateRoute>
              <PrivateRoute path="/patients" name='Patients' {...props}>
                <InnerLayout >
                  <PatientList {...props} />
                </InnerLayout>
              </PrivateRoute>
              <Route path="/request/:id" name="request" {...props}
                render={(propsInner) => (
                  <LandingLayout {...propsInner}>
                    <RequestForm />
                  </LandingLayout>
                )}
              />
              <Route
                path='/example/hotel'
                name='hotel-home'
                render={(props) => (
                  <Home />
                )}
              />
              <Route
                path='/example/verified'
                name='hotel-verified'
                render={(props) => (
                  <HotelVerified />
                )}
              />
              <Route
                path='/bypass'
                name='bypass-mos'
                render={(propsInner) =>
                  <BypassLogin {...propsInner} />
                }
              />
              <Route path="/access" name="access-p" render={(propsInner) =>
                <Login {...propsInner} />
              } />
              <Route
                path="/"
                name="access"
                render={(props) => (
                  <LandingLayout {...props} displayTitle={false}>
                    <DefaultLanding {...props} />
                  </LandingLayout>

                )}
              />
              <Route path="*">
                <NoMatch />
              </Route>
            </Switch>
          </React.Suspense>
        </BrowserRouter>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    tenantinfo: state.updates.tenant,
    token: state.updates.token,
    healthorg: state.updates.healthorg,
    error: state.updates.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    storePermissions: (data) => {
      dispatch(storePermissions(data));
    },
    loadHealthOrg: (data) => {
      dispatch(loadHealthOrg(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);