import React, { useState, useEffect } from 'react';
import { AppBar, Box, Grid } from '@material-ui/core';
import styled, { withTheme } from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '@pgi/common/fe/styles/style.css';

const healthOrg = localStorage.getItem('health_org');
const healthOrgId = JSON.parse(healthOrg)?.tenant_id;
const StyledAppBar = styled(AppBar)`
  height: ${(props) => props.theme.layout.header.height};
  &.MuiAppBar-root {
    @media screen and (max-width: 800px) {
      background-size: 100%;
    }
  }
  &.MuiAppBar-positionFixed {
    position: unset;
  }
  &.MuiAppBar-colorPrimary {
    background-color: ${(props) => props.theme.layout.header.bgColor};
  }
  &.MuiPaper-elevation4 {
    box-shadow: ${(props) => props.theme.layout.header.boxShadow};
  }
`;
const StyledH2 = styled.h2`
  margin-top: ${(props) => props.theme.layout.header.title.marginTop};
  font-size: ${(props) => props.theme.fonts.size.apptitle};
  font-weight: ${(props) => props.theme.fonts.weight.bold};
  color: ${(props) => healthOrgId ? props.theme.colors.apptitle : props.theme.layout.header.title.color};
  text-transform: uppercase;
  @media screen and (max-width: 1100px) {
    font-size: ${(props) => props.theme.fonts.size.subtitle1};
    margin-top: 0;
  }
`;
const StyledLogo = styled.img`
  height: ${(props) => props.theme.layout.header.logo.height};
  width: ${(props) => props.theme.layout.header.logo.width};
  @media screen and (max-width: 1100px) {
    font-size: ${(props) => props.theme.fonts.size.subtitle1};
    margin-top: 0;
  }
`;
const StyledLink = styled(Link)`
  text-decoration: none;
  color: white;
  margin-top: ${(props) => props.theme.layout.header.logo.marginTop};
  display: flex;
  margin-left: 6%;
`;
const GridWithBg = styled(Grid)`
background-image: ${(props) => props.theme.layout.header.bgImage};
background-repeat: ${(props) => props.theme.layout.header.bgRepeat};
background-size: contain;
min-width: 648px;
min-height: 200px;
`;
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const PublicHeader = (props) => {
  const query = useQuery();
  const tenantId = query.get('tenant_id');
  const { t } = useTranslation();
  const blueShade = '#0092dd';
  const blackShade = '#4a4a4a';
  const [domainTitleColor, setDomainTitleColor] = useState(blackShade);

  useEffect(() => {
    if (tenantId || props.match.params.id) {
      setDomainTitleColor(blackShade);
      return;
    }
    setDomainTitleColor(blueShade);
  }, [tenantId]);

  return (
    <>
      <header id='header' container>
        <div className='logo-container' style={{ backgroundImage: `url('${props.background}')` }}>
            {(props.mosdomain) ? (
            <Link className='logo' onClick={() => {
                window.location = `${props.mosdomain}${props.url}`;
            }}>
              <StyledLogo src={props.logo} />
            </Link>
            ) : (
              <Link className='logo' to={props.url}>
                <StyledLogo src={props.logo} />
              </Link>
            )}
        </div>
        <div className='domain-name'>
          {props.displayTitle === false ? '' : <StyledH2 style={{ color: domainTitleColor }}>{t(props.translations?.headerTitle)}</StyledH2>}
        </div>
      </header>
    </>
  );
};

export default withTheme(PublicHeader);
