import React from 'react';
import styled from 'styled-components';
import { Button, Card } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import requestIcon from './assets/iconmonstr-credit-card-9-blue.svg';
import appcon from './assets/iconmonstr-credit-card-9-green.svg';

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
`;
const StyledCard = styled(Card)`
  padding: ${(props) => props.theme.card.padding};
  display: ${(props) => props.theme.card.display};
  flex-direction: ${(props) => props.theme.card.direction};
  justify-content: ${(props) => props.theme.card.justify};
  align-items: ${(props) => props.theme.card.align};
  width: ${(props) => props.theme.card.width};
  height: ${(props) => props.theme.card.height};
  box-sizing: ${(props) => props.theme.card.box};
  margin: 0 4%;
  &.MuiPaper-rounded {
    border-radius: ${(props) => props.theme.card.radius};
  }
`;
const StyledSubtitle = styled.div`
  text-align: center;
  font-size: ${(props) => props.theme.fonts.size.headline6};
  font-weight: ${(props) => props.theme.fonts.weight.headline6};
  font-family: ${(props) => props.theme.fonts.openSans};
`;
const StyledIcon = styled.img`
  height: ${(props) => props.theme.accessIcon.height};
`;
const StyledP = styled.p`
  font-size: ${(props) => props.theme.fonts.size.subtitle1};
  font-weight: ${(props) => props.theme.fonts.weight.regular};
  text-align: center;
  color: ${(props) => props.theme.colors.paragraph};
  line-height: 24px;
  width: 90%;
  font-family: ${(props) => props.theme.fonts.openSans};
  letter-spacing: 0.15px;
`;

const RequestButton = styled(Button)`
  &.MuiButton-root {
    background-color: ${(props) => props.theme.buttons.roundedPrimary.bg};
    color: ${(props) => props.theme.buttons.roundedPrimary.color};
    border-radius: ${(props) =>
      props.theme.buttons.roundedPrimary.borderRadius};
    font-family: ${(props) => props.theme.fonts.openSans};
    font-size: ${(props) => props.theme.fonts.size.subtitle2};
    font-weight: ${(props) => props.theme.fonts.weight.semiBold};
    height: ${(props) => props.theme.buttons.roundedPrimary.height};
  }
  &.MuiButton-root:hover {
    background-color: ${(props) =>
      props.theme.buttons.roundedPrimary.background};
  }
  width: 90%;
`;
const AccessButton = styled(Button)`
  &.MuiButton-root {
    background-color: ${(props) =>
      props.theme.buttons.roundedSecondary.background};
    color: ${(props) => props.theme.buttons.roundedSecondary.color};
    border-radius: ${(props) =>
      props.theme.buttons.roundedSecondary.borderRadius};
    ont-family: ${(props) => props.theme.fonts.openSans};
    font-size: ${(props) => props.theme.fonts.size.subtitle2};
    font-weight: ${(props) => props.theme.fonts.weight.semiBold};
    height: ${(props) => props.theme.buttons.roundedSecondary.height};
  }
  &.MuiButton-root:hover {
    background-color: ${(props) =>
      props.theme.buttons.roundedSecondary.background};
  }
  width: 95%;
`;
const RequestCard = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const translations = props.translations;
  return (
    <>
      <StyledDiv>
        <StyledCard variant='outlined'>
          <StyledSubtitle>{t(translations.requestTitle)}</StyledSubtitle>
          <StyledIcon src={requestIcon} />
          <StyledP>{t(translations.requestPara)}</StyledP>
          <RequestButton onClick={() => history.push('/request')}>
            i1{t(translations.requestBtn)}
          </RequestButton>
        </StyledCard>
        <StyledCard variant='outlined'>
          <StyledSubtitle>{t(translations.appTitle)}</StyledSubtitle>
          <StyledIcon src={appcon} />
          <StyledP>{t(translations.appPara)}</StyledP>
          <AccessButton onClick={() => history.push(props.appUri)}>
            i2{t(translations.appBtn)}
          </AccessButton>
        </StyledCard>
      </StyledDiv>
    </>
  );
};

export default RequestCard;
