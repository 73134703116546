import styled from 'styled-components';
import { Button, Card } from '@material-ui/core';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
`;
const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1%;
  flex-wrap: wrap;
  background-color: ${(props) => props.theme.colors.bg};
`;

const StyledTitle = styled.h2`
  font-family: ${(props) => props.theme.fonts.openSans};
  font-size: ${(props) => props.theme.fonts.size.headline6};
  font-weight: ${(props) => props.theme.fonts.weight.bold};
  letter-spacing: 0.1px;
  text-shadow: 0px 0px 1px black;
`;
const StyledText = styled.div`
  // width: 56%;
  height: 86px;
  text-align: center;
  font-family: ${(props) => props.theme.fonts.openSans};
  font-size: ${(props) => props.theme.bodyText.fontSize};
  font-weight: ${(props) => props.theme.fonts.weight.regular};
  color: ${(props) => props.theme.bodyText.color};
  letter-spacing: 0.4px;
  margin-bottom: 14px;
`;
const RequestButton = styled(Button)`
  &.MuiButton-root {
    background-color: ${(props) =>
      props.theme.buttons.roundedPrimary.background};
    color: ${(props) => props.theme.buttons.roundedPrimary.color};
    border-radius: ${(props) =>
      props.theme.buttons.roundedPrimary.borderRadius};
    font-family: ${(props) => props.theme.fonts.openSans};
    font-size: ${(props) => props.theme.fonts.size.subtitle2};
    font-weight: ${(props) => props.theme.fonts.weight.semiBold};
    height: ${(props) => props.theme.buttons.roundedPrimary.height};
    min-width: 80%;
    width: fit-content;
  }
  &.MuiButton-root:hover {
    background-color: ${(props) =>
      props.theme.buttons.roundedPrimary.background};
  }
`;
const AccessButton = styled(Button)`
  &.MuiButton-root {
    background-color: ${(props) =>
      props.theme.buttons.roundedSecondary.background};
    color: ${(props) => props.theme.buttons.roundedSecondary.color};
    border-radius: ${(props) =>
      props.theme.buttons.roundedSecondary.borderRadius};
    font-family: ${(props) => props.theme.fonts.openSans};
    font-size: ${(props) => props.theme.fonts.size.subtitle2};
    font-weight: ${(props) => props.theme.fonts.weight.semiBold};
    height: ${(props) => props.theme.buttons.roundedSecondary.height};
    min-width: 80%;
    width: fit-content;
  }
  &.MuiButton-root:hover {
    background-color: ${(props) =>
      props.theme.buttons.roundedSecondary.background};
  }

`;
const NewAccessButton = styled(Button)`
&.MuiButton-root {
  background-color: ${(props) =>
    props.theme.buttons.access.background};
  color: ${(props) => props.theme.buttons.access.color};
  border-radius: ${(props) =>
    props.theme.buttons.roundedSecondary.borderRadius};
  font-family: ${(props) => props.theme.fonts.openSans};
  font-size: ${(props) => props.theme.fonts.size.subtitle2};
  font-weight: ${(props) => props.theme.fonts.weight.semiBold};
  height: ${(props) => props.theme.buttons.roundedSecondary.height};
  min-width: 80%;
  width: fit-content;
}
&.MuiButton-root:hover {
  text-shadow: 0px 0px 1px #572727;
  background-color: #ffffff;
}
width: 95%;
`;
const StyledP = styled.p`
  font-size: ${(props) => props.theme.fonts.size.subtitle1};
  font-weight: ${(props) => props.theme.fonts.weight.regular};
  text-align: center;
  color: ${(props) => props.theme.colors.paragraph};
  // line-height: 24px;
  width: 80%;
  font-family: ${(props) => props.theme.fonts.openSans};
  // letter-spacing: 0.15px;
  margin: 0;
`;
const Bold = styled.span`
  font-size: ${(props) => props.theme.fonts.size.subtitle2};
  font-weight: ${(props) => props.theme.fonts.weight.bold};
  color: ${(props) => props.theme.colors.paragraph};
  line-height: 24px;
  font-family: ${(props) => props.theme.fonts.openSans};
  letter-spacing: 0.15px;
`;
const StyledTabAccess = styled.p`
    text-align: center;
    font-family: ${(props) => props.theme.fonts.openSans};
    font-weight: ${(props) => props.theme.fonts.weight.regular};
    color: white;
    letter-spacing: 0.4px;
    margin-bottom: 24px;
    margin-top: 24px;
    padding: 0 4%;
`;
export {
  StyledContainer,
  StyledDiv,
  StyledTitle,
  StyledText,
  RequestButton,
  AccessButton,
  StyledP,
  Bold,
  NewAccessButton,
  StyledTabAccess
};
